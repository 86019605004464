import styled from "styled-components";
// import theme from "config/theme";

const Title2 = styled.span`
  font-size: 24px;
  line-height: 1;
  
  .mobile & {
    font-size: 16px;
  }
`;

export default Title2;
