import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Button, Col } from "antd";
import PropTypes from "prop-types";

import theme from "config/theme";

import { ChromePicker } from "react-color";

import iconArrowRight from "assets/img/icon-arrow-right.svg";

import Modal from "components/Modal";
import useStores from "hooks/useStores";

const Color = styled.div`
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
  border: 1px solid ${theme.borderGray};
  border-radius: 50%;
`;

const Picker = styled(ChromePicker)`
  &.chrome-picker {
    width: 100% !important;
    & > div {
      background-color: ${theme.backgroundBlack};
    }

    & svg {
      fill: ${theme.white} !important;
      background: ${theme.gray} !important;
      &:hover {
        fill: ${theme.black} !important;
        background: ${theme.white} !important;
      }
    }
  }
  margin-bottom: 16px;
`;

const ColorPicker = ({
  invisible,
  onClose: handleClose,
  onComplete: handleComplete,
  originalColor,
  currentColor: previousColor,
  colorName,
}) => {
  const [currentColor, setCurrentColor] = useState(previousColor);
  const { l10n } = useStores();

  useEffect(() => {
    setCurrentColor(previousColor);
  }, [previousColor]);

  const handleChange = (value) => {
    setCurrentColor(value.hex);
  };
  return (
    <Modal
      invisible={invisible}
      title={colorName}
      closable
      onClose={handleClose}
      noPadding
      style={{ width: "100vw", maxWidth: 368 }}
    >
      <Picker color={currentColor} onChange={handleChange} disableAlpha />
      <Row
        justify="center"
        align="middle"
        gutter={[16, 16]}
        style={{ marginBottom: 16 }}
      >
        <Color style={{ backgroundColor: originalColor }} />
        &nbsp; &nbsp;
        <img src={iconArrowRight} alt="arrow" style={{ width: 18 }} />
        &nbsp; &nbsp;
        <Color style={{ backgroundColor: currentColor }} />
      </Row>
      <Row
        style={{ marginBottom: 23 }}
        justify="center"
        align="middle"
        gutter={[8, 8]}
      >
        <Col>
          <Button
            onClick={() => {
              handleComplete(originalColor);
            }}
            size="small"
            type="primary"
          >
            {l10n.str("BUTTON_RESET")}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              handleComplete(currentColor);
            }}
            size="small"
            type="primary"
          >
            {l10n.str("BUTTON_APPLY")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ColorPicker.propTypes = {
  invisible: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  colorName: PropTypes.string,
  originalColor: PropTypes.string,
  currentColor: PropTypes.string,
};

ColorPicker.defaultProps = {
  onClose: () => {},
  onComplete: () => {},
};

export default ColorPicker;
