import React, { useEffect, useState, useCallback, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, Form } from "antd";
import styled from "styled-components";
import api from "utils/api";
import theme from "config/theme";
import useStores from "hooks/useStores";

import PropTypes from "prop-types";

import logoPexels from "assets/img/logo-pexels.png";
import logoUnsplash from "assets/img/logo-unsplash.png";
import logoPixabay from "assets/img/logo-pixabay.png";

const logos = {
  pexels: logoPexels,
  unsplash: logoUnsplash,
  pixabay: logoPixabay,
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 50vh;
  /* overflow-x: hidden; */
  overflow-y: auto;
  position: relative;
`;
const SearchWrapper = styled(Form)`
  &.ant-form {
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ImageList = styled(InfiniteScroll)`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  /* max-height: 30vh; */
  /* 
  & > img {
    width: 100%;
    margin-bottom: 4px;
  } */
`;

const Item = styled.div`
  position: relative;
  display: inline-block;
  height: 128px;
  width: auto;
  cursor: pointer;
  margin: 0 8px 16px 8px;
  transition: transform 0.125s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: translateY(-4px);
  }

  & > .copyright {
    /* width: 80%;
    max-width: 64px; */
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    /* padding: 2px; */
    /* background-color: ${theme.white}; */
    opacity: 1;

    & > img {
      width: auto;
      height: 24px;
      vertical-align: middle;
    }
  }
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  outline: none;
`;

Image.defaultProps = { alt: "search result" };

const SearchImage = ({
  style,
  onSelect: handleSelect,
  defaultQuery,
  minDuration,
}) => {
  const { l10n } = useStores();
  const isBusy = useRef(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const [totals, setTotals] = useState({
    unsplash: 0,
    pixabay: 0,
    pexels: 0,
  });
  const [currentQuery, setCurrentQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(
    async ({ query, page }) => {
      if (!isBusy.current) {
        isBusy.current = true;

        let pixabayResult = {
          total: 0,
        };
        try {
          pixabayResult = await api
            .images()
            .searchVideos({ query, page, from: "pixabay", minDuration });

          setSearchResultData((data) => {
            return [...data, ...pixabayResult.data];
          });
        } catch (error) {
          console.log(error);
        }

        let pexelsResult = {
          total: 0,
        };
        try {
          pexelsResult = await api
            .images()
            .searchVideos({ query, page, from: "pexels", minDuration });

          setSearchResultData((data) => {
            return [...data, ...pexelsResult.data];
          });
        } catch (error) {
          console.log(error);
        }
        setTotals({
          // unsplash: unsplashResult.total,
          pixabay: pixabayResult.total,
          pexels: pexelsResult.total,
        });
        // setIsBusy(false);
        isBusy.current = false;
      }
    },
    [minDuration]
  );

  const handleSearch = useCallback(
    ({ query }) => {
      if (!isBusy.current) {
        const page = query ? 1 : parseInt(Math.random() * 100, 10);
        setSearchResultData([]);
        setCurrentQuery(query);
        setCurrentPage(page);
        fetchData({ query, page });
      }
    },
    [fetchData]
  );

  useEffect(() => {
    if (
      defaultQuery &&
      typeof defaultQuery === "string" &&
      defaultQuery.length >= 2
    ) {
      handleSearch({ query: defaultQuery });
    } else {
      handleSearch({});
    }
  }, [defaultQuery, handleSearch]);
  return (
    <Wrapper style={style}>
      <SearchWrapper onFinish={handleSearch}>
        <Form.Item name="query">
          <Input.Search
            onSearch={(value) => {
              handleSearch({ query: value });
            }}
            style={{ width: 312, maxWidth: "100vw" }}
            className="dark"
            size="small"
            placeholder={l10n.str("PLACEHOLDER_SEARCH_VIDEO")}
          />
        </Form.Item>
      </SearchWrapper>
      <ImageList
        scrollableTarget="selectVideo"
        dataLength={searchResultData.length}
        next={async () => {
          const nextPage = currentPage + 1;
          await fetchData({ query: currentQuery, page: nextPage });
          setCurrentPage(nextPage);
        }}
        loader={
          <div style={{ display: "block", textAlign: "center", padding: 16 }}>
            <LoadingOutlined />
          </div>
        }
        hasMore={
          searchResultData.length <=
          Object.keys(totals).reduce((sum, key) => sum + (totals[key] || 0), 0)
        }
      >
        {searchResultData.map((item) => {
          const { thumbnail, link, from } = item;
          return (
            <Item>
              <Image
                src={thumbnail}
                onClick={() => {
                  handleSelect(item);
                }}
              />
              <a
                className="copyright"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logos[from]} alt={from} />
              </a>
            </Item>
          );
        })}
      </ImageList>
    </Wrapper>
  );
};

SearchImage.propTypes = {
  onSelect: PropTypes.func,
  defaultQuery: PropTypes.string,
  minDuration: PropTypes.number,
};

SearchImage.defaultProps = {
  onSelect: () => {},
  defaultQuery: "",
  minDuration: 0,
};

export default SearchImage;
