import styled from "styled-components";
import PropTypes from "prop-types";

import iconRatio from "assets/img/icon-ratio.svg";
import iconDuration from "assets/img/icon-duration.svg";
import iconText from "assets/img/icon-text.svg";
import iconPicture from "assets/img/icon-picture.svg";
import iconVideo from "assets/img/icon-video.svg";
import iconRatioDark from "assets/img/icon-ratio-dark.svg";
import iconDurationDark from "assets/img/icon-duration-dark.svg";
import iconTextDark from "assets/img/icon-text-dark.svg";
import iconPictureDark from "assets/img/icon-picture-dark.svg";
import iconVideoDark from "assets/img/icon-video-dark.svg";

const icons = {
  light: {
    ratio: iconRatio,
    duration: iconDuration,
    text: iconText,
    picture: iconPicture,
    video: iconVideo,
  },
  dark: {
    ratio: iconRatioDark,
    duration: iconDurationDark,
    text: iconTextDark,
    picture: iconPictureDark,
    video: iconVideoDark,
  },
};

const Info = styled.div`
  width: auto;
  height: auto;
  position: relative;
  /* padding-left: 32px; */
  display: inline-block;
  margin-right: 16px;

  & > span {
    vertical-align: middle;
    line-height: 1;
  }

  &::before {
    content: "";
    display: inline-block;
    width: ${({ iconSize }) => {
      return iconSize + "px";
    }};
    height: ${({ iconSize }) => {
      return iconSize + "px";
    }};
    background-position: 0 50%;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    /* margin-right: 8px; */
    background-image: ${({ type, theme }) => {
      const iconUrl = icons[theme][type];
      return `url('${iconUrl}')`;
    }};
  }
`;

Info.propTypes = {
  type: PropTypes.string,
  theme: PropTypes.oneOf("light", "dark"),
  iconSize: PropTypes.number,
};

Info.defaultProps = {
  type: "ratio",
  theme: "light",
  iconSize: 24,
};

export default Info;
