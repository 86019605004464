import Unsplash from "unsplash-js";
import { authenticate as Pixabay } from "pixabay-api";
import { create } from "axios";
import queryString from "query-string";

const unsplash = new Unsplash({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
});

const pixabay = Pixabay(process.env.REACT_APP_PIXABAY_KEY);

const pexelsAxios = create({
  baseURL: "https://api.pexels.com",
  withCredentials: false,
});

pexelsAxios.defaults.headers = {
  Authorization: process.env.REACT_APP_PEXELS_KEY,
};

function api() {
  const searchVideos = async (
    data = {
      query: "",
      page: 1,
      limit: 20,
      orientation: "",
      from: "pixabay",
      minDuration: 0,
    }
  ) => {
    const { query, from } = data;
    let { page, limit, orientation, minDuration } = data;
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    if (!minDuration || Number.isNaN(minDuration)) {
      minDuration = 0;
    }

    let result = {};
    const resultToReturn = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: [],
    };

    result = await pexelsAxios({
          url:
            (query ? "/videos/search?" : "/videos/popular?") +
            queryString.stringify({ per_page: limit, query, page }),
          method: "get",
        });
        if (Array.isArray(result?.data?.videos)) {
          resultToReturn.total = result.data.total_results;
          resultToReturn.totalPage = Math.ceil(result.data.total / limit);
          resultToReturn.data = result.data.videos
            .filter(({ duration }) => {
              return parseInt(duration, 0) >= minDuration;
            })
            .map(({ image: thumbnail, video_files: videoFiles, url }) => {
              const video = (videoFiles || []).find((item) => {
                return (
                  parseInt(item?.width, 10) >= 1920 ||
                  parseInt(item?.height, 10) >= 1080
                );
              });
              return {
                thumbnail,
                // video: videoFiles?.[0]?.link,
                video: video?.link,
                width: video?.width,
                height: video?.height,
                from,
                link: url || "https://pexels.com",
              };
            });
        }
    return resultToReturn;
  };

  const searchPhotos = async (
    data = {
      query: "",
      page: 1,
      limit: 20,
      orientation: "landscape|portrait",
      from: "unsplash",
    }
  ) => {
    const { query, from } = data;
    let { page, limit, orientation } = data;
    // if (!query) {
    //   query = "hello";
    // }
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    if (["landscape", "portrait"].indexOf(orientation) < 0) {
      orientation = "";
    }
    // if (!orientation) {
    //   // unsplash "landscape", "portrait", "squarish"
    //   // pixabay "all", "horizontal", "vertical"
    // }

    let result = {};
    const resultToReturn = {
      total: 0,
      limit,
      page,
      totalPage: 0,
      data: [],
    };

    switch (from) {
      case "pexels":
        result = await pexelsAxios({
          url:
            (query ? "/v1/search?" : "/v1/curated?") +
            queryString.stringify({ per_page: limit, query, page }),
          method: "get",
        });
        if (Array.isArray(result?.data?.photos)) {
          resultToReturn.total = result.data.total_results;
          resultToReturn.totalPage = Math.ceil(result.data.total / limit);
          resultToReturn.data = result.data.photos.map(
            ({ src, width, height, url }) => ({
              thumbnail: src.tiny,
              // image: src[orientation] || src.large,
              image: src.original,
              width,
              height,
              from,
              link: url || "https://pexels.com",
            })
          );
        }
        break;
      case "pixabay":
        if (orientation === "landscape") {
          orientation = "horizontal";
        } else if (orientation === "portrait") {
          orientation = "vertical";
        } else {
          orientation = "all";
        }
        if (query) {
          result = await pixabay.searchImages(query, {
            per_page: limit,
            page,
            orientation,
          });
          resultToReturn.total = result.total;
          resultToReturn.totalPage = Math.ceil(result.total / limit);
          resultToReturn.data = result.hits.map(
            ({
              previewURL: thumbnail,
              fullHDURL: image,
              imageWidth: width,
              imageHeight: height,
              pageURL,
            }) => ({
              thumbnail,
              image,
              width,
              height,
              from,
              link: pageURL || "https://pixabay.com",
            })
          );
        }
        break;

      case "unsplash":
      default:
        if (query) {
          result = await (
            await unsplash.search.photos(query, page, limit, {
              orientation,
            })
          ).json();

          resultToReturn.total = result.total;
          resultToReturn.totalPage = result.total_pages;
          resultToReturn.data = result.results.map(
            ({ urls, width, height, links }) => ({
              thumbnail: urls?.thumb || "",
              image: urls?.full || "",
              width,
              height,
              from,
              link: links?.html || "https://unsplash.com",
            })
          );
        } else {
          result = await (
            await unsplash.photos.getRandomPhoto({ count: 20 })
          ).json();
          resultToReturn.total = result.length;
          resultToReturn.totalPage = 1;
          resultToReturn.data = result.map(
            ({ urls, width, height, links }) => ({
              thumbnail: urls?.thumb || "",
              image: urls?.regular || "",
              width,
              height,
              from,
              link: links?.html || "https://unsplash.com",
            })
          );
        }
        break;
    }
    return resultToReturn;
  };
  return {
    search: searchPhotos,
    searchPhotos,
    searchVideos,
  };
}

// api().searchVideos({ query: "안경", from: "pixabay" });

// api().search({ query: "puppy", from: "unsplash" });
// api().search({ query: "hello", from: "unsplash" });
// api().search({ query: "mama", from: "pixabay" });
// api().search({ query: "brother", from: "pixabay" });

export default api;
