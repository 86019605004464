import React from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Tag as antdTag } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import theme from "config/theme";

import Typography from "components/Typography";

import iconPlay from "assets/img/icon-music-play.svg";
import iconPause from "assets/img/icon-music-pause.svg";

const { Text2, Caption, Caption2 } = Typography;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${theme.black}cc;
  padding: 17px 22px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.primary}40;
  }

  &.selected {
    background-color: ${theme.primary}80;
  }
`;

const ButtonPlay = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  background-image: url(${iconPlay});
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 12px;

  .selected.playing & {
    background-image: url(${iconPause});
  }
`;

const Tag = styled(antdTag)`
  &.ant-tag {
    padding: 2px 8px;
    line-height: 1;
    font-size: 8px;
    background-color: ${theme.darkTag};
    border-color: ${theme.darkTag};
    color: ${theme.white};
  }
`;

const BgmItem = inject("l10n")(
  observer(({ data: musicData, onClick: handleClick, className, l10n }) => {
    return (
      <Wrapper onClick={handleClick} className={className}>
        <Row
          justify="space-between"
          align="top"
          style={{ flexFlow: "row nowrap" }}
        >
          <Col style={{ overflow: "hidden" }}>
            <Text2
              style={{ fontWeight: 700, display: "block", marginBottom: 2 }}
            >
              {musicData.musicTitle}
            </Text2>
            <Caption style={{ color: theme.gray, display: "block" }}>
              {parseInt(parseInt(musicData.musicLength, 10) / 60, 10)}:
              {("0" + (parseInt(musicData.musicLength, 10) % 60)).substr(-2)}
            </Caption>
            <div>
              {parseInt(musicData.musicMood, 10) > 0 && (
                <Tag>
                  <Caption2>
                    {
                      l10n.str("EDITOR_BGM_MOODS")[
                        parseInt(musicData.musicMood, 10)
                      ]
                    }
                  </Caption2>
                </Tag>
              )}
              {parseInt(musicData.musicGenre, 10) > 0 && (
                <Tag>
                  <Caption2>
                    {
                      l10n.str("EDITOR_BGM_GENRES")[
                        parseInt(musicData.musicGenre, 10)
                      ]
                    }
                  </Caption2>
                </Tag>
              )}
            </div>
          </Col>
          <Col>
            <ButtonPlay />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Caption style={{ color: theme.gray }}>
              {musicData.musicDescription}
            </Caption>
          </Col>
        </Row>
      </Wrapper>
    );
  })
);

BgmItem.propTypes = {
  data: PropTypes.oneOf([PropTypes.object]),
  onClick: PropTypes.func,
};

export default BgmItem;
