import { create } from "axios";

// const urlencode = require("urlencode");

function api(accessToken) {
  const withToken = accessToken
    ? {
        // 'Access-Control-Request-Headers': 'X-AUTH-TOKEN',
        // 'Access-Control-Allow-Headers': 'X-AUTH-TOKEN, X-Requested-With',
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
      }
    : {};

  const headers = {
    "Access-Control-Allow-Origin": "*",
    ...withToken,
  };

  const axios = create({
    baseURL: process.env.REACT_APP_FILES_API_URL,
    withCredentials: false,
    config: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_FILES_API_URL,
      // headers: { 'Access-Control-Allow-Origin': '*' },
      headers,
    },
  });
  axios.defaults.headers = headers;

  const files = {};
  const music = {};

  files.upload = async (data = { file: "Binary" }) => {
    try {
      const { file } = data;
      const formData = new FormData();
      formData.append("file", file);
      const result = await axios({
        method: "post",
        url: `/upload/image`,
        data: formData,
      });
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  files.convertVideoToGif = async (
    data = {
      sourceURL: "https://",
      startTime: 0,
      durationTime: 5,
      type: "base64",
    }
  ) => {
    try {
      const { sourceURL, startTime, durationTime } = data;
      let { type } = data;
      const outputScale = 480;
      const outputFps = 20;

      if (!type) {
        type = "base64";
      }

      const result = await axios({
        method: "post",
        url: `/cafe24/convertGif`,
        data: {
          sourceURL,
          startTime: parseFloat(startTime).toFixed(1),
          durationTime: parseFloat(durationTime).toFixed(1),
          outputScale,
          outputFps,
          type,
        },
      });

      if (result?.data?.Location) {
        result.data.url = result.data.Location;
        return result?.data;
      }

      if (result?.data?.data) {
        result.data.url = result.data.data;
        return result?.data;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  files.cropAndTrimVideo = async (
    data = {
      file: "",
      width: 0,
      height: 0,
      renderWidth: 0,
      renderHeight: 0,
      x: 0,
      y: 0,
      startTime: 0,
      duration: 0,
    }
  ) => {
    // /upload/video/processing
    try {
      const {
        file,
        width: cropWidth,
        height: cropHeight,
        renderWidth: scaleWidth,
        renderHeight: scaleHeight,
        x: cropX,
        y: cropY,
        startTime: trimStartTime,
        duration: trimDuration,
      } = data;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("cropWidth", cropWidth);
      formData.append("cropHeight", cropHeight);
      formData.append("scaleWidth", scaleWidth);
      formData.append("scaleHeight", scaleHeight);
      formData.append("cropX", cropX);
      formData.append("cropY", cropY);
      formData.append("trimStartTime", trimStartTime);
      formData.append("trimDuration", trimDuration);
      const result = await axios({
        method: "post",
        url: `/upload/video/processing`,
        data: formData,
      });
      console.log(result);
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  music.list = async (data = { limit: Number, page: Number }) => {
    try {
      let { limit, page } = data;
      if (!limit) {
        limit = 10;
      }
      if (!page) {
        page = 1;
      }

      const result = await axios({
        method: "get",
        url: `/download/music/list/info?page=${page}&limit=${limit}`,
      });
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  music.upload = async (
    data = {
      musicUrl: String,
      fadeEffect: Boolean,
      trimAudioStart: Number,
      trimAudioDuration: Number,
    }
  ) => {
    try {
      const { musicUrl, fadeEffect, trimAudioStart, trimAudioDuration } = data;
      const result = await axios({
        method: "post",
        url: "/upload/music",
        data: {
          musicUrl,
          fadeEffect,
          trimAudioStart,
          trimAudioDuration,
        },
      });
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  // async uploadVideo(data){
  //     return await http(`https://file.vplate.io/upload/video/withCrop`, data, 'post')
  // },
  // async makingFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:1}, 'patch')
  // },
  // async modifyFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:0}, 'patch')
  // },
  // async confirmFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:4}, 'patch')
  // }

  return {
    files,
    music,
  };
}

export default api;
