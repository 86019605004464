import styled from "styled-components";

const Container = styled.div`
  width: 96%;
  height: auto;
  position: relative;
  max-width: 1520px;
  margin: 0 auto;
  .mobile & {
    width: 90%;
  }
`;

export default Container;
