import Modal from "components/Modal";
import { PropTypes } from "mobx-react";
import React, { useEffect, useState } from "react";
import Typography from "components/Typography";
import styled from "styled-components";

import imgExample from "assets/img/img-editor-contact-example.png";
import { Button, Col, Form, Input, Row } from "antd";
import useStores from "hooks/useStores";

const { Text2, Caption } = Typography;
const Wrapper = styled.div`
  width: 90vw;
  max-width: 480px;
  height: auto;
  position: relative;
`;

const ModalInputContact = ({
  invisible,
  onClose: handleClose,
  onComplete: handleComplete,
}) => {
  const { l10n } = useStores();
  const [showInput, setShowInput] = useState(false);
  const [frmText] = Form.useForm();

  useEffect(() => {
    if (invisible) {
      setTimeout(() => {
        setShowInput(false);
      }, 300);
    }
  }, [invisible]);

  return (
    <Modal invisible={invisible} onClose={handleClose} closable>
      {!showInput && (
        <Wrapper>
          <div style={{ marginBottom: 16 }}>
            <Text2 style={{ fontWeight: 700 }}>
              문의 전화번호를 입력하시겠습니까?
            </Text2>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Caption>
              입력하지 않아도 제작을 완료할 수 있습니다. <br />
              예시이미지)
            </Caption>
          </div>
          <div style={{ textAlign: "center", marginBottom: 40 }}>
            <img
              src={imgExample}
              style={{ width: "90%" }}
              alt="문의 번호 적용 예시"
            />
          </div>
          <Row justify="center" gutter={[8, 8]}>
            <Col span={8}>
              <Button
                type="gray"
                block
                onClick={() => {
                  handleComplete(false);
                }}
              >
                넘어가기
              </Button>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                block
                onClick={() => {
                  setShowInput(true);
                }}
              >
                입력하기
              </Button>
            </Col>
          </Row>
        </Wrapper>
      )}
      {showInput && (
        <Wrapper>
          <div style={{ marginBottom: 16 }}>
            <Text2 style={{ fontWeight: 700 }}>
              문의 전화번호를 입력해주세요.
            </Text2>
          </div>
          <div>
            <Form
              form={frmText}
              onFinish={({ text }) => {
                handleComplete(text);
              }}
            >
              <p>
                <Form.Item name="text">
                  <Input
                    className="dark"
                    size="small"
                    maxLength={50}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Form.Item>
              </p>
              <Form.Item>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    size="default"
                    type="gray"
                    htmlType="button"
                    style={{ width: 168 }}
                    onClick={handleClose}
                  >
                    {l10n.str("BUTTON_CANCEL")}
                  </Button>
                  &nbsp;
                  <Button
                    size="default"
                    type="primary"
                    htmlType="submit"
                    style={{ width: 168, marginRight: 0 }}
                  >
                    완료
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Wrapper>
      )}
    </Modal>
  );
};

ModalInputContact.propTypes = {
  invisible: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};

ModalInputContact.defaultProps = {
  invisible: true,
  onClose: () => {},
  onComplete: () => {},
};

export default ModalInputContact;
