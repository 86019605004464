import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  message,
  Spin,
  Tooltip,
  Popover,
  Progress,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import AnimatedNumber from "react-animated-number";
import queryString from "query-string";
import useStores from "hooks/useStores";

import theme from "config/theme";
import iconBgm from "assets/img/icon-bgm-white.svg";
import iconPlay from "assets/img/icon-music-play.svg";
import iconPause from "assets/img/icon-music-pause.svg";
import iconWarning from "assets/img/icon-warning.svg";
import iconComponents from "assets/img/icon-components.svg";

import api from "utils/api";

import Typography from "components/Typography";
import Info from "components/IconInfo";
import Modal from "components/Modal";
import String from "components/String";
import SceneList from "./SceneList";
import Screen from "./Screen";
import BGM from "./ModalBgm";
import ColorPicker from "./ColorPicker";
import ModalPreviewScene from "./ModalPreviewScene";
import ModalInputContact from "./ModalInputContact";

const { Title2, Text, Text2, Caption } = Typography;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: ${theme.editorBackground};
  overflow: hidden;
  color: ${theme.white};
  display: flex;
  flex-direction: column;
`;

const GoBack = styled.div`
  width: auto;
  height: auto;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
  display: inline-block;
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  color: ${theme.white};
  cursor: pointer;
  &::before {
    content: "<";
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    margin-right: 4px;
  }
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

const ButtonPlay = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  background-image: url(${iconPlay});
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 12px;
  cursor: pointer;

  &.playing {
    background-image: url(${iconPause});
  }
`;

const ButtonRender = styled.button`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 48px;
  max-width: 192px;
  background-color: ${theme.primary}19;
  color: ${theme.white};
  font-size: 16px;
  border: 2px solid ${theme.primary};
  border-radius: 3px;
  cursor: pointer;
  z-index: 1;

  &:disabled {
    opacity: 0.5;
  }

  &::before {
    content: "";
    width: ${(props) => {
      return props.workProgress + "%";
    }};
    height: 100%;
    background-color: ${theme.primary};
    transition: width 0.2s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;

ButtonRender.propTypes = {
  workProgress: PropTypes.number,
};

ButtonRender.defaultProps = {
  workProgress: 0,
};

const addHash = (text) => {
  return `${!(text + "").startsWith("#") && "#"}${text + ""}`;
};

const removeHash = (text) => {
  return (text + "").replace("#", "");
};

const Editor = ({ match }) => {
  const { l10n } = useStores();
  const { subtemplateId } = match.params;
  const [isEditing, setIsEditing] = useState(false);
  const [forceUpdateTrigger, setForceUpdate] = useState(1);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [showModalBgm, setShowModalBgm] = useState(false);
  const [showModalRender, setShowModalRender] = useState(false);
  const [showModalGoBack, setShowModalGoBack] = useState(false);
  const [showModalPreviewScene, setShowModalPreviewScene] = useState(false);
  const [showModalInputContact, setShowModalInputContact] = useState(false);
  const [selectedColorIndex, setSelectedColorIndex] = useState(-1);
  const [showPopComponents, setShowPopComponents] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const refAudio = useRef(new Audio());
  const audio = refAudio.current;
  const [showPopBgm, setShowPopBgm] = useState(false);

  const location = useLocation();
  const { user: userId, showDeleteConfirmModal } = useMemo(() => {
    const query = {
      ...{ user: "", showDeleteConfirmModal: false },
      ...queryString.parse(location.search),
    };

    if (query.showDeleteConfirmModal) {
      if (query.showDeleteConfirmModal === "false") {
        query.showDeleteConfirmModal = false;
      }
    }

    query.showDeleteConfirmModal = !!query.showDeleteConfirmModal;
    return query;
  }, [location]);

  const sendGoBackToParent = useCallback(() => {
    if (typeof window?.parent?.postMessage === "function") {
      window.parent.postMessage(
        {
          projectId: subtemplateId,
        },
        "*"
      );
    }
  }, [subtemplateId]);

  useEffect(() => {
    if (showModalGoBack && !showDeleteConfirmModal) {
      sendGoBackToParent();
    }
  }, [showDeleteConfirmModal, showModalGoBack, sendGoBackToParent]);

  const templateData = useMemo(() => {
    if (projectData && projectData.template) {
      return projectData.template;
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, forceUpdateTrigger]);

  const parseProjectData = (data) => {
    const newData = { ...data };
    const lastSceneType = newData.lastSceneType;
    const lastSceneIndex = newData?.template?.sources.length - 1;
    const lastSceneFromOriginal = newData?.template?.sources[lastSceneIndex];
    const lastSceneFromDesign =
      newData?.template?.lastsources[lastSceneType - 1];
    if (lastSceneFromOriginal) {
      if (lastSceneFromDesign) {
        lastSceneFromOriginal.sceneImage =
          newData?.template?.lastsources[lastSceneType - 1].sceneImage;
      }
      let otIndex = 0;
      let olIndex = 0;
      lastSceneFromOriginal.source = lastSceneFromOriginal.source
        .map((item) => {
          if (item.sourceType === "OT") {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === "OT";
              }
            )[otIndex++];
            if (lastSceneSource) {
              return lastSceneSource;
            }
          }

          if (item.sourceType === "OL") {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === "OL";
              }
            )[olIndex++];
            if (lastSceneSource) {
              return lastSceneSource;
            }
          }

          return item;
        })
        .map((item) => {
          const newItem = { ...item };
          if (lastSceneType === 3 || lastSceneType === 1) {
            if (newItem.sourceType === "OT") {
              newItem.sourceType = "T";
            }
          }

          if (lastSceneType === 3 || lastSceneType === 2) {
            if (newItem.sourceType === "OL") {
              newItem.sourceType = "I";
            }
          }
          return newItem;
        });
      newData.template.sources[lastSceneIndex] = lastSceneFromOriginal;
    }
    return newData;
  };

  const inputData = useMemo(() => {
    if (
      projectData &&
      projectData.sources &&
      Array.isArray(projectData.sources)
    ) {
      return projectData.sources;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, forceUpdateTrigger]);

  const templateSources = useMemo(() => {
    return templateData?.sources?.map((sources, i) => {
      const newSources = { ...sources };
      newSources.source = newSources?.source?.map((area, j) => {
        const newArea = { ...area };
        if (inputData[i][j]) {
          newArea.isDone = true;
        } else {
          newArea.isDone = false;
        }
        return newArea;
      });
      return newSources;
    });
  }, [templateData, inputData]);

  const bgm = useMemo(() => {
    if (projectData?.bgm?.musicUrl) {
      const result = projectData.bgm;
      if (projectData?.bgmUrl) {
        result.cutMusicUrl = projectData.bgmUrl;
      }
      return result;
    }

    return {
      _id: null,
      musicUrl: templateData?.temPreviewVideo,
      cutMusicUrl: templateData?.temPreviewVideo,
      musicDescription: l10n.str("EDITOR_BGM_DEFAULT_DESCRIPTION"),
      // musicGenre: -1,
      musicInstrument: [],
      musicLength: templateData.videoDuration,
      // musicMood: -1,
      musicTitle: `(${l10n.str("EDITOR_BGM_UNTITLED")})`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, templateData, l10n, forceUpdateTrigger]);
  const [musicProgress, setMusicProgress] = useState(0);

  useEffect(() => {
    audio.src = bgm?.cutMusicUrl;
    audio.ontimeupdate = () => {
      setMusicProgress((audio.currentTime / audio.duration) * 100);
    };
    audio.onpause = () => {
      setMusicProgress(0);
      audio.currentTime = 0;
    };
    audio.onended = () => {
      setIsPlayingAudio(false);
      setMusicProgress(0);
      audio.currentTime = 0;
    };
    return () => {
      audio.pause();
      audio.src = "";
    };
  }, [bgm, audio]);

  useEffect(() => {
    if (isPlayingAudio) {
      audio.play();
    } else {
      audio.pause();
      setMusicProgress(0);
      audio.currentTime = 0;
    }
  }, [isPlayingAudio, audio]);

  const colors = useMemo(() => {
    if (Array.isArray(projectData?.changedColor)) {
      return projectData.changedColor.map((color, index) => {
        if (!color) {
          return templateData?.temColor?.[index] || null;
        }
        return color;
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, templateData, forceUpdateTrigger]);

  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0);

  const typesToInput = useMemo(() => {
    const result = {
      video: 0,
      image: 0,
      text: 0,
    };
    if (Array.isArray(templateSources)) {
      templateSources.forEach((sources) => {
        if (Array.isArray(sources?.source)) {
          sources.source.forEach((source) => {
            switch (source?.sourceType) {
              case "I":
                result.image += 1;
                break;
              case "T":
                result.text += 1;
                break;
              case "V":
                result.video += 1;
                break;
              default:
                break;
            }
          });
        }
      });
    }
    return result;
  }, [templateSources]);
  const completedNumbers = useMemo(() => {
    const result = {
      video: 0,
      image: 0,
      text: 0,
    };
    if (Array.isArray(templateSources)) {
      templateSources.forEach((sources) => {
        if (Array.isArray(sources?.source)) {
          sources.source.forEach((source) => {
            if (source.isDone) {
              switch (source.sourceType) {
                case "I":
                  result.image += 1;
                  break;
                case "T":
                  result.text += 1;
                  break;
                case "V":
                  result.video += 1;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    }
    return result;
  }, [templateSources]);

  const forceUpdate = useCallback(() => {
    setForceUpdate((c) => c + 1);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const result = await api
        .service()
        .projects.findOne({ subtemplateId, userId });
      if (result && typeof result === "object" && result?._id) {
        setProjectData(() => {
          return parseProjectData({
            ...result,
            timestamp: new Date().getTime(),
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsProjectLoaded(true);
  }, [subtemplateId, userId]);

  const patchProjectData = useCallback(
    async (
      data = {
        sources: [],
        bgmUrl: "",
        bgmId: "",
        colors: [],
        lastSceneType: 3,
        contactNumber: null,
      }
    ) => {
      const dataToPatch = {
        sources: inputData,
        bgmUrl: projectData.bgmUrl,
        bgmId: projectData?.bgm?._id,
        colors,
      };
      if (data.sources) {
        dataToPatch.sources = data.sources;
      }
      if (data.bgmUrl) {
        dataToPatch.bgmUrl = data.bgmUrl;
      }
      if (data.bgmId) {
        dataToPatch.bgmId = data.bgmId;
      }
      if (data.colors) {
        dataToPatch.colors = data.colors;
      }
      if (data.lastSceneType) {
        dataToPatch.lastSceneType = data.lastSceneType;
      }
      if (data.contactNumber || data.contactNumber === null) {
        dataToPatch.contactNumber = data.contactNumber;
      }
      const result = await api
        .service()
        .projects.patch({ subtemplateId, userId, ...(dataToPatch || {}) });
      if (result) {
        fetchData();
        // setProjectData((o) => {
        //   const newData = mergeDeep(o, {
        //     ...result,
        //     timestamp: new Date().getTime(),
        //   });

        //   return parseProjectData(newData);
        // });
        setTimeout(() => {
          forceUpdate();
        }, 100);
      }
    },
    [
      inputData,
      projectData,
      colors,
      subtemplateId,
      userId,
      fetchData,
      forceUpdate,
    ]
  );

  const handleScreenDataChange = useCallback(
    (sceneIndex, source) => {
      const newInputData = [...inputData];
      newInputData[selectedSceneIndex] = source;
      patchProjectData({ sources: newInputData });
    },
    [inputData, patchProjectData, selectedSceneIndex]
  );

  const handleColorChange = useCallback(
    async (color) => {
      const newColors = [...colors];
      newColors[selectedColorIndex] = removeHash(color);
      await patchProjectData({ colors: newColors });
      setSelectedColorIndex(-1);
    },
    [colors, patchProjectData, selectedColorIndex]
  );

  const handleBgmChange = useCallback(
    async (id, url) => {
      await patchProjectData({ bgmId: id, bgmUrl: url });
      setShowModalBgm(false);
    },
    [patchProjectData]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (
        !isEditing &&
        !showModalBgm &&
        !showModalPreviewScene &&
        !showModalRender
      ) {
        if (event.keyCode === 39 || event.keyCode === 40) {
          setSelectedSceneIndex((current) => {
            if (templateSources) {
              if (current + 1 >= templateSources.length) {
                return templateSources.length - 1;
              }
            }
            return current + 1;
          });
        }
        if (event.keyCode === 37 || event.keyCode === 38) {
          setSelectedSceneIndex((current) => {
            if (current - 1 < 0) {
              return 0;
            }
            return current - 1;
          });
        }
      }
    },
    [
      templateSources,
      isEditing,
      showModalBgm,
      showModalPreviewScene,
      showModalRender,
    ]
  );

  const handleClickRender = useCallback(async () => {
    if (parseInt(projectData.workProgress, 10) < 100) {
      message.error("아직 작업이 완료되지 않았습니다.");
      return;
    }
    setShowModalInputContact(true);
  }, [projectData]);

  const handleClickConfirmRender = useCallback(async () => {
    const result = await api
      .service()
      .projects.changeStatus({ subtemplateId, userId, status: 1 });
    if (result) {
      message.success(l10n.str("EDITOR_COMPLETE_RESULT")?.[0]);
      fetchData();
      setShowModalRender(false);
      sendGoBackToParent();
    }
  }, [subtemplateId, l10n, userId, fetchData, sendGoBackToParent]);

  useEffect(() => {
    if (isProjectLoaded) {
      if (!projectData?._id) {
        message.error(l10n.str("MESSAGE_PROJECT_NOT_FOUND"));
      }
    }
  }, [projectData, l10n, isProjectLoaded]);

  useEffect(() => {
    if (selectedSceneIndex < 0) {
      setSelectedSceneIndex(0);
    }
    if (templateSources) {
      if (selectedSceneIndex >= templateSources.length) {
        setSelectedSceneIndex(templateSources.length - 1);
      }
    }
  }, [selectedSceneIndex, templateSources]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // eslint-disable-next-line no-unused-vars
  const handleSceneChange = useCallback((index, source) => {
    setSelectedSceneIndex(index);
  }, []);

  const PopBgm = (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ flexFlow: "row nowrap" }}
      >
        <Col>
          <Text>{l10n.str("EDITOR_CURRENT_BGM")}</Text>
        </Col>
        <Col>
          <ButtonPlay
            className={isPlayingAudio ? "playing" : ""}
            onClick={() => {
              setIsPlayingAudio((current) => {
                return !current;
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Caption style={{ color: theme.gray, marginBottom: 0 }}>
            {bgm?.musicTitle || `(${l10n.str("EDITOR_BGM_UNTITLED")})`}
          </Caption>
        </Col>
      </Row>
      <Progress
        percent={musicProgress}
        size="small"
        showInfo={false}
        strokeColor={theme.primary}
        style={{ top: -10 }}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setShowPopBgm(false);
            setShowModalBgm(true);
          }}
        >
          {l10n.str("BUTTON_CHANGE_BGM")}
        </Button>
      </div>
    </>
  );

  const isEditable = useMemo(() => {
    if (projectData?.status === 1) {
      return false;
    }
    return true;
  }, [projectData]);

  const isRendering = useMemo(() => {
    return projectData?.status >= 1 && projectData?.status <= 2;
  }, [projectData]);

  useEffect(() => {
    let intervalId = null;
    if (!isEditable || isRendering) {
      intervalId = setInterval(() => {
        fetchData();
      }, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isEditable, isRendering, fetchData]);

  const PopComponents = (
    <>
      {["텍스트 적용하기", "이미지 적용하기", "텍스트와 이미지 적용하기"].map(
        (item, index) => {
          return (
            <Button
              htmlType="button"
              type={
                index + 1 === projectData?.lastSceneType ? "primary" : "brown"
              }
              block
              style={{ whiteSpace: "nowrap", textAlign: "left" }}
              onClick={() => {
                patchProjectData({ lastSceneType: index + 1 });
                setShowPopComponents(false);
              }}
            >
              <Text2>{item}</Text2>
            </Button>
          );
        }
      )}
    </>
  );

  return isProjectLoaded ? (
    <Wrapper>
      <GoBack
        onClick={() => {
          setShowModalGoBack(true);
        }}
      >
        뒤로
      </GoBack>
      <Row
        gutter={[16, 16]}
        style={{
          borderBottom: `1px solid ${theme.borderGray}`,
          padding: "1.875vw 1.875vw 0 1.875vw",
          marginBottom: 19,
        }}
      >
        <Col flex="172px">&nbsp;</Col>
        <Col flex="auto">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ minHeight: 84 }}
          >
            <Col>
              <Info theme="dark" type="ratio">
                <span>
                  &nbsp;&nbsp;
                  {
                    l10n.str("RATIO_LIST")[
                      parseInt(templateData.templateRatio, 10)
                    ]
                  }
                </span>
              </Info>
              <Info theme="dark" type="duration">
                <span>&nbsp;&nbsp;{templateData.videoDuration}s</span>
              </Info>
              <br />
              <Info theme="dark" type="text">
                <span>
                  &nbsp;&nbsp;{completedNumbers.text}/{typesToInput.text}
                </span>
              </Info>
              <Info theme="dark" type="picture">
                <span>
                  &nbsp;&nbsp;{completedNumbers.image}/{typesToInput.image}
                </span>
              </Info>
              <Info theme="dark" type="video">
                <span>
                  &nbsp;&nbsp;{completedNumbers.video}/{typesToInput.video}
                </span>
              </Info>
            </Col>
            <Col
              style={{
                textAlign: "center",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Title2 style={{ color: theme.white }}>
                {templateData.temTitle}
              </Title2>
            </Col>
            <Col style={{ minWidth: 192 }}>
              <ButtonRender
                workProgress={
                  isRendering
                    ? projectData.renderProgress || 0
                    : projectData.workProgress || 0
                }
                onClick={handleClickRender}
                disabled={!isEditable || isRendering}
              >
                {isRendering ? (
                  <>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 16, color: theme["white-color"] }}
                          spin
                        />
                      }
                    />
                    &nbsp; 렌더링
                  </>
                ) : (
                  <>
                    {projectData.workProgress >= 100 && "영상 렌더링 시작"}
                    {projectData.workProgress < 100 && (
                      <>
                        <AnimatedNumber
                          value={projectData.workProgress}
                          formatValue={parseInt}
                        />
                        %
                      </>
                    )}
                  </>
                )}
              </ButtonRender>
            </Col>
          </Row>
        </Col>
        <Col flex="64px">&nbsp;</Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: "0 1.875vw" }}>
        <Col flex="172px">&nbsp;</Col>
        <Col flex="auto" style={{ textAlign: "right" }}>
          <Tooltip title={l10n.str("MESSAGE_ABOUT_CHANGE_BGM")}>
            <Popover
              visible={showPopBgm}
              onVisibleChange={(visible) => {
                setShowPopBgm(visible);
                setIsPlayingAudio(false);
              }}
              overlayClassName="pop-editor pop-editor-bgm"
              trigger="click"
              content={PopBgm}
              placement="bottomLeft"
            >
              <Button type="brown" size="default" shape="round">
                <img src={iconBgm} alt="icon" style={{ width: 19 }} />
                <Text2
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    fontWeight: 700,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{l10n.str("EDITOR_BGM")}&nbsp;&nbsp;&nbsp;&nbsp;
                </Text2>
              </Button>
            </Popover>
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="영상 마지막 부분에 표시할 콘텐츠 유형을 선택합니다.">
            <Popover
              visible={showPopComponents}
              onVisibleChange={(visible) => {
                setShowPopComponents(visible);
              }}
              overlayClassName="pop-editor pop-editor-color"
              trigger="click"
              content={PopComponents}
              placement="bottomLeft"
            >
              <Button type="brown" size="default" shape="round">
                <img
                  src={iconComponents}
                  alt="icon"
                  style={{ width: 24, verticalAlign: "middle" }}
                />
                <Text2
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    fontWeight: 700,
                  }}
                >
                  &nbsp;&nbsp;구성요소&nbsp;&nbsp;&nbsp;&nbsp;
                </Text2>
              </Button>
            </Popover>
          </Tooltip>
        </Col>
        <Col flex="64px">&nbsp;</Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          flex: 1,
          height: 0,
          flexWrap: "nowrap",
          padding: "0 1.875vw 1.875vw 1.875vw",
        }}
        align="top"
        justify="space-between"
      >
        <Col flex="172px" style={{ height: "100%" }}>
          <SceneList
            sources={templateSources}
            selectedIndex={selectedSceneIndex}
            onSceneChange={handleSceneChange}
          />
        </Col>
        <Col flex="auto" style={{ height: "100%" }}>
          {!isEditable && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 50,
                backgroundColor: "rgba(0,0,0,0.5)",
                color: theme["white-color"],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div>
                {projectData?.status < 3 && (
                  <>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: theme["white-color"] }}
                          spin
                        />
                      }
                    />
                    <br />
                    렌더링이 시작될 때 까지 잠시만 기다려주세요.
                  </>
                )}
              </div>
            </div>
          )}
          <Screen
            source={templateSources?.[selectedSceneIndex] || []}
            data={inputData?.[selectedSceneIndex] || []}
            onChange={handleScreenDataChange}
            onStartEditing={() => {
              setIsEditing(true);
            }}
            onEndEditing={() => {
              setIsEditing(false);
            }}
          />
        </Col>
        <Col flex="64px" style={{ textAlign: "center" }}>
          &nbsp;
        </Col>
      </Row>
      <BGM
        bgm={bgm}
        invisible={!showModalBgm}
        duration={templateData.videoDuration}
        onClose={() => {
          setShowModalBgm(false);
        }}
        onComplete={handleBgmChange}
      />
      <ColorPicker
        originalColor={
          selectedColorIndex >= 0 &&
          addHash(templateData?.temColor?.[selectedColorIndex])
        }
        currentColor={
          selectedColorIndex >= 0 && addHash(colors?.[selectedColorIndex])
        }
        colorName={l10n.str("EDITOR_COLOR_LIST")?.[selectedColorIndex]}
        invisible={selectedColorIndex < 0}
        onComplete={handleColorChange}
        onClose={() => {
          setSelectedColorIndex(-1);
        }}
      />
      <Modal
        onClose={() => {
          setShowModalRender(false);
        }}
        invisible={!showModalRender}
        style={{ minWidth: 496, maxWidth: "90%" }}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: 24 }}>
            <img src={iconWarning} alt="warning" style={{ width: 28 }} />
          </div>
          <div style={{ marginBottom: 30 }}>
            <Text style={{ fontWeight: 700 }}>
              <String text="EDITOR_COMPLETE_NOTICE" />
            </Text>
          </div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button
                type="gray"
                block
                onClick={() => {
                  setShowModalRender(false);
                }}
              >
                {l10n.str("BUTTON_CANCEL")}
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" block onClick={handleClickConfirmRender}>
                {l10n.str("BUTTON_COMPLETE")}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        onClose={() => {
          setShowModalGoBack(false);
        }}
        invisible={!showModalGoBack || !showDeleteConfirmModal}
        closable
        title="안내"
        style={{ minWidth: 496, maxWidth: "90%" }}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: 24 }}>
            <img src={iconWarning} alt="warning" style={{ width: 28 }} />
          </div>
          <div style={{ marginBottom: 30 }}>
            <Text style={{ fontWeight: 700 }}>
              프로젝트를 저장하시겠습니까?
            </Text>
          </div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button
                type="gray"
                block
                onClick={async () => {
                  const res = await api
                    .service()
                    .projects.remove({ subtemplateId, userId });
                  if (res) {
                    sendGoBackToParent();
                  }
                }}
              >
                삭제
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                block
                onClick={() => {
                  sendGoBackToParent();
                  setShowModalGoBack(false);
                }}
              >
                저장
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <ModalPreviewScene
        invisible={!showModalPreviewScene}
        url={
          showModalPreviewScene &&
          process.env.REACT_APP_API_URL +
            "/subtemplate/" +
            subtemplateId +
            "/preview?previewFrame=" +
            selectedSceneIndex
        }
        onClose={() => {
          setShowModalPreviewScene(false);
        }}
      />

      <ModalInputContact
        invisible={!showModalInputContact}
        onComplete={async (contactNumber) => {
          if (contactNumber) {
            await patchProjectData({ contactNumber });
          } else {
            await patchProjectData({ contactNumber: null });
          }
          setShowModalInputContact(false);
          setShowModalRender(true);
        }}
        onClose={() => {
          setShowModalInputContact(false);
        }}
      />
    </Wrapper>
  ) : (
    <></>
  );
};

export default () => {
  return (
    <Switch>
      <Route path="/app/:subtemplateId" component={Editor} />
    </Switch>
  );
};
