import { action, observable, computed, set } from "mobx";
import queryString from "query-string";
import autoSave from "utils/storeAutoSave";
import api from "utils/api";

export class AuthStore {
  @observable userInformation = {};

  @observable lastAccessTokenIssue = new Date();

  @computed get tokens() {
    return this.userInformation?.token;
  }

  @action async refresh() {
    try {
      if (this.userInformation?.token?.refreshToken) {
        const { refreshToken } = this.userInformation?.token;
        const refreshResult = await api
          .service()
          .auth.refreshToken({ refreshToken });
        console.log(refreshResult);
        if (refreshResult?.accessToken) {
          const { accessToken } = refreshResult;
          const newUserInformation = { ...this.userInformation };

          newUserInformation.token.accessToken = accessToken;
          this.userInformation = newUserInformation;
          this.lastAccessTokenIssue = new Date();
          return accessToken;
        }
        await this.signout(false);
        window.location.href = `/auth/signin?${queryString.stringify({
          redirectUrl: window.location.pathname,
        })}`;
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  @action async getAccessToken() {
    const tokens = this.userInformation.token;
    let token = "";
    if (typeof tokens === "string") {
      token = tokens;
    }
    if (tokens && typeof tokens === "object") {
      const { accessToken } = tokens;
      if (accessToken) {
        token = accessToken;
      }
    }

    if (!token) {
      return false;
    }

    if (this.lastAccessTokenIssue) {
      const diffSec =
        (new Date().getTime() - new Date(this.lastAccessTokenIssue).getTime()) /
        1000;
      if (diffSec > 600) {
        const newAccessToken = await this.refresh();
        if (newAccessToken) {
          return newAccessToken;
        }
        return false;
      }
    }
    return token;
  }

  @action async loadUserInformation() {
    try {
      let userResult = await api
        .service(await this.getAccessToken())
        .users.info();
      if (Array.isArray(userResult)) {
        userResult = userResult[0];
      }
      const newUserInformation = {
        ...this.userInformation,
        ...userResult,
      };
      this.userInformation = newUserInformation;
    } catch (error) {
      console.log(error);
    }
    return this.userInformation;
  }

  @action async signin(
    data = { userEmail: "", password: "", channel: "email | facebook | google" }
  ) {
    try {
      const { userEmail, password, channel } = data;
      const signinResult = await api
        .service()
        .users.signin({ userEmail, password, channel });
      if (signinResult?.accessToken) {
        const { accessToken, refreshToken } = signinResult;

        let userResult = await api.service(accessToken).users.info();
        if (userResult) {
          if (Array.isArray(userResult)) {
            userResult = userResult[0];
          }
          const newUserInformation = {
            ...userResult,
            token: { accessToken, refreshToken, time: new Date() },
            // accessToken,
            // refreshToken,
          };
          this.userInformation = newUserInformation;
          this.lastAccessTokenIssue = new Date();
          return this.userInformation;
        }
      }
      if (signinResult?.idToken) {
        return { code: "NEED_TO_CONNECT", idToken: signinResult.idToken };
      }
      // const emailResult = await api.service().users.signin.email({
      //   userEmail,
      //   password
      // });

      // if (emailResult && emailResult.userUid) {
      //   const { userUid } = emailResult;
      //   const signinResult = await api.service().users.signin.token({
      //     userUid,
      //     languageCode: "ko"
      //   });

      //   if (signinResult && signinResult.token) {
      //     const { token } = signinResult;
      //     let userResult = await api.service(token).users.info();
      //     if (userResult) {
      //       if (Array.isArray(userResult)) {
      //         userResult = userResult[0];
      //       }
      //       this.userInformation = { ...userResult, token };
      //       return this.userInformation;
      //     }
      //   }
      // }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  @action async signout(destoryToken) {
    if (destoryToken) {
      await api.service(await this.getAccessToken()).users.signout();
    }
    this.userInformation = {};
    this.lastAccessTokenIssue = new Date();
  }

  constructor() {
    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    if (localStorage.getItem("userInformation") !== null) {
      const data = localStorage.getItem("userInformation");
      set(this, JSON.parse(data));
      if (this.userInformation.token) {
        this.refresh().then(() => {
          this.loadUserInformation();
        });
      }
    }
  }

  save(json) {
    localStorage.setItem("userInformation", json);
  }
}

export default AuthStore;
