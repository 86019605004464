import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import theme from "config/theme";

import api from "utils/api";

import useStores from "hooks/useStores";
import Area from "./ScreenArea";
import ModalSelectImage from "./ModalSelectImage";
import ModalSelectVideo from "./ModalSelectVideo";
import ModalChangeText from "./ModalChangeText";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${theme.black};
`;

const Canvas = styled.svg`
  width: 100%;
  height: 100%;
  background-color: ${theme.black};
`;

const BackgroundImage = styled.image`
  width: 100%;
  height: 100%;
`;

const Screen = ({
  source: sceneData,
  data,
  onChange: handleChange,
  onStartEditing: handleStart,
  onEndEditing: handleEnd,
}) => {
  const { auth } = useStores();
  const [mouseEffect, setMouseEffect] = useState(false);
  const sources = useMemo(() => {
    return (sceneData?.source || [])
      .map((item, index) => {
        return { ...item, index };
      })
      .sort(({ sourceZindex: a = -1 }, { sourceZindex: b = -1 }) => {
        return a < b ? -1 : 1;
      })
      .filter(({ sourceType }) =>
        ["I", "V", "T"].includes(`${sourceType}`.toUpperCase())
      );
  }, [sceneData]);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(-1);
  const selectedSource = useMemo(() => {
    return sources?.find((item) => {
      return item?.index === selectedAreaIndex;
    });
  }, [sources, selectedAreaIndex]);
  useEffect(() => {
    if (selectedAreaIndex >= 0) {
      handleStart();
    } else {
      handleEnd();
    }
  }, [selectedAreaIndex, handleStart, handleEnd]);

  // const images = useMemo(() => {
  //   return (
  //     sources?.filter((item) => {
  //       return (
  //         item.sourceType.toUpperCase() === "I" ||
  //         item.sourceType.toUpperCase() === "V"
  //       );
  //     }) || []
  //   );
  // }, [sources]);

  // const texts = useMemo(() => {
  //   return (
  //     sources?.filter((item) => {
  //       return item.sourceType.toUpperCase() === "T";
  //     }) || []
  //   );
  // }, [sources]);

  const { sceneIndex } = sceneData;

  const handleClickArea = useCallback((index) => {
    setSelectedAreaIndex(index);
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedAreaIndex(-1);
  }, []);

  const handleSubmitText = useCallback(
    (values) => {
      const newData = [...data];
      if (!Number.isNaN(selectedSource?.index)) {
        newData[selectedSource?.index] = values.text;
      }
      handleChange(sceneIndex, newData);
      handleModalClose();
    },
    [data, handleChange, handleModalClose, sceneIndex, selectedSource]
  );

  const handleSelectImage = useCallback(
    async (imageBase64) => {
      try {
        const file = await (await fetch(imageBase64)).blob();
        const uploadResult = await api
          .files(auth.userInformation.token)
          .files.upload({ file });

        const newData = [...data];
        if (!Number.isNaN(selectedSource?.index)) {
          newData[selectedSource?.index] = uploadResult.url;
        }

        handleChange(sceneIndex, newData);
      } catch (error) {
        console.log(error);
      }
      handleModalClose();
    },
    [auth, data, handleChange, handleModalClose, sceneIndex, selectedSource]
  );

  const handleSelectVideo = useCallback(
    async (video) => {
      try {
        const newData = [...data];
        if (!Number.isNaN(selectedSource?.index)) {
          newData[selectedSource?.index] = video.url;
        }
        handleChange(sceneIndex, newData);
      } catch (error) {
        console.log(error);
      }
      handleModalClose();
    },
    [data, handleChange, handleModalClose, sceneIndex, selectedSource]
  );

  return (
    <Wrapper className={mouseEffect ? "hover" : ""}>
      <Canvas viewBox="0 0 1280 720">
        <BackgroundImage href={sceneData.sceneImage} />
        {sources?.map(
          ({
            sourceArea,
            sourceType,
            index,
            sourceWidth,
            sourceHeight,
            iconArea,
            thumbnailArea,
            sourceTextLength,
          }) => {
            return (
              <Area
                points={sourceArea}
                type={sourceType}
                data={data[index]}
                size={{ width: sourceWidth, height: sourceHeight }}
                iconPath={{ x: iconArea?.[0], y: iconArea?.[1] }}
                thumbnailPath={{
                  x: thumbnailArea?.[0],
                  y: thumbnailArea?.[1],
                }}
                onClick={() => {
                  handleClickArea(index);
                }}
                onMouseEnter={() => {
                  setMouseEffect(true);
                }}
                onMouseLeave={() => {
                  setMouseEffect(false);
                }}
                textLength={sourceTextLength?.[0] || 20}
              />
            );
          }
        )}
      </Canvas>

      <ModalChangeText
        invisible={selectedAreaIndex < 0 || selectedSource.sourceType !== "T"}
        onClose={handleModalClose}
        defaultValue={data?.[selectedAreaIndex]}
        textLength={sources?.[selectedAreaIndex]?.sourceTextLength?.[0] || 20}
        onComplete={handleSubmitText}
      />
      <ModalSelectImage
        invisible={selectedAreaIndex < 0 || selectedSource.sourceType !== "I"}
        onClose={handleModalClose}
        aspect={
          selectedAreaIndex >= 0 && selectedSource.sourceType === "I"
            ? selectedSource.sourceWidth / selectedSource.sourceHeight
            : 1
        }
        onComplete={handleSelectImage}
      />
      <ModalSelectVideo
        invisible={selectedAreaIndex < 0 || selectedSource.sourceType !== "V"}
        videoSize={
          selectedAreaIndex >= 0 &&
          selectedSource.sourceType === "V" && {
            width: selectedSource.sourceWidth,
            height: selectedSource.sourceHeight,
          }
        }
        duration={
          selectedAreaIndex >= 0 &&
          selectedSource.sourceType === "V" &&
          parseInt(selectedSource.sourceVideoTime, 10)
        }
        onClose={handleModalClose}
        onComplete={handleSelectVideo}
      />
    </Wrapper>
  );
};

Screen.propTypes = {
  source: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onStartEditing: PropTypes.func,
  onEndEditing: PropTypes.func,
};

Screen.defaultProps = {
  source: [],
  data: [],
  onChange: () => {},
};

export default Screen;
