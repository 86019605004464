const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {boolean} flipH
 * @param {boolean} flipV
 */
export default async function getFlipedImg(imageSrc, flipH, flipV) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = image.width;
  canvas.height = image.height;

  const scaleH = flipH ? -1 : 1; // Set horizontal scale to -1 if flip horizontal
  const scaleV = flipV ? -1 : 1; // Set verical scale to -1 if flip vertical
  const posX = flipH ? image.width * -1 : 0; // Set x position to -100% if flip horizontal
  const posY = flipV ? image.height * -1 : 0; // Set y position to -100% if flip vertical

  ctx.save(); // Save the current state
  ctx.scale(scaleH, scaleV); // Set scale to flip the image
  ctx.drawImage(image, posX, posY, image.width, image.height); // draw the image
  ctx.restore(); // Restore   the last saved state

  // As Base64 string
  return canvas.toDataURL("image/png");

  // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, "image/png");
  // });
}
