import { useState, useEffect, useCallback } from "react";
import ResponsiveMode from "config/ResponsiveMode";
import breakpoints from "config/ResponsiveModeBreakpoints";

const useResponsiveMode = () => {
  const [mode, setMode] = useState(ResponsiveMode.unknown);

  const handleResizeWindow = useCallback(() => {
    let newMode = ResponsiveMode.unknown;

    breakpoints.some((size) => {
      if (window.innerWidth < size.width) {
        newMode = size.code;
        return true;
      }
      return false;
    });
    setMode(newMode);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [handleResizeWindow]);

  return mode;
};

export default useResponsiveMode;
