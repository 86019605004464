import lessVariables from "./theme.json";

const theme = {
  backgroundBlack: "#030303",
  backgroundLightGray: "#f5f5f5",
  borderGray: "#838383",
  // primary: "#e34958",
  primary: lessVariables["primary-color"],
  black: "#333333",
  white: "#ffffff",
  gray: lessVariables["gray-color"],
  darkGray: lessVariables["dark-gray-color"],
  lightgray: lessVariables["light-gray-color"], // #d9d9d9
  lightGray: lessVariables["light-gray-color"],
  grey: "#c9c9c9",
  editorBackground: "#000000",
  brown: lessVariables["brown-color"],
  darkTag: "#707070",
  headerBorder: "#707070",
  completed: "#c3d562",
  confirmed: "#69b4ff",
  skyBlue: "#69b4ff",
  backgroundGradientPrimary: "linear-gradient(93deg, #e34959 0%, #e8492b)",
};

export default { ...theme, ...lessVariables };
