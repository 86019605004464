const ResponsiveMode = {
  xxSmall: 0,
  xSmall: 1,
  small: 2,
  medium: 3,
  large: 4,
  xLarge: 5,
  unknown: 999,
};

export default ResponsiveMode;
