import React, { useState, useRef, useEffect } from "react";
// import styled from "styled-components";
import PropTypes from "prop-types";

import theme from "config/theme";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TypoGraphy from "components/Typography";
import Modal from "components/Modal";

import useStores from "hooks/useStores";
import SearchImage from "./SearchImage";
import CropImage from "./CropImage";

const { Caption, Text2 } = TypoGraphy;
const SelectImage = ({
  onComplete: handleComplete,
  onClose: handleClose,
  invisible,
  aspect,
}) => {
  const { l10n } = useStores();
  const refInput = useRef();
  const [imageUrl, setImageUrl] = useState("");
  const [isRendering, setIsRendering] = useState(false);

  const handleSearchImage = ({ image: selectedImage }) => {
    setImageUrl(selectedImage);
  };

  const handleBack = () => {
    setImageUrl("");
  };

  useEffect(() => {
    if (invisible) {
      setIsRendering(false);
      setImageUrl("");
    }
  }, [invisible]);

  return (
    <>
      <Modal
        id="selectImage"
        invisible={invisible || imageUrl || isRendering}
        onClose={handleClose}
        closable
        noPadding
        title={
          <div style={{ textAlign: "left", width: "100%" }}>
            <Button
              size="default"
              type="link"
              style={{ margin: 0, color: theme.primary }}
            >
              {l10n.str("EDITOR_IMAGE_FREE_IMAGE")}
            </Button>
            <input
              ref={refInput}
              type="file"
              style={{ width: 0, height: 0, visibility: "hidden" }}
              onChange={(event) => {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  setImageUrl(reader.result);
                };
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
              }}
            />
            <Button
              htmlType="button"
              size="default"
              type="link"
              style={{ margin: 0 }}
              onClick={() => {
                refInput.current.click();
              }}
            >
              {l10n.str("EDITOR_IMAGE_UPLOAD")}
            </Button>
          </div>
        }
        style={{ width: "80vw" }}
      >
        <div>
          <SearchImage onSelect={handleSearchImage} />
        </div>
      </Modal>
      {imageUrl && !isRendering && (
        <Modal
          invisible={invisible}
          onClose={handleClose}
          onBack={handleBack}
          closable
          backButton
          noPadding
          title={l10n.str("EDITOR_EDIT_IMAGE")}
          style={{ width: "80vw" }}
        >
          <div>
            <div
              style={{
                textAlign: "center",
                padding: 16,
                zIndex: 1,
                position: "relative",
              }}
            >
              <Caption>{l10n.str("EDITOR_EDIT_IMAGE_DESCRIPTION")}</Caption>
            </div>
            <CropImage
              image={imageUrl}
              aspect={aspect}
              onCrop={(value) => {
                setIsRendering(true);
                handleComplete(value);
              }}
            />
          </div>
        </Modal>
      )}

      {isRendering && (
        <Modal style={{ textAlign: "center" }}>
          <Text2>{l10n.str("EDITOR_IMAGE_APPLYING")}</Text2>
          <br />
          <br />
          <LoadingOutlined />
        </Modal>
      )}
    </>
  );
};
SelectImage.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  invisible: PropTypes.bool,
  aspect: PropTypes.number,
};

SelectImage.defaultProps = {
  onComplete: () => {},
  onClose: () => {},
  invisible: false,
  aspect: 1,
};

export default SelectImage;
