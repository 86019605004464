import React from "react";
import { inject, observer } from "mobx-react";

const String = inject("l10n")(
  observer(({ l10n, text, value }) => {
    return (
      <>
        {l10n
          .str(text, value)
          .split("\n")
          .map((val, i, arr) => {
            return (
              <>
                {val}
                {arr.length - 1 > i && <br />}
              </>
            );
          })}
      </>
    );
  })
);

export default String;
