import styled from "styled-components";
// import theme from "config/theme";

const Body2 = styled.span`
  font-size: 16px;
  line-height: 1.3;

  .mobile & {
    font-size: 13px;
  }
`;

export default Body2;
