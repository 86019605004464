import styled from "styled-components";

const Caption = styled.span`
  display: inline-block;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
`;

export default Caption;
