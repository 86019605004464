import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import theme from "config/theme";

import iconPhoto from "assets/img/icon-area-photo.svg";
import iconVideo from "assets/img/icon-area-video.svg";
import iconText from "assets/img/icon-area-text.svg";

import iconPhotoCompleted from "assets/img/icon-area-photo-done.svg";
import iconVideoCompleted from "assets/img/icon-area-video-done.svg";
import iconTextCompleted from "assets/img/icon-area-text-done.svg";

import iconPhotoActive from "assets/img/icon-area-photo-active.svg";
import iconVideoActive from "assets/img/icon-area-video-active.svg";
import iconTextActive from "assets/img/icon-area-text-active.svg";

const icons = {
  photo: {
    default: iconPhoto,
    active: iconPhotoActive,
    completed: iconPhotoCompleted,
  },
  video: {
    default: iconVideo,
    active: iconVideoActive,
    completed: iconVideoCompleted,
  },
  text: {
    default: iconText,
    active: iconTextActive,
    completed: iconTextCompleted,
  },
};

const Wrapper = styled.g`

  @keyframes op {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.2;
    }
    80% {
      opacity: 1;
    }
  }

  transition: opacity .2s ease-in-out;
  .hover & {
    opacity: 0.2;

    &:hover{
      opacity: 1;
    }
  }

  cursor: pointer;
  &:hover polygon,
  &.completed:hover polygon {
    animation: op .8s ease-in-out infinite 0s;
    /* fill: ${theme.black + "00"}; */
    z-index: 30;
  }
`;

const Polygon = styled.polygon`
  fill: ${theme.black + "7f"};
  stroke: ${theme.white};
  stroke-width: 1;
  /* opacity: 0.5; */

  transition: all 0.2s ease-in-out;

  &.completed {
    fill: ${theme.completed + "7f"};
    stroke: ${theme.completed};
  }
`;

const Text = styled.text`
  fill: ${theme.white};
  font-size: 16px;
  font-weight: 700;
`;

Text.defaultProps = {
  textAnchor: "middle",
  alignmentBaseline: "middle",
  kerning: -2,
};

const Photo = styled.image`
  width: 128px;
`;

Photo.defaultProps = {
  alignmentBaseline: "middle",
};

const IconWrapper = styled.rect`
  fill: ${theme.lightgray};
`;

IconWrapper.defaultProps = {
  width: 36,
  height: 36,
  x: 8,
  y: 8,
  rx: 3,
  ry: 3,
};

const ICON_WIDTH = 40;

const getCenterOfPolygonFromPoints = (_points) => {
  try {
    const arrayX = _points.map((point) => point[0]);
    const arrayY = _points.map((point) => point[1]);
    const minX = Math.min(...arrayX);
    const maxX = Math.max(...arrayX);
    const minY = Math.min(...arrayY);
    const maxY = Math.max(...arrayY);
    return [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2];
  } catch (error) {
    return [0, 0];
  }
};

const omitText = (text, length) => {
  if ((`${text || ""}`).length > length) {
    return (`${text || ""}`).substr(0, length) + "...";
  }
  return text;
};

const Area = ({
  points,
  data,
  type,
  onClick: handleClick,
  size,
  iconPath: customIconPath,
  thumbnailPath: customThumbnailPath,
  onMouseEnter: handleMouseEnter,
  onMouseLeave: handleMouseLeave,
  textLength,
}) => {
  // console.log(points);
  const firstPoint = useMemo(() => {
    return points[0];
  }, [points]);
  const relativePoints = useMemo(() => {
    return JSON.parse(JSON.stringify(points)).map((point) => {
      // eslint-disable-next-line no-param-reassign
      point[0] -= firstPoint[0];
      // eslint-disable-next-line no-param-reassign
      point[1] -= firstPoint[1];
      return point;
    });
  }, [points, firstPoint]);

  const thumbnailSize = useMemo(() => {
    const result = {
      width: size.width ? size.width / 4 : 80,
      height: size.height ? size.height / 4 : 80,
    };
    if (result.width < 80) {
      result.width = 80;
    }
    if (result.width > 160) {
      result.width = 160;
    }
    if (result.height < 80) {
      result.height = 80;
    }
    if (result.height > 160) {
      result.height = 160;
    }
    if (size.width > size.height) {
      result.height = (result.width * size.height) / size.width;
    } else {
      result.width = (result.height * size.width) / size.height;
    }
    return result;
  }, [size]);

  const thumbnailPath = useMemo(() => {
    if (
      typeof customThumbnailPath?.x === "number" &&
      typeof customThumbnailPath?.y === "number"
    ) {
      const result = { ...customThumbnailPath };
      if (result.x < 0) {
        result.x = firstPoint[0] + 15;
      }
      if (result.y < 0) {
        result.y = firstPoint[1] + 15;
      }
      return result;
    }
    const result = {
      x: getCenterOfPolygonFromPoints(points)[0] - thumbnailSize.width / 2,
      y: getCenterOfPolygonFromPoints(points)[1] - thumbnailSize.height / 2,
    };
    return result;
  }, [customThumbnailPath, points, thumbnailSize, firstPoint]);

  const relativeThumbnailPath = useMemo(() => {
    return {
      x: thumbnailPath.x - firstPoint[0],
      y: thumbnailPath.y - firstPoint[1],
    };
  }, [firstPoint, thumbnailPath]);

  const iconPath = useMemo(() => {
    if (data && type !== "T") {
      const result = {
        x: thumbnailPath.x + thumbnailSize.width - ICON_WIDTH / 2,
        y: thumbnailPath.y + thumbnailSize.height - ICON_WIDTH / 2,
      };

      return result;
    }

    if (
      typeof customIconPath?.x === "number" &&
      typeof customIconPath?.y === "number"
    ) {
      const result = { ...customIconPath };
      if (result.x < 0) {
        result.x = firstPoint[0] + 15 + ICON_WIDTH / 2;
      }
      if (result.y < 0) {
        result.y = firstPoint[1] + 15 + ICON_WIDTH / 2;
      }
      return result;
    }
    const result = {
      x: getCenterOfPolygonFromPoints(points)[0],
      y: getCenterOfPolygonFromPoints(points)[1],
    };
    return result;
  }, [
    customIconPath,
    points,
    thumbnailPath,
    data,
    thumbnailSize,
    type,
    firstPoint,
  ]);

  const relativeIconPath = useMemo(() => {
    return {
      x: iconPath.x - firstPoint[0],
      y: iconPath.y - firstPoint[1],
    };
  }, [firstPoint, iconPath]);

  useEffect(() => {
    const imageList = [
      iconPhoto,
      iconPhotoActive,
      iconPhotoCompleted,
      iconText,
      iconTextActive,
      iconTextCompleted,
      iconVideo,
      iconVideoActive,
      iconVideoCompleted,
    ];

    imageList.forEach((image) => {
      new Image().src = image;
    });
  }, []);

  return (
    <>
      <Wrapper
        transform={`translate(${firstPoint.join(",")})`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <defs>
          <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
            <feFlood floodColor={theme.brown} />
            <feComposite in="SourceGraphic" operator="xor" />
          </filter>
        </defs>
        <Polygon
          className={data ? "completed" : ""}
          points={relativePoints
            .map((point) => {
              return point.join(",");
            })
            .join(" ")}
        />

        {type === "T" && (
          <>
            <image
              width={ICON_WIDTH}
              href={icons.text[data ? "completed" : "default"]}
              x={relativeIconPath.x - ICON_WIDTH / 2}
              y={relativeIconPath.y - ICON_WIDTH / 2}
            />
            {/* 텍스트 배경색 https://stackoverflow.com/questions/15500894/background-color-of-text-in-svg */}
            <Text
              filter="url(#solid)"
              x={relativeIconPath.x}
              y={relativeIconPath.y}
              // x={getCenterOfPolygonFromPoints(relativePoints)[0]}
              // y={getCenterOfPolygonFromPoints(relativePoints)[1]}
            >
              {omitText(data, textLength)}
            </Text>
            <Text
              x={relativeIconPath.x}
              y={relativeIconPath.y}
              // x={getCenterOfPolygonFromPoints(relativePoints)[0]}
              // y={getCenterOfPolygonFromPoints(relativePoints)[1]}
            >
              {omitText(data, textLength)}
            </Text>
          </>
        )}
        {type === "I" && (
          <>
            {data && (
              <Photo
                x={relativeThumbnailPath.x}
                y={relativeThumbnailPath.y}
                style={{
                  ...thumbnailSize,
                }}
                href={`${data}`}
              />
            )}
            <image
              width={ICON_WIDTH}
              href={icons.photo[data ? "completed" : "default"]}
              x={relativeIconPath.x - ICON_WIDTH / 2}
              y={relativeIconPath.y - ICON_WIDTH / 2}
            />
          </>
        )}
        {type === "V" && (
          <>
            {data && (
              <Photo
                x={relativeThumbnailPath.x}
                y={relativeThumbnailPath.y}
                style={{
                  ...thumbnailSize,
                }}
                href={`${data}/thumbnail`}
              />
            )}
            <image
              width={ICON_WIDTH}
              href={icons.video[data ? "completed" : "default"]}
              x={relativeIconPath.x - ICON_WIDTH / 2}
              y={relativeIconPath.y - ICON_WIDTH / 2}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

Area.propTypes = {
  points: PropTypes.arrayOf(PropTypes.array).isRequired,
  type: PropTypes.oneOf(["I", "T", "V"]),
  size: { width: PropTypes.number, height: PropTypes.number },
  iconPath: { x: PropTypes.number, y: PropTypes.number },
  thumbnailPath: { x: PropTypes.number, y: PropTypes.number },
  data: PropTypes.string,
  onClick: PropTypes.func,
  textLength: PropTypes.number,
};

Area.defaultProps = {
  data: "",
  type: "T",
  onClick: () => {},
};

export default Area;
