import styled from "styled-components";
// import theme from "config/theme";

const Headline2 = styled.span`
  display: inline-block;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;

  .mobile & {
    font-size: 22px;
  }
`;

export default Headline2;
