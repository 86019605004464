import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";

import Editor from "pages/Editor";

import "./App.less";
import "animate.css";
import usePlatform from "hooks/usePlatform";
import useResponsiveMode from "hooks/useResponsiveMode";
import ResponsiveMode from "config/ResponsiveMode";
// import 'antd/dist/antd.css';

const App = () => {
  const platform = usePlatform();
  const responsiveMode = useResponsiveMode();

  useEffect(() => {
    document.getElementsByTagName("html")[0].className = platform;
  }, [platform]);

  useEffect(() => {
    const keys = Object.keys(ResponsiveMode);
    const elementBody = document.getElementsByTagName("body")[0];
    elementBody.classList.remove(...keys);
    if (keys[responsiveMode]) {
      elementBody.classList.add(keys[responsiveMode]);
      return;
    }
    elementBody.classList.add(keys.pop());
  }, [responsiveMode]);

  return (
    <div className={["App", platform].join(" ")}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VPLATE</title>
        <link rel="canonical" href="https://vplate.io/" />
      </Helmet>
      <Switch>
        <Route path="/app">
          <Editor />
        </Route>
      </Switch>
    </div>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
