import { useState, useEffect } from "react";

const usePlatform = () => {
  const [platform, setPlatform] = useState("");

  const handleResizeWindow = () => {
    if (window.innerWidth < 768) {
      setPlatform("mobile");
    } else {
      setPlatform("desktop");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return platform;
};

export default usePlatform;
