import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import SceneItem from "./SceneItem";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 8px;
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SceneList = ({ sources, onSceneChange, selectedIndex }) => {
  const refWrapper = useRef();

  useEffect(() => {
    const { scrollTop, clientWidth, clientHeight } = refWrapper.current;
    if (scrollTop > (clientWidth / 16) * 9 * selectedIndex) {
      refWrapper.current.scrollTop = (clientWidth / 16) * 9 * selectedIndex;
    }

    if (scrollTop + clientHeight <= (clientWidth / 16) * 9 * selectedIndex) {
      refWrapper.current.scrollTop =
        (clientWidth / 16) * 9 * (selectedIndex + 1) - clientHeight;
    }
  }, [selectedIndex]);
  return (
    <Wrapper ref={refWrapper}>
      {sources?.map((source, index) => (
        <SceneItem
          image={source?.sceneImage}
          onClick={() => {
            onSceneChange(index, { ...source });
          }}
          isDone={
            source?.source?.filter((area) => {
              return (
                !area.isDone && ["T", "I", "V"].indexOf(area.sourceType) >= 0
              );
            }).length <= 0
          }
          isSelected={selectedIndex === index}
        />
      ))}
    </Wrapper>
  );
};

SceneList.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSceneChange: PropTypes.func,
  selectedIndex: PropTypes.number.isRequired,
};

SceneList.defaultProps = {
  onSceneChange: () => {},
};

export default SceneList;
