import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import PropTypes from "prop-types";

import api from "utils/api";
import theme from "config/theme";
import Modal from "components/Modal";
import Container from "components/Container";
import Typography from "components/Typography";

import iconFadeEffect from "assets/img/icon-fade-effect.svg";

import useStores from "hooks/useStores";
import Item from "./Item";
import Trimmer from "./Trim";
import SearchPanel from "./SearchPanel";

const { Text2, Caption } = Typography;

const Body = styled(Container)`
  width: 80vw;
  /* min-height: 50vh; */
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

const BGM = ({
  invisible,
  onClose: handleClose,
  duration,
  bgm: currentBgm,
  onComplete: handleComplete,
}) => {
  const { l10n, auth } = useStores();
  const [musicList, setMusicList] = useState([]);
  const [, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedMusicId, setSelectedMusicId] = useState(null);
  const selectedMusic = useMemo(() => {
    return musicList.find((music) => {
      return music._id === selectedMusicId;
    });
  }, [musicList, selectedMusicId]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trimStartTime, setTrimStartTime] = useState(0);
  const [isRendering, setIsRendering] = useState(false);
  const [fadeEffect, setFadeEffect] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({
    moods: [0],
    genres: [0],
  });

  const fetchData = useCallback(
    async ({ page, limit }) => {
      const result = await api
        .service(await auth.getAccessToken())
        .music.list({ page, limit: limit || 40, ...selectedOptions });
      if (Array.isArray(result?.result)) {
        setMusicList((current) => {
          const newMusicList = [...current];
          newMusicList.push(
            ...result.result.filter((item) => {
              return item._id !== currentBgm?._id;
            })
          );

          const ids = newMusicList.map((template) => {
            return template?._id;
          });
          return newMusicList.filter((template, index) => {
            return ids.indexOf(template._id) === index;
          });
        });
        if (result.result.length <= 0) {
          setHasMore(false);
        }
      }
    },
    [auth, currentBgm, selectedOptions]
  );

  const uploadMusic = async () => {
    setIsRendering(true);
    try {
      const result = await api.files(auth.userInformation.token).music.upload({
        musicUrl: selectedMusic?.musicUrl,
        trimAudioDuration: duration,
        trimAudioStart: trimStartTime,
        fadeEffect,
      });
      if (result?.musicUrl) {
        message.success(l10n.str("MESSAGE_APPLY_BGM_SUCCESS"));
        handleComplete(selectedMusic?._id, result?.musicUrl);
      } else {
        throw Error();
      }
    } catch (error) {
      message.error(l10n.str("MESSAGE_APPLY_BGM_FAILED"));
    }
    setTimeout(() => {
      setIsRendering(false);
    }, 300);
  };

  useEffect(() => {
    if (!invisible) {
      fetchData({ page: 1 });
    }
  }, [fetchData, invisible, selectedOptions]);

  useEffect(() => {
    setMusicList([{ ...currentBgm, isSelected: true }]);
    setCurrentPage(1);
    setHasMore(true);
  }, [currentBgm, selectedOptions]);

  useEffect(() => {
    setSelectedMusicId(currentBgm?._id || null);
    setIsPlaying(false);
    setFadeEffect(true);
    setTrimStartTime(0);
  }, [invisible, currentBgm]);

  return (
    <>
      {!isRendering && (
        <Modal
          invisible={invisible}
          closable
          onClose={handleClose}
          title={l10n.str("EDITOR_BGM")}
          style={{ overflowY: "hidden" }}
          footer={
            <div
              style={{
                width: "100%",
                bottom: 0,
                left: 0,
                padding: 16,
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                size="default"
                style={{ margin: 0 }}
                onClick={uploadMusic}
              >
                {l10n.str("BUTTON_APPLY")}
              </Button>
            </div>
          }
        >
          <Body>
            {!isRendering && (
              <div style={{ marginBottom: 24 }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 24 }}
                >
                  <Col>
                    <Text2 style={{ fontWeight: 700, color: theme.lightgray }}>
                      {selectedMusic?.musicTitle || ""}
                    </Text2>
                  </Col>
                  <Col>
                    <Caption style={{ color: theme.lightgray }}>
                      {parseInt(
                        parseInt(selectedMusic?.musicLength, 10) / 60,
                        10
                      )}
                      :
                      {(
                        "00" +
                        (parseInt(selectedMusic?.musicLength, 10) % 60)
                      ).substr(-2)}{" "}
                    </Caption>
                  </Col>
                </Row>
                <Trimmer
                  url={
                    selectedMusicId
                      ? selectedMusic?.musicUrl
                      : currentBgm?.musicUrl
                  }
                  duration={duration}
                  isPlaying={isPlaying}
                  value={trimStartTime}
                  onChange={setTrimStartTime}
                  style={{ marginBottom: 16 }}
                />
                <Row justify="space-between" align="middle">
                  <Col>
                    <Caption>Duration {duration || "?"}s</Caption>
                  </Col>
                  <Col>
                    <Button
                      type={fadeEffect ? "primary" : "brown"}
                      style={{
                        backgroundColor: fadeEffect
                          ? theme.primary
                          : theme.brown,
                      }}
                      size="small"
                      onClick={() => {
                        setFadeEffect((current) => {
                          return !current;
                        });
                      }}
                    >
                      <img
                        src={iconFadeEffect}
                        alt="fade effect"
                        style={{ width: 11 }}
                      />
                      &nbsp;&nbsp; Fade Effect
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <div
              id="changeBgm"
              style={{
                position: "relative",
                maxHeight: "40vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <SearchPanel onChange={setSelectedOptions} />
              <InfiniteScroll
                scrollableTarget="changeBgm"
                dataLength={musicList.length}
                hasMore={hasMore}
                next={() => {
                  setCurrentPage((current) => {
                    const nextPage = current + 1;
                    console.log("nextPage");
                    console.log(nextPage);
                    fetchData({ page: nextPage });
                    return nextPage;
                  });
                }}
                style={{ overflowX: "hidden" }}
              >
                <Row gutter={[16, 16]} style={{ padding: 4 }}>
                  {musicList.map((music) => {
                    return (
                      <Col lg={4} md={6} sm={12} xs={24}>
                        <Item
                          className={[
                            isPlaying && "playing",
                            selectedMusicId === music?._id && "selected",
                          ]}
                          data={music}
                          onClick={() => {
                            setSelectedMusicId((currentIndex) => {
                              if (currentIndex === music?._id) {
                                setIsPlaying((currentIsPlaying) => {
                                  return !currentIsPlaying;
                                });
                              } else {
                                setIsPlaying(true);
                              }
                              return music?._id;
                            });
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </InfiniteScroll>
            </div>
          </Body>
        </Modal>
      )}

      {isRendering && (
        <Modal style={{ textAlign: "center" }}>
          <Text2>{l10n.str("EDITOR_BGM_APPLYING")}</Text2>
          <br />
          <br />
          <LoadingOutlined />
        </Modal>
      )}
    </>
  );
};

BGM.propTypes = {
  bgm: PropTypes.oneOfType([PropTypes.object]),
  invisible: PropTypes.bool,
  duration: PropTypes.number,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};

export default BGM;
