import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import theme from "config/theme";

import iconClose from "assets/img/icon-close.svg";
import iconBack from "assets/img/icon-back.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 1009;
  opacity: 1;

  &.invisible {
    z-index: -1;
    opacity: 0;
    /* top: -100%; */
    transition: z-index 0.3s step-end, opacity 0.2s ease-in-out;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${theme.black};
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;

  .invisible & {
    opacity: 0;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  max-height: 90%;
  overflow: auto;
  min-width: 300px;
  min-height: 200px;
  display: inline-block;
  border: 2px solid ${theme.white};
  background-color: ${theme.backgroundBlack};
  color: ${theme.white};
  display: flex;
  flex-direction: column;

  .mobile & {
    width: 96%;
  }
`;

const Body = styled.div`
  width: 100%;
  height: auto;
  padding: 36px 64px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  .mobile & {
    padding: 8px 12px;
  }
`;

const ButtonClose = styled.div`
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 12px;
  width: 32px;
  height: 32px;
  background-image: url(${iconClose});
  background-size: 12px auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;

  .mobile & {
    right: 4px;
    top: 6px;
    width: 24px;
    height: 24px;
    background-size: 10px auto;
  }
`;

ButtonClose.defaultProps = {
  type: "button",
};

const ButtonBack = styled.div`
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 12px;
  width: 32px;
  height: 32px;
  background-image: url(${iconBack});
  background-size: 12px auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;

  .mobile & {
    left: 4px;
    top: 6px;
    width: 24px;
    height: 24px;
    background-size: 10px auto;
  }
`;

ButtonBack.defaultProps = {
  type: "button",
};

const TitleBar = styled.div`
  width: 100%;
  height: auto;
  min-height: 56px;
  position: relative;
  /* position: absolute;
  left: 0;
  top: 0; */
  background-color: ${theme.white};
  color: ${theme.black};
  display: flex;
  justify-content: center;
  align-items: center;

  .mobile & {
    min-height: 36px;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: auto;
  min-height: 56px;
  position: relative;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  background-color: ${theme.backgroundBlack};
  color: ${theme.white};
  display: flex;
  justify-content: center;
  align-items: center;

  .mobile & {
    min-height: 36px;
  }
`;

const Modal = ({
  id,
  invisible,
  noPadding,
  title,
  footer,
  closable,
  backButton,
  onClose: handleClose,
  onBack: handleBack,
  children,
  style,
}) => {
  const handleKeydown = useCallback(
    (event) => {
      if (event.keyCode === 27 && !invisible && closable) {
        handleClose();
      }
    },
    [handleClose, invisible, closable]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  return (
    <Wrapper className={invisible ? "invisible" : ""}>
      <Background onClick={closable ? handleClose : undefined} />
      <ModalWrapper
        style={
          {
            // ...(noPadding ? { padding: 0 } : {}),
            // ...(title && { paddingTop: 64 }),
            // ...(footer && { paddingBottom: 64 }),
          }
        }
      >
        {title && <TitleBar>{title}</TitleBar>}
        {closable && title && <ButtonClose onClick={handleClose} />}
        {backButton && <ButtonBack onClick={handleBack} />}
        <Body
          id={id}
          style={{
            ...style,
            ...(noPadding ? { padding: 0 } : {}),
          }}
        >
          {children}
        </Body>
        {footer && <Footer>{footer}</Footer>}
      </ModalWrapper>
    </Wrapper>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  footer: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  invisible: PropTypes.bool,
  noPadding: PropTypes.bool,
  closable: PropTypes.bool,
  backButton: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

Modal.defaultProps = {
  id: "",
  closable: false,
  backButton: false,
  noPadding: false,
  onClose: () => {},
  onBack: () => {},
};

export default Modal;
