import React, { useMemo, useState, useEffect } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import theme from "config/theme";

import PropTypes from "prop-types";

import Checkbox from "components/Checkbox";
import useStores from "hooks/useStores";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px;
  margin-bottom: 16px;

  &::before {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${theme.black};
    opacity: 0.4;
  }

  & .ant-checkbox,
  & .ant-checkbox-wrapper {
    color: ${theme.white} !important;
  }
`;

const SearchPanel = ({ onChange: handleChange }) => {
  const { l10n } = useStores();

  const [selectedMoods, setSelectedMoods] = useState([0]);
  const [selectedGenres, setSelectedGenres] = useState([0]);

  const moods = useMemo(() => {
    const result = l10n.str("EDITOR_BGM_MOODS");
    if (Array.isArray(result)) {
      return result;
    }
    return [];
  }, [l10n]);

  const genres = useMemo(() => {
    const result = l10n.str("EDITOR_BGM_GENRES");
    if (Array.isArray(result)) {
      return result;
    }
    return [];
  }, [l10n]);

  useEffect(() => {
    handleChange({ moods: selectedMoods, genres: selectedGenres });
  }, [handleChange, selectedMoods, selectedGenres]);

  return (
    <Wrapper>
      <Row
        gutter={[16, 0]}
        justify="space-between"
        align="middle"
        style={{ marginBottom: 8 }}
      >
        <Col span={2}>{l10n.str("MOODS")}</Col>
        <Col span={22}>
          <Checkbox.Group
            options={moods.map((option, index) => {
              return { label: option, value: index };
            })}
            value={selectedMoods}
            onChange={(value) => {
              setSelectedMoods((currentOptions) => {
                if (value.indexOf(0) >= 0) {
                  if (currentOptions.indexOf(0) >= 0) {
                    value.splice(value.indexOf(0), 1);
                    return value;
                  }
                  return [0];
                }
                if (value.length <= 0) {
                  return [0];
                }
                return value;
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between" align="middle">
        <Col span={2}>{l10n.str("GENRES")}</Col>
        <Col span={22}>
          <Checkbox.Group
            options={genres.map((option, index) => {
              return { label: option, value: index };
            })}
            value={selectedGenres}
            onChange={(value) => {
              setSelectedGenres((currentOptions) => {
                if (value.indexOf(0) >= 0) {
                  if (currentOptions.indexOf(0) >= 0) {
                    value.splice(value.indexOf(0), 1);
                    return value;
                  }
                  return [0];
                }
                if (value.length <= 0) {
                  return [0];
                }
                return value;
              });
            }}
          />
        </Col>
      </Row>
      {/* EDITOR_BGM_MOODS */}
      {/* EDITOR_BGM_GENRES */}
    </Wrapper>
  );
};

SearchPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SearchPanel;
