import styled from "styled-components";
import theme from "config/theme";

const Body = styled.span`
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  /* color: ${theme.black}; */

  .mobile & {
    font-size: 14px;
  }
`;

export default Body;
