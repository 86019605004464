import styled from "styled-components";
import theme from "config/theme";

const Title = styled.span`
  font-size: 2em;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  /* color: ${theme.black}; */

  .mobile &{
    font-size: 18px;
  }
`;

export default Title;
