import { action, observable, set, computed } from "mobx";
import autoSave from "utils/storeAutoSave";
// import api from "utils/api";
import moment from "moment";
import l10nData from "config/l10n";
import "moment/locale/ko";
import "moment/locale/th";
import { getUserLocales } from "get-user-locale";

// const ko = Object.keys(l10nData.ko);
// const th = Object.keys(l10nData.th);
// const no = ko.filter((key) => {
//   return th.indexOf(key) < 0;
// });

// const re = {};
// no.forEach((key) => {
//   re[key] = l10nData.ko[key];
// });

// console.log(JSON.stringify(re, null, 4));

export class L10nStore {
  defaultLanguage = "en";

  availableLanguageCodes = ["ko", "en", "th"];

  countryCodes = {
    ko: "KR",
    en: "",
    th: "TH",
  };

  @observable languageCode = "en";

  @computed get countryCode() {
    return this.countryCodes[this.languageCode] || "";
  }

  @action changeLanguage = (code) => {
    if (this.availableLanguageCodes.indexOf(code) >= 0) {
      this.languageCode = code;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  @action str = (id = "", value = {}) => {
    try {
      let text = l10nData[this.languageCode][id];
      if (!text) {
        text = id;
      }
      if (typeof text === "function") {
        text = text(value);
      }
      return text;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  constructor() {
    autoSave(this, this.save.bind(this));
    this.load();
    moment.locale(this.languageCode);
  }

  load() {
    if (localStorage.getItem("languageCode") !== null) {
      const data = localStorage.getItem("languageCode");
      set(this, JSON.parse(data));
      moment.locale(this.languageCode);
    } else {
      const userLocales = getUserLocales() || [];
      userLocales.reverse().forEach((locale) => {
        const languageCode = (locale + "").substring(0, 2).toLowerCase();
        if (this.availableLanguageCodes.indexOf(languageCode) >= 0) {
          this.languageCode = languageCode;
        }
      });
      moment.locale(this.languageCode);
    }
  }

  save(json) {
    localStorage.setItem("languageCode", json);
  }
}

export default L10nStore;
