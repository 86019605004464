import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { inject, observer } from "mobx-react";
import theme from "config/theme";
import api from "utils/api";
import PropTypes from "prop-types";

import Modal from "components/Modal";
import Typography from "components/Typography";

import CropAndTrimVideo from "./CropAndTrimVideo";
import SearchVideo from "./SearchVideo";

const { Text2 } = Typography;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const SelectVideo = inject(
  "auth",
  "l10n"
)(
  observer(
    ({
      auth,
      l10n,
      onComplete: handleComplete,
      onClose: handleClose,
      invisible,
      videoSize,
      duration,
    }) => {
      const refInput = useRef();
      const [videoUrl, setVideoUrl] = useState("");
      const [value, setValue] = useState({
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        startTime: 0,
      });
      const [isRendering, setIsRendering] = useState(false);

      useEffect(() => {
        if (invisible) {
          setVideoUrl("");
          setIsRendering(false);
        }
      }, [invisible]);

      const handleSearchVideo = ({ video: selectedVideo }) => {
        setVideoUrl(selectedVideo);
      };

      const handleChange = useCallback((v) => {
        setValue(v);
      }, []);

      const handleClickComplete = useCallback(async () => {
        setIsRendering(true);
        const file = await (
          await fetch(
            ((videoUrl + "").startsWith("http")
              ? process.env.REACT_APP_CORS_PROXY_URL || ""
              : "") + videoUrl,
            {
              headers:
                (videoUrl + "").indexOf("vplate.io") >= 0
                  ? { "Access-Control-Allow-Origin": "*" }
                  : {},
            }
          )
        ).blob();
        const { x, y, height, width, startTime } = value;
        const { width: renderWidth, height: renderHeight } = videoSize;
        const result = await api
          .files(auth.userInformation.token)
          .files.cropAndTrimVideo({
            file,
            width,
            height,
            x,
            y,
            startTime,
            renderWidth,
            renderHeight,
            duration,
          });
        if (result && result.url) {
          message.success(l10n.str("MESSAGE_APPLY_VIDEO_SUCCESS"));
          handleComplete(result);
        } else {
          message.error(l10n.str("MESSAGE_APPLY_VIDEO_FAILED"));
        }
      }, [
        auth.userInformation.token,
        duration,
        value,
        videoSize,
        videoUrl,
        handleComplete,
        l10n,
      ]);

      return (
        <>
          {!videoUrl && !isRendering && (
            <Modal
              id="selectVideo"
              invisible={invisible}
              closable
              onClose={() => {
                setVideoUrl("");
                handleClose();
              }}
              style={{ width: "80vw", minHeight: 360 }}
              title={
                <div style={{ textAlign: "left", width: "100%" }}>
                  <Button
                    size="default"
                    type="link"
                    style={{ margin: 0, color: theme.primary }}
                  >
                    {l10n.str("EDITOR_VIDEO_FREE_VIDEO")}
                  </Button>
                  <input
                    type="file"
                    ref={refInput}
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={async (event) => {
                      const file = event.target.files[0];
                      if (file?.type?.endsWith("mp4")) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          setVideoUrl(reader.result);
                        };
                      } else {
                        message.loading("");
                        try {
                          const uploadResult = await api
                            .files(await auth.getAccessToken())
                            .files.upload({ file });
                          if (uploadResult?.url) {
                            setVideoUrl(
                              (uploadResult?.url + "").replace(
                                "http://",
                                "https://"
                              )
                            );
                            message.destroy();
                          } else {
                            throw new Error();
                          }
                        } catch (error) {
                          message.error("");
                        }
                      }
                      if (event?.target?.value) {
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = null;
                      }
                    }}
                    style={{ width: 0, height: 0, visibility: "hidden" }}
                  />
                  <Button
                    htmlType="button"
                    size="default"
                    type="link"
                    style={{ margin: 0 }}
                    onClick={() => {
                      refInput.current.click();
                    }}
                  >
                    {l10n.str("EDITOR_VIDEO_UPLOAD")}
                  </Button>
                </div>
              }
            >
              <Wrapper>
                {!invisible && (
                  <SearchVideo
                    onSelect={handleSearchVideo}
                    minDuration={duration}
                  />
                )}
                {/* {!invisible && ( */}
                {/* <ReactVideoTrimmer duration={duration} showEncodeBtn /> */}
                {/* )} */}
              </Wrapper>
            </Modal>
          )}
          {videoUrl && !isRendering && (
            <Modal
              invisible={invisible}
              backButton
              closable
              title={l10n.str("EDITOR_EDIT_VIDEO")}
              footer={
                <div style={{ textAlign: "right" }}>
                  <Button type="primary" onClick={handleClickComplete}>
                    {l10n.str("BUTTON_COMPLETE")}
                  </Button>
                </div>
              }
              onBack={() => {
                setVideoUrl("");
              }}
              onClose={() => {
                setVideoUrl("");
                handleClose();
              }}
              style={{ width: "80vw", minHeight: 360 }}
            >
              <Wrapper>
                <CropAndTrimVideo
                  video={videoUrl}
                  duration={duration}
                  videoSize={videoSize}
                  onChange={handleChange}
                />
              </Wrapper>
            </Modal>
          )}
          {isRendering && (
            <Modal style={{ textAlign: "center" }}>
              <Text2>{l10n.str("EDITOR_VIDEO_APPLYING")}</Text2>
              <br />
              <br />
              <LoadingOutlined />
            </Modal>
          )}
        </>
      );
    }
  )
);

SelectVideo.propTypes = {
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  invisible: PropTypes.bool,
  duration: PropTypes.number,
  videoSize: { width: PropTypes.number, height: PropTypes.number },
};

SelectVideo.defaultProps = {
  onComplete: () => {},
  onClose: () => {},
  invisible: false,
  duration: 5,
};

export default SelectVideo;
