import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import Typography from "components/Typography";
import Trimmer from "./Trimmer";

const { Text } = Typography;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const CropperWrapper = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  margin-bottom: 16px;
`;

const CropAndTrimVideo = inject("l10n")(
  observer(
    ({
      video: videoUrl,
      duration,
      videoSize,
      onChange: handleChange,
      l10n,
    }) => {
      const refCropper = useRef();
      const [crop, setCrop] = useState({ x: 0, y: 0 });
      const [zoom, setZoom] = useState(1);
      const [isLoaded, setIsLoaded] = useState(false);
      const [computed, setComputed] = useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      });
      const [startTime, setStartTime] = useState(0);
      const [currentTime, setCurrentTime] = useState(0);
      const [originalDuration, setOriginalDuration] = useState(10);
      const aspect = useMemo(() => {
        return videoSize.width / videoSize.height;
      }, [videoSize]);

      const handleVideoTimeUpdate = useCallback(() => {
        try {
          const video = refCropper?.current?.videoRef;
          if (video) {
            if (
              video.currentTime > startTime + duration ||
              video.currentTime < startTime
            ) {
              video.currentTime = startTime;
            }
            video.play();
            setCurrentTime(video.currentTime);
          }
        } catch (error) {
          console.log(error);
        }
      }, [startTime, duration]);

      const handleCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
          setComputed(croppedAreaPixels);
        },
        []
      );
      const handleMediaLoaded = () => {
        setIsLoaded(true);
      };

      useEffect(() => {
        handleChange({ ...computed, startTime });
      }, [computed, startTime, handleChange]);

      useEffect(() => {
        const video = refCropper.current.videoRef;

        video.ontimeupdate = handleVideoTimeUpdate;
        video.onloadedmetadata = () => {
          // console.log(refCropper.current);
          setOriginalDuration(video.duration);
        };
      }, [startTime, duration, videoUrl, handleVideoTimeUpdate]);

      useEffect(() => {
        setIsLoaded(false);
      }, [videoUrl]);

      return (
        <Wrapper>
          {videoUrl && (
            <>
              <Text
                style={{
                  display: "block",
                  marginBottom: 16,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                {l10n.str("EDITOR_EDIT_VIDEO_CROP")}
              </Text>
              <CropperWrapper>
                {!isLoaded && <div>{l10n.str("LOADING")}</div>}
                <Cropper
                  ref={refCropper}
                  aspect={aspect}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  video={videoUrl}
                  onCropComplete={handleCropComplete}
                  onMediaLoaded={handleMediaLoaded}
                />
              </CropperWrapper>
              <br />
              <br />
              <Text
                style={{
                  display: "block",
                  marginBottom: 16,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                {l10n.str("EDITOR_EDIT_VIDEO_TRIM")}
              </Text>
              <Trimmer
                duration={duration}
                onChange={setStartTime}
                currentTime={currentTime}
                originalDuration={originalDuration}
                value={startTime}
              />
            </>
          )}
        </Wrapper>
      );
    }
  )
);

CropAndTrimVideo.propTypes = {
  video: PropTypes.string,
  duration: PropTypes.number,
  videoSize: { width: PropTypes.number, height: PropTypes.number },
  onChange: PropTypes.func,
};
export default CropAndTrimVideo;
