import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Slider as antdSlider } from "antd";
import theme from "config/theme";

const HEIGHT = 36;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const Slider = styled(antdSlider)`
  width: 100%;
  height: auto;
  position: relative;
  &.ant-slider {
    height: ${HEIGHT + "px"};
    padding: 0;
    margin: 0;
  }

  & .ant-slider-rail {
    height: ${HEIGHT + "px"};
    /* background-image: ${(props) => {
      return `url(${props.waveform})`;
    }}; */
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  & .ant-slider-handle {
    width: ${(props) => {
      return `${(props.duration / props.max) * 100}%`;
    }};
    height: ${HEIGHT + 10 + "px"};
    border-radius: 4px;
    background-color: ${theme.primary}80;
    border-width: 5px;
    border-color: ${theme.primary}80 !important;
    /* opacity: 0.8; */
    z-index: 50;
  }

  & .ant-slider-track {
    height: 100%;
    display: none;
  }
`;

const Time = styled.div`
  height: ${HEIGHT + "px"};
  position: absolute;
  top: 50%;
  /* margin-left: 4px; */
  transform: translateY(-50%);
  display: inline-block;
  background-color: ${theme.primary}cc;
  z-index: 30;
  transition: width 0.2s linear;
`;

const Trimmer = ({
  // onChange: handleTrim,
  value,
  duration,
  originalDuration,
  style,
  currentTime,
  onChange: setValue,
}) => {
  // const [isDone, setIsDone] = useState(false);

  const handleSliderChange = useCallback(
    (newValue) => {
      if (newValue - duration / 2 <= 0) {
        setValue(0);
      } else if (newValue + duration / 2 >= originalDuration) {
        setValue(originalDuration - duration);
      } else {
        setValue(newValue - duration / 2);
      }
    },
    [duration, originalDuration, setValue]
  );

  useEffect(() => {
    // setValue(duration / 2);
    setValue(0);
  }, [duration, setValue]);

  return (
    <Wrapper style={style}>
      <Slider
        currentTime={currentTime}
        // value={value}
        value={value + duration / 2}
        duration={duration}
        onChange={handleSliderChange}
        min={0}
        max={originalDuration}
        step={0.1}
        tooltipPlacement="topLeft"
        tipFormatter={(v) => {
          return `${parseFloat(v - duration / 2).toFixed(1)}s ~ 
          ${parseFloat(v + duration / 2).toFixed(1)}s`;
        }}
      />
      <Time
        style={{
          width: `${((currentTime - value) / originalDuration) * 100}%`,
          left: `${(value / originalDuration) * 100}%`,
        }}
      />
    </Wrapper>
  );
};

Trimmer.propTypes = {
  // onChange: PropTypes.func,
  duration: PropTypes.number,
  originalDuration: PropTypes.number,
  currentTime: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

Trimmer.defaultProps = {
  // onChange: () => {},
};

export default Trimmer;
