import ResponsiveMode from "./ResponsiveMode";

const breakpoints = [
  {
    code: ResponsiveMode.xxSmall,
    width: 360,
  },
  {
    code: ResponsiveMode.xSmall,
    width: 576,
  },
  {
    code: ResponsiveMode.small,
    width: 768,
  },
  {
    code: ResponsiveMode.medium,
    width: 992,
  },
  {
    code: ResponsiveMode.large,
    width: 1200,
  },
  {
    code: ResponsiveMode.xLarge,
    width: 999999,
  },
];

export default breakpoints;