import { action, observable, set } from "mobx";
import autoSave from "utils/storeAutoSave";

export class RecentStore {
  @observable projects = [];

  @action pushProject = (data = { subtemplateId: "", userId: "" }) => {
    this.projects.splice(this.projects.indexOf(data));
    this.projects.push(data);
  };

  @action getRecentProjects = (userId) => {
    return this.projects
      .filter((project) => {
        return project.userId === userId;
      })
      .reverse()
      .slice(0, 5)
      .map((project) => {
        return project.subtemplateId;
      });
  };

  constructor() {
    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    if (localStorage.getItem("recent.projects") !== null) {
      const data = localStorage.getItem("recent.projects");
      set(this, JSON.parse(data));
      console.log(this.projects);
    }
  }

  save(json) {
    localStorage.setItem("recent.projects", json);
  }
}

export default RecentStore;
