import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Slider as antdSlider } from "antd";
import theme from "config/theme";
import axios from "axios";
import WaveformGenerator from "utils/waveform-generator-web";
import { useAsyncMemo } from "use-async-memo";

const HEIGHT = 128;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const Slider = styled(antdSlider)`
  width: 100%;
  height: auto;
  position: relative;
  &.ant-slider {
    height: ${HEIGHT + "px"};
    padding: 0;
    margin: 0;
  }

  & .ant-slider-rail {
    height: ${HEIGHT + "px"};
    background-image: ${(props) => {
      return `url(${props.waveform})`;
    }};
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  & .ant-slider-handle {
    width: ${(props) => {
      return `${(props.duration / props.max) * 100}%`;
    }};
    height: ${HEIGHT + 10 + "px"};
    border-radius: 4px;
    background-color: ${theme.primary}80;
    border-width: 5px;
    border-color: ${theme.primary}80 !important;
    z-index: 50;
  }

  & .ant-slider-track {
    height: 100%;
    display: none;
  }
`;

const Time = styled.div`
  height: ${HEIGHT + "px"};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  background-color: ${theme.primary}cc;
  z-index: 30;
  transition: width 0.2s linear;
`;

const generateWaveform = async (audioBuffer) => {
  if (audioBuffer) {
    const waveformGenerator = new WaveformGenerator(audioBuffer);
    const waveformSettings = {
      barWidth: 1,
      barGap: 0,
      waveformColor: theme.black,
      barAlign: "center",
      waveformHeight: HEIGHT,
      waveformWidth: 1920,
      drawMode: "png",
    };
    const waveformResult = await waveformGenerator.getWaveform({
      ...waveformSettings,
    });

    return waveformResult;
  }
  return "";
};

const BgmTrimmer = ({
  url,
  value: valueFromProps,
  onChange: handleChange,
  duration,
  style,
  isPlaying,
}) => {
  const audioBuffer = useAsyncMemo(async () => {
    const result = await axios.get(url, {
      responseType: "arraybuffer",
      crossdomain: true,
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    return result.data;
  }, [url]);
  const [originalDuration, setOriginalDuration] = useState(100);
  const [waveform, setWaveform] = useState(null);
  const [currentTime, setCurrentTime] = useState(0.0);
  const [value, setValue] = useState(valueFromProps + duration / 2);

  const handleSliderAfterChange = useCallback(
    (v) => {
      handleChange(v - duration / 2);
    },
    [duration, handleChange]
  );

  const audioRef = useRef(new Audio());
  const audio = audioRef.current;

  const handleSliderChange = useCallback(
    (newValue) => {
      if (newValue - duration / 2 <= 0) {
        setValue(duration / 2);
        return;
      }
      if (newValue + duration / 2 >= originalDuration) {
        setValue(originalDuration - duration / 2);
        return;
      }
      setValue(newValue);
    },
    [duration, originalDuration]
  );

  const setAudioSource = useCallback(
    async (src) => {
      if (audio) {
        audio.src = src;
        if (isPlaying) {
          if (audio.paused) {
            await audio.play();
          }
        } else {
          audio.pause();
        }
      }
    },
    [audio, isPlaying]
  );

  const handleLoadedMetaData = useCallback(() => {
    setOriginalDuration(audio.duration);
  }, [audio]);

  const handleTimeUpdate = useCallback(() => {
    if (
      audio.currentTime > value + duration / 2 ||
      audio.currentTime < value - duration / 2
    ) {
      audio.currentTime = value - duration / 2;
    }
    setCurrentTime(audio.currentTime);
  }, [audio, duration, value]);

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetaData);
      audio.addEventListener("timeupdate", handleTimeUpdate);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", handleLoadedMetaData);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [audio, handleLoadedMetaData, handleTimeUpdate]);

  useEffect(() => {
    if (audio) {
      audio.currentTime = value - duration / 2;
    }
  }, [value, audio, duration]);

  useEffect(() => {
    setValue(duration / 2);
  }, [duration, setValue]);

  useEffect(() => {
    if (audio && url) {
      setAudioSource(url);
      generateWaveform(audioBuffer).then(setWaveform);
    }
  }, [audio, url, setAudioSource, audioBuffer]);

  useEffect(() => {
    if (audio) {
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isPlaying, audio]);

  return (
    <Wrapper style={style}>
      <Slider
        currentTime={currentTime}
        waveform={waveform}
        value={value}
        duration={duration}
        onChange={handleSliderChange}
        onAfterChange={handleSliderAfterChange}
        min={0}
        max={originalDuration}
        step={0.1}
        tooltipPlacement="topLeft"
        tipFormatter={(v) => {
          return `${parseFloat(v - duration / 2).toFixed(1)}s ~ 
          ${parseFloat(v + duration / 2).toFixed(1)}s`;
        }}
      />
      <Time
        style={{
          width: `${
            ((currentTime - (value - duration / 2)) / originalDuration) * 100
          }%`,
          left: `${((value - duration / 2) / originalDuration) * 100}%`,
        }}
      />
    </Wrapper>
  );
};

BgmTrimmer.propTypes = {
  url: PropTypes.string.isRequired,
  duration: PropTypes.number,
  isPlaying: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

BgmTrimmer.defaultProps = {
  duration: 10,
  isPlaying: false,
  onChange: () => {},
};

export default BgmTrimmer;
