import React, { useState, useEffect, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { message } from "antd";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Modal from "components/Modal";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const PreviewScene = inject(
  "l10n",
  "auth"
)(
  observer(({ l10n, url, invisible, onClose: handleClose }) => {
    const [closable, setClosable] = useState(false);
    const refTimer = useRef();

    useEffect(() => {
      if (!invisible) {
        if (refTimer?.current) {
          clearTimeout(refTimer.current);
        }
        setClosable(false);
        refTimer.current = setTimeout(() => {
          setClosable(true);
        }, 5000);
      }
    }, [invisible]);

    return (
      <Modal
        invisible={invisible}
        onClose={handleClose}
        title={l10n.str("EDITOR_PREVIEW")}
        closable={closable}
        style={{ width: "60vw", minHeight: "40vh" }}
      >
        {url && (
          <Wrapper>
            <div
              style={{
                width: "100%",
                height: "auto",
                position: "absolute",
                textAlign: "center",
                zIndex: 0,
                left: 0,
                top: "45%",
              }}
            >
              <LoadingOutlined />
              <br />
              {l10n.str("LOADING")}
            </div>
            <div
              style={{
                zIndex: 10,
                position: "relative",
                width: "100%",
                height: 0,
                paddingBottom: "56.25%",
              }}
            >
              <img
                src={url}
                onError={() => {
                  message.error(l10n.str("MESSAGE_TO_MANY_REQUEST_PREVIEW"));
                  handleClose();
                }}
                onLoad={() => {
                  setClosable(true);
                }}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 10,
                  objectFit: "contain",
                }}
              />
            </div>
          </Wrapper>
        )}
      </Modal>
    );
  })
);

PreviewScene.propTypes = {
  url: PropTypes.string,
  invisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PreviewScene;
