import React, { useRef, useEffect, useState, useCallback } from "react";
import { Input, Form, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
// import styled from "styled-components";
import theme from "config/theme";

import Typography from "components/Typography";
import Modal from "components/Modal";

import iconWarning from "assets/img/icon-warning.svg";
import iconSuccess from "assets/img/icon-success.svg";

const { Text2, Caption } = Typography;

const ModalChangeText = inject("l10n")(
  observer(
    ({
      invisible,
      onClose: handleModalClose,
      onComplete: handleSubmitText,
      defaultValue,
      textLength,
      l10n,
    }) => {
      const [frmText] = Form.useForm();
      const refInput = useRef();
      const [length, setLength] = useState(0);
      const [help, setHelp] = useState({});

      const handleChange = useCallback(
        (value) => {
          setLength(value.length);

          if (value.length >= 50) {
            setHelp({
              icon: iconWarning,
              text: l10n.str("EDITOR_TEXT_HELPS")[2],
            });
            return;
          }
          if (value.length > textLength) {
            setHelp({
              icon: iconWarning,
              text: l10n.str("EDITOR_TEXT_HELPS")[1],
            });
            return;
          }
          if (value.length > 0 && value.length <= textLength) {
            setHelp({
              icon: iconSuccess,
              text: l10n.str("EDITOR_TEXT_HELPS")[0],
            });
            return;
          }
          setHelp({});
        },
        [l10n, textLength]
      );

      const handleKeyDown = useCallback(
        (event) => {
          const { value } = event.target;
          handleChange(value);
        },
        [handleChange]
      );

      useEffect(() => {
        if (!invisible) {
          const value = defaultValue || "";
          frmText.setFieldsValue({ text: value });
          handleChange(value);
          refInput.current.focus();
        } else {
          setTimeout(() => {
            frmText.resetFields();
          }, 300);
        }
      }, [invisible, defaultValue, frmText, handleChange]);

      return (
        <Modal
          invisible={invisible}
          closable
          onClose={handleModalClose}
          style={{ minWidth: 368, maxWidth: "80vw" }}
        >
          <Form form={frmText} onFinish={handleSubmitText}>
            <p>
              <Text2 style={{ fontWeight: 700, color: theme.white }}>
                {l10n.str("PLACEHOLDER_INPUT_TEXT")}
              </Text2>
            </p>
            <p>
              <Form.Item
                name="text"
                help={
                  <Row justify="space-between" align="middle">
                    <Col>
                      {help?.icon && (
                        <img
                          src={help?.icon}
                          style={{
                            width: 16,
                            verticalAlign: "middle",
                            margin: "0 8px",
                          }}
                          alt="info"
                        />
                      )}
                      {help?.text && (
                        <Caption
                          style={{
                            verticalAlign: "middle",
                            color: theme.lightgray,
                          }}
                        >
                          {help?.text}
                        </Caption>
                      )}
                    </Col>
                    <Col>
                      <Caption
                        style={{
                          verticalAlign: "middle",
                          color: theme.lightgray,
                        }}
                      >
                        {length}/{textLength}
                      </Caption>
                    </Col>
                  </Row>
                }
              >
                <Input
                  className="dark"
                  size="small"
                  ref={refInput}
                  maxLength={50}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyDown}
                  onKeyPress={handleKeyDown}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                />
              </Form.Item>
            </p>
            <Form.Item>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  size="default"
                  type="gray"
                  htmlType="button"
                  style={{ width: 168 }}
                  onClick={handleModalClose}
                >
                  {l10n.str("BUTTON_CANCEL")}
                </Button>
                &nbsp;
                <Button
                  size="default"
                  type="primary"
                  htmlType="submit"
                  style={{ width: 168, marginRight: 0 }}
                >
                  {l10n.str("BUTTON_SAVE")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  )
);

ModalChangeText.propTypes = {
  invisible: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  defaultValue: PropTypes.string,
  textLength: PropTypes.number,
};

export default ModalChangeText;
