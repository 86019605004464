import styled from "styled-components";
// import theme from "config/theme";

const Headline = styled.span`
  font-size: 64px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;

  .mobile & {
    font-size: 24px;
  }
`;

export default Headline;
