import comma from "comma-number";
import moment from "moment";

const l10n = {
  ko: {
    MESSAGE_PROJECT_NOT_FOUND: "프로젝트를 찾을 수 없습니다.",
    MESSAGE_DESKTOP_ONLY:
      "디자인 편집은 PC 또는 안드로이드 APP 에서 가능합니다.",
    BUTTON_WATERMARK: "영상초안 만들기",
    MESSAGE_ABOUT_WATERMARK: "완료된 영상에 브랜드 워터마크를 적용합니다.",
    MESSAGE_ABOUT_PREVIEW: "변경한 요소들을 미리 볼 수 있습니다",
    MESSAGE_ABOUT_CHANGE_COLOR: "색상을 변경할 수 있습니다",
    MESSAGE_ABOUT_CHANGE_BGM: "배경음을 변경할 수 있습니다",
    BRAND_NAME: "브랜드 명",
    INPUT_BRAND_NAME: "브랜드 이름을 입력해주세요",
    BRAND_LOGO: "로고 이미지 파일",
    RADIO_BRAND_LOGO_TRUE: "로고 이미지가 있습니다",
    RADIO_BRAND_LOGO_FALSE: "로고 이미지가 없습니다",
    BUTTON_UPLOAD_BRAND_LOGO: "파일 불러오기",
    MESSAGE_ONLY_PNG: "배경이 없는 png 파일만 가능합니다",
    BRAND_LOGO_CAUTIONS: [
      "완료된 영상에 브랜드 워터마크가 함께 부착됩니다.",
      "워터마크는 반 투명이기 때문에, 영상의 색에 따라 보이지 않을 수도 있습니다.",
      "워터마크의 브랜드 로고는 영상의 중간에 배치됩니다.",
      "배경이 없는 png 파일 이외의 파일을 사용하면 로고가 보이지 않을 수 있습니다.",
      "로고 이미지가 없어도 워터 마크를 만들 수 있습니다.",
      "로고 이미지가 없을 시에는 브랜드명만 사용하여 워터마크가 적용됩니다.",
    ],
    MESSAGE_BRAND_NAME_REQUIRED: "브랜드 명을 입력하지 않았습니다.",
    MESSAGE_BRAND_LOGO_REQUIRED: "로고 파일을 업로드 해주세요.",
    MESSAGE_LOADING_WATERMARK: "브랜드 워터마크를 적용하고 있습니다.",

    MESSAGE_WATERMARK_EXIST: "사용된 워터마크가 있습니다",
    MESSAGE_WATERMARK_EXIST_DESCRIPTION:
      "변경하시려면 '워터마크 수정하기' 버튼을,\n그대로 적용을 원하시면 '영상 초안 적용하기' 버튼을 눌러주세요",
    BUTTON_MODIFY_WATERMARK: "워터마크 수정하기",
    BUTTON_USE_WATERMARK: "영상 초안 적용하기",
    BUTTON_RETRY: "다시 시도",
    PLACEHOLDER_INPUT_VERIFY_CODE: "인증번호를 입력해주세요",
    CONFIRM_CANCEL_MEMBERSHIP: "멤버십을 해지하시겠습니까?",
    CONFIRM_REMOVE_PROJECT: "정말 프로젝트를 삭제하시겠습니까?",
    CONFIRM_REMOVE_PROJECT_SUBTITLE: "삭제된 이후에는 복구할 수 없습니다.",
    MESSAGE_DELETED: "삭제되었습니다.",
    MESSAGE_CONFIRMED: "확정이 완료되었습니다.",
    MESSAGE_GO_TO_EDITOR: "편집실로 이동합니다.",
    MESSAGE_CONFIRM_FAILED: "확정을 실패하였습니다. 다음에 다시 시도해보세요.",
    MESSAGE_COUPON_ADDED: "쿠폰 등록에 성공하였습니다.",
    MESSAGE_COUPON_FAILED: "쿠폰 등록에 실패하였습니다.",
    MESSAGE_APPLY_BGM_SUCCESS: "배경음을 적용하였습니다.",
    MESSAGE_APPLY_BGM_FAILED:
      "배경음 적용을 실패하였습니다. 다시 시도해 보세요.",
    MESSAGE_APPLY_VIDEO_SUCCESS: "비디오를 적용했습니다.",
    MESSAGE_APPLY_VIDEO_FAILED: "비디오 적용을 실패했습니다.",
    MESSAGE_PURCHASE_AGREE_REQUIRED: "필수 항목에 동의해주세요.",
    MESSAGE_PURCHASE_SUCCESS: "결제에 성공하였습니다.",
    MESSAGE_PURCHASE_FAILED: "결제에 실패하였습니다.",
    MESSAGE_PURCHASE_COUPON_ONLY: "현재는 쿠폰을 이용한 결제만 가능합니다.",
    MESSAGE_EDIT_USER_INFO_SUCCESS: "회원 정보 수정을 완료하였습니다.",
    MESSAGE_EDIT_USER_INFO_FAILED: "회원 정보 수정을 실패하였습니다.",
    MESSAGE_EMAIL_REQUIRED: "이메일을 입력해주세요.",
    MESSAGE_USER_NAME_REQUIRED: "이름을 입력해주세요.",
    MESSAGE_CONTENT_REQUIRED: "내용을 입력해주세요.",
    MESSAGE_PHONE_REQUIRED: "휴대폰 번호를 입력해주세요.",
    MESSAGE_VERIFY_CODE_SENDING:
      "입력하신 휴대폰 번호로 인증번호를 발송하고 있습니다.",
    MESSAGE_VERIFY_CODE_SENDED: "인증번호가 발송되었습니다.",
    MESSAGE_VERIFY_CODE_FAILED_TO_SEND:
      "인증번호 전송을 실패하였습니다. 휴대폰 번호를 확인해주세요.",
    MESSAGE_PASSWORD_REQUIRED: "비밀번호를 입력해주세요.",
    MESSAGE_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS:
      "비밀번호를 6글자 이상 입력해주세요.",
    MESSAGE_EMAIL_NOT_VALIDATED: "이메일을 정확히 입력해주세요.",
    MESSAGE_TO_MANY_REQUEST_PREVIEW:
      "미리보기 요청을 너무 많이 하였습니다. 잠시 뒤에 다시 시도해주세요.",
    MESSAGE_PASSWORDS_DO_NOT_MATCH:
      "입력하신 비밀번호가 서로 일치하지 않습니다.",
    MESSAGE_NEED_TO_VERIFY_PHONE: "휴대폰 인증을 진행해주세요.",
    MESSAGE_ALREADY_REGISTERED_PHONE_NUMBER:
      "해당 휴대폰 번호는 이미 가입되어 있습니다.",
    MESSAGE_VERIFY_PHONE_FAILED: "휴대폰 인증에 실패하였습니다.",
    MESSAGE_SIGNUP_FAILED: "회원가입에 실패하였습니다.",
    MESSAGE_FIND_EMAIL_FAILED: "입력하신 정보와 일치하는 회원 정보가 없습니다.",
    MESSAGE_SESSION_EXPIRED:
      "로그인 세션이 만료되었습니다. 다시 로그인해주세요.",
    MESSAGE_NOT_SUPPORTED: "아직 지원되지 않습니다.",
    PLACEHOLDER_INPUT_COUPON: "쿠폰 번호를 입력해주세요.",
    PLACEHOLDER_INPUT_TEXT: "텍스트를 입력해주세요.",
    PLACEHOLDER_INPUT_EMAIL: "이메일을 입력해주세요.",
    PLACEHOLDER_INPUT_CONTENT: "내용을 입력해주세요.",
    PLACEHOLDER_INPUT_PASSWORD: "비밀번호를 입력해주세요.",
    PLACEHOLDER_INPUT_USER_NAME: "이름을 입력해주세요.",
    PLACEHOLDER_NO_COUPON_SELECTED: "선택된 쿠폰이 없습니다.",
    CANNOT_CONFIRM: "프로젝트가 확정되어 더 이상 수정할 수 없습니다.",
    LIKES_DELETED: "찜 목록에서 삭제하였습니다.",
    LIKES_ADDED: "찜 목록에 추가했습니다.",
    OPTION_NOT_SELECTED: "선택 안 함",
    THUMBNAIL: "썸네일",
    DISCOUNT: "할인",
    COUPON_TYPE: "쿠폰 유형",
    BUTTON_DOWNLOAD_VIDEO: "다운로드",
    BUTTON_DOWNLOAD_VIDEO_WATERMARK: "영상 초안 다운로드",
    PROJECTS_RECENT: "최근",
    PURCHASED_DATE: "결제 일자",
    PURCHASED_INFO: "상품 정보",
    MOVE_TO_STORE: "디자인 스토어로 이동합니다",
    STORE_TITLE: "디자인 스토어",
    CONNECT_SOCIAL_MEDIA: "소셜 미디어 계정 연결",
    CONNECT_SOCIAL_MEDIA_DESCRIPTION:
      "로그인 하신 소셜 미디어 계정과 연결된 계정이 없습니다.\n회원가입 하거나 기존 계정과 연동할 수 있습니다.",
    OR: "또는",
    NO_SEARCH_RESULT: "검색 결과가 없습니다.",
    PRICE: "금액",
    PRICE_CALCULATED: ({ price }) =>
      `${comma(parseInt((price + "").split(",").join(""), 10))}원`,
    PRICE_FREE: "무료",
    PROFILE_NAME: ({ name }) => `${name}님`,
    ACCOUNT_NO_MEMBERSHIP: "가입한 요금제가 없습니다",
    PURCHASE: "결제하기",
    PURCHASE_DETAILS: "상세정보",
    PURCHASE_METHOD: "결제방법",
    BUTTON_OK: "확인",
    BUTTON_GO_TO_PRICING: "멤버십 가입",
    BUTTON_CHANGE_PLAN: "변경하기",
    BUTTON_GO_BACK: "이전 페이지로",
    BUTTON_GO_TO_PROJECTS: "프로젝트 목록으로 이동",
    BUTTON_GO_TO_MAIN: "메인으로 이동",
    BUTTON_CHANGE_BGM: "변경하기",
    BUTTON_APPLY: "적용하기",
    BUTTON_RESET: "원래대로",
    BUTTON_COMPLETE: "완료",
    BUTTON_CANCEL: "취소",
    BUTTON_SAVE: "저장",
    BUTTON_PURCHASE: "결제하기",
    BUTTON_CONTACT: "문의하기",
    BUTTON_MODIFY: "수정하기",
    BUTTON_MODIFY_PROJECT: "수정하기",
    BUTTON_CONFIRM_PROJECT: "확정하기",
    BUTTON_SIGNIN: "로그인",
    BUTTON_SIGNUP: "회원가입",
    BUTTON_FIND_EMAIL: "이메일 찾기",
    BUTTON_RECOVERY: "비밀번호 찾기",
    BUTTON_SIGNIN_WITH_FACEBOOK: "페이스북 계정으로 로그인",
    BUTTON_SIGNIN_WITH_GOOGLE: "구글 계정으로 로그인",
    BUTTON_CANCEL_MEMBERSHIP: "해지하기",
    EDITOR_CURRENT_BGM: "현재 배경음",
    EDITOR_BGM_UNTITLED: "제목 없음",
    EDITOR_BGM_DEFAULT_DESCRIPTION:
      "현재 디자인에 적용되어 있는 기본 배경음입니다.",
    EDITOR_BGM_APPLYING: "배경음을 적용하고 있습니다.",
    EDITOR_TEXT_HELPS: [
      "디자인에 적절해요",
      "디자인에 어울리지 않을 수 있어요",
      "디자인에 어울리기 힘들어요",
    ],
    LOADING: "로드 중...",
    EDITOR_IMAGE_FREE_IMAGE: "무료 이미지",
    EDITOR_IMAGE_UPLOAD: "파일 불러오기",
    EDITOR_IMAGE_APPLYING: "이미지를 적용하고 있습니다.",
    PLACEHOLDER_SEARCH_IMAGE: "원하는 이미지를 검색해보세요",
    PLACEHOLDER_SEARCH_VIDEO: "원하는 비디오를 검색해보세요",
    EDITOR_EDIT_IMAGE: "이미지 편집하기",
    EDITOR_EDIT_IMAGE_DESCRIPTION:
      "선택한 이미지를 필요한 부분만큼 자르거나 좌우/상하반전 또는 회전 시킬 수 있습니다.",
    EDITOR_EDIT_IMAGE_FLIP_HORIZONTAL: "좌우반전",
    EDITOR_EDIT_IMAGE_FLIP_VERTICAL: "상하반전",
    EDITOR_EDIT_IMAGE_ROTATE: "회전",

    EDITOR_VIDEO_FREE_VIDEO: "무료 비디오",
    EDITOR_VIDEO_UPLOAD: "파일 불러오기",
    EDITOR_EDIT_VIDEO: "비디오 편집",
    EDITOR_EDIT_VIDEO_CROP: "비디오 영역 설정",
    EDITOR_EDIT_VIDEO_TRIM: "비디오 삽입 구간 설정",
    EDITOR_VIDEO_APPLYING: "비디오를 변환하고 있습니다.",

    CANNOT_CHANGE_BGM: "디자인 색상을 변경할 수 없는 프로젝트군요.",
    PROJECTS_COMPLETED_NONE: "완료한 프로젝트가 없습니다.",
    GREETINGS: ({ name }) => `${name}님, 안녕하세요.`,
    MESSAGE_SIGNIN_FAILED: "로그인에 실패하였습니다.",
    RATIO: "비율",
    RATIO_LIST: ["전체", "1:1", "9:16", "16:9", "4:5"],
    CATEGORY: "카테고리",
    CATEGORY_LIST: [
      "전체",
      "카페 & 레스토랑",
      "뷰티 & 패션",
      "기업(IT)",
      "예술",
      "여행",
      "개인",
      "액티비티",
      "이벤트",
      "이커머스",
      "스토리텔링",
      "타이포",
      "상품 소개",
      "포스터",
      "기타",
    ],
    STYLE: "스타일",
    STYLE_LIST: [
      "전체",
      "따뜻한",
      "차가운",
      "감성적인",
      "차분한",
      "화려한",
      "모던심플",
      "실험적인",
      "입체적인",
    ],
    PROJECT_STATUS: ["제작 중", "적용 대기", "적용 중", "적용 완료", "확정됨"],

    // MAIN UI
    NAVIGATION_HOME: "메인",
    NAVIGATION_STORE: "디자인 스토어",
    NAVIGATION_PRICING: "요금제",
    NAVIGATION_EDITOR: "편집실",
    NAVIGATION_PROJECTS: "나의 프로젝트",
    NAVIGATION_LOGIN: "로그인",
    CARD1_CATCHPHRASE: "가장 쉬운 광고영상\n브이플레이트",
    CARD1_BUTTON: "디자인 둘러보기",
    CARD2_HOWTO1: "고르고",
    CARD2_HOWTO2: "넣으면",
    CARD2_HOWTO3: "끝",
    CARD2_DESIGN: "디자인 갯수",
    CARD2_PROJECT: "프로젝트 갯수",
    CARD2_BUTTON: "디자인 둘러보기",
    CARD3_MESSAGE: "나에게 필요한 \n모든 종류의 광고 디자인",
    CARD3_CATEGORY1: "카페 & 레스토랑",
    CARD3_CATEGORY2: "뷰티 & 패션",
    CARD3_CATEGORY3: "기업(IT)",
    CARD3_CATEGORY4: "예술",
    CARD3_CATEGORY5: "여행",
    CARD3_CATEGORY6: "개인",
    CARD3_CATEGORY7: "액티비티",
    CARD3_CATEGORY8: "이벤트",
    CARD4_TITLE: ({ month, week }) => {
      return `${month}월 ${week}주 NEW`;
    },
    CARD4_BUTTON: "디자인 더 보기",
    CARD5_MESSAGE: ({ number }) =>
      `이미 ${number}개의 영상이\n브이플레이트로 제작되었습니다.`,
    CARD5_BUTTON: "디자인 더 보기",
    CARD6_MESSAGE: "모바일 앱으로도\n영상을 제작해보세요",
    FOOTER_CONTACT: "제휴 문의",
    FOOTER_COMPANY: "회사 소개",
    FOOTER_POLICY: "이용 약관",
    FOOTER_PRIVACY: "개인정보처리방침",
    FOOTER_PAYMENT: "결제대행서비스 이용약관",
    FOOTER_CORPORATION: "(주)더브이플래닛",
    FOOTER_DETAILS:
      "서울특별시 마포구 월드컵로 120, 4층 | 대표: 이준호\n사업자등록번호: 185-88-01064 | 통신자사업번호: 2018-서울마포-0384",
    // DESIGN STORE UI
    TITLE: "디자인 스토어",
    FILTER_RATIO: "비율",
    ALL: "전체",
    RATIO2: "1:1",
    RATIO3: "9:16",
    RATIO4: "16:9",
    FILTER_CATEGORY: "카테고리",
    CATEGORY1: "전체",
    CATEGORY2: "카페 & 레스토랑",
    CATEGORY3: "뷰티 & 패션",
    CATEGORY4: "기업(IT)",
    CATEGORY5: "예술",
    CATEGORY6: "여행",
    CATEGORY7: "개인",
    CATEGORY8: "액티비티",
    CATEGORY9: "이벤트",
    CATEGORY10: "이커머스",
    CATEGORY11: "스토리텔링",
    CATEGORY12: "타이포",
    CATEGORY13: "상품 소개",
    CATEGORY14: "포스터",
    CATEGORY15: "기타",
    FILTER_STYLE: "스타일",
    STYLE1: "전체",
    STYLE2: "따뜻한",
    STYLE3: "차가운",
    STYLE4: "감성적인",
    STYLE5: "차분한",
    STYLE6: "화려한",
    STYLE7: "모던심플",
    STYLE8: "실험적인",
    STYLE9: "입체적인",
    RESET: "초기화",
    CUSTOM: "커스텀 가능한",
    ONLYIMAGE: "이미지만 있는",
    ONLYVIDEO: "영상만 있는",
    BOTH: "이미지, 영상 모두 있는",
    MOSTPOPULAR: "인기순",
    RECENT: "최신순",
    // SELECT DESIGN
    DETAIL_CATEGORY: "카테고리",
    DETAIL_STYLE: "스타일",
    DETAIL_AUTHOR: "만든이",
    DETAIL_INFO: "설명",
    DETAIL_NOTICE1: "제공하는 BGM의 저작권은 브이플레이트에 있습니다.",
    DETAIL_NOTICE2:
      "브이플레이트로 제작한 모든 영상은 상업적 이용이 가능합니다.",
    DETAIL_NOTICE3: "제작한 영상의 재판매 행위는 모두 금지됩니다.",
    DETAIL_PRICE: "결제 금액",
    DETAIL_BUTTON: "디자인 구매",
    DETAIL_BUTTON_SUBSCRIPTION: "이 디자인 사용",
    DETAIL_SIMILAR: "비슷한 템플릿",
    // PRICING PAGE
    PRICING_TITLE: "요금제",
    PRICING_PAYPERONE_MESSAGE: "1개의 디자인 필요할 때",
    PRICING_PAYPERONE: "건당 결제",
    PRICING_PAYPERONE_INFO: [
      "- 영상 1개 제작 가능",
      "- 플랫폼 별 영상비율 맞춤 제공",
      "- 제작한 영상 7일동안 무제한 다운로드",
      "- 디자인 에디터 제공",
      "- 상업적 이용 가능",
      "- 자체 보유한 이미지/영상 사용가능",
      "- 500만개의 무료 이미지/영상클립 제공",
      "- MP4 파일로 다운로드",
      "- 상업적 이용 가능한 음원 제공",
      "- 채팅 고객 지원",
    ],
    PRICING_PAYPERONE_CATCHPHRASE1: "이 모든게",
    PRICING_PAYPERONE_CATCHPHRASE2: "7,800원 / 디자인 1개",
    // MONTHLY PRICING INFO
    PRICING_SUBSCRIPTION_MESSAGE: "모든 디자인을 사용하고 싶다면",
    PRICING_SUBSCRIPTION_MONTHLY: "월간 요금제",
    PRICING_SUBSCRIPTION_ANNUALLY: "연간 요금제",
    PRICING_SUBSCRIPTION_BASIC: "베이직",
    PRICING_SUBSCRIPTION_STANDARD: "스탠다드",
    PRICING_SUBSCRIPTION_PRO: "프로",

    PRICING_SUBSCRIPTION_BASIC_DESCRIPTION:
      "모든 디자인의 템플릿이 필요한 지금!\n추천하는 베스트 요금제 입니다",
    PRICING_SUBSCRIPTION_STANDARD_DESCRIPTION:
      "팀 기능을 제공해 다양한 기능을\n사용할 수 있는 스탠다드 요금제 입니다",
    PRICING_SUBSCRIPTION_PRO_DESCRIPTION:
      "맞춤형 서비스로 좋은 퀄리티 영상을\n제작할 수 있는 프로 요금제 입니다",
    PRICING_SUBSCRIPTION_BASIC_TOP: "가장 인기 있습니다",
    PRICING_SUBSCRIPTION_BASIC_TARGET: "개인",
    PRICING_SUBSCRIPTION_STANDARD_TARGET: "팀 멤버",
    PRICING_SUBSCRIPTION_MEMBERS_1: "1명",
    PRICING_SUBSCRIPTION_MEMBERS_5: "5명",
    PRICING_SUBSCRIPTION_MEMBERS_10: "10명",
    PRICING_SUBSCRIPTION_MEMBERS_20: "20명",

    PRICING_SUBSCRIPTION_BENEFIT_1_HEADER: "영상 제작 서비스",

    PRICING_SUBSCRIPTION_BENEFIT_1_ITEMS: [
      "500만개의 무료 이미지/영상 클립 제공",
      "자체 보유한 이미지/영상 사용 가능",
      "상업적 이용 가능한 음원 제공",
      "영상 디자인 템플릿 무제한 제작 가능",
      "제작 영상 무제한 다운로드 가능",
      "디자인 에디터 제공",
      "플랫폼 별 영상 맞춤 제공",
      "프리미엄 이미지/영상 클립 제공",
    ],

    PRICING_SUBSCRIPTION_BENEFIT_1_TOOLTIPS: [
      "Pexels, Unsplash, Pixabay 의\n무료 이미지 / 영상을 제공합니다",
      "회원님이 보유하고 있는 \n이미지 / 영상을 사용할 수 있습니다",
      "브이플레이트에서 자체적으로 만든\n음원을 영상 제작 시 무료로 사용 가능합니다",
      "모든 템플릿의 이용이 가능하며\n영상을 무제한으로 제작할 수 있습니다.",
      "만든 프로젝트를 확정하면 정기결제 기간동안\n무제한으로 다운로드할 수 있습니다",
      "영상 제작을 쉽게 할 수 있는\n디자인 에디터를 제공합니다",
      "어떤 플랫폼에도 사용할 수 있는\n1:1 / 4:5 / 9:16 / 16:9 을 제공하고 있습니다",
      "Getty images 등 유료 서비스를 이용하여 \n이미지 / 영상을 제공합니다",
    ],

    PRICING_SUBSCRIPTION_BENEFIT_2_HEADER: "고객 지원 서비스",

    PRICING_SUBSCRIPTION_BENEFIT_2_ITEMS: [
      "상업적 이용 가능",
      "재판매 라이센스 부여",
      "브랜드 워터마크 기능",
      "맞춤형 플랫폼 연동",
      "맞춤형 디자인 제공",
      "Video API 제공",
      "팀 멤버",
      "채팅 지원",
    ],

    PRICING_SUBSCRIPTION_BENEFIT_2_TOOLTIPS: [
      "만든 프로젝트 영상은\n상업적으로 이용 가능합니다",
      "만든 프로젝트 영상은\n재판매가 가능합니다",
      "브랜드 워터마크를 입힌 영상을\n다운로드하고 공유할 수 있습니다",
      "비즈니스에 맞춰서\n맞춤형 플랫폼을 제공해드립니다",
      "브랜드에 최적화된\n비디오 디자인을 제작해드립니다",
      "비즈니스에서 사용할 수 있는\nVideo API를 제공해드립니다.",
      "팀 멤버들과 함께\n제작하고 공유할 수 있습니다",
      "브이플레이트 상담사가\n직접 문제를 해결해드립니다",
    ],

    PRICING_SUBSCRIPTION_BASIC_MAX_MEMBERS: "1명",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS: "5~20명",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS_CAPTION:
      "(팀 인원에 따라 가격이 다릅니다)",
    PRICING_SUBSCRIPTION_PRO_MAX_MEMBERS: "무제한",
    PRICING_SUBSCRIPTION_BASIC_SUPPORT_SERVICE: "고객 지원",
    PRICING_SUBSCRIPTION_STANDARD_SUPPORT_SERVICE: "우선 지원",
    PRICING_SUBSCRIPTION_PRO_SUPPORT_SERVICE: "전담 고객 지원",

    PRICING_FAQ: "요금제에 대한 설명이 필요하신가요?",

    PRICING_BASIC_PRICE: "45,000원 / 1개월",
    PRICING_PERIOD_MONTHLY: "매월",
    PRICING_PERIOD_ANNUALLY: "매년",
    PER_MONTH: "/ 매월",
    PER_YEAR: "/ 매년",
    PRICING_BASIC_INFO1: [
      "영상 무제한 제작",
      "영상 무제한 다운로드",
      "상업적 이용가능",
      "최대 1명의 팀 멤버",
      "채팅 고객 지원",
    ],
    PRICING_BASIC_INFO2: [
      "- 500만개의 무료 이미지/영상클립 제공",
      "- 상업적 이용 가능한 음원 제공",
      "- 프로젝트 무제한 제작 가능",
      "- 제작 영상 무제한 다운로드 가능",
      "- 플랫폼 별 영상비율 맞춤 제공",
      "- 디자인 에디터 제공",
      "- 자체 보유한 이미지/영상 사용가능",
      "- MP4 파일로 다운로드",
      "- 상업적 이용가능",
      "　",
      "해당 플랜에서는 제작하신 영상의 재판매가 금지됩니다.",
    ],
    PRICING_STANDARD_PRICE: "192,000원 / 1개월",
    PRICING_STANDARD_INFO1: [
      "프리미엄 이미지/영상클립 제공",
      "재판매 라이센스 부여",
      "브랜드 워터마크 기능",
      "우선 고객 지원",
    ],
    PRICING_STANDARD_INFO2: [
      "- 500만개의 무료 이미지/영상클립 제공",
      "- 상업적 이용 가능한 음원 제공",
      "- 프로젝트 무제한 제작 가능",
      "- 제작 영상 무제한 다운로드 가능",
      "- 플랫폼 별 영상비율 맞춤 제공",
      "- 디자인 에디터 제공",
      "- 자체 보유한 이미지/영상 사용가능",
      "- MP4 파일로 다운로드",
      "- 상업적 이용가능",
      "- 프리미엄 이미지/영상클립 제공 (작업 중)",
      "- 최대 5명의 팀 멤버 이용가능",
      "- 우선 고객 지원",
      "- 재판매 라이센스 부여",
      "- 브랜드 워터마크 기능 제공 (작업 중)",
    ],
    PRICING_STANDARD_OPTIONS: [
      "최대 5명 팀 멤버",
      "최대 10명 팀 멤버",
      "최대 20명 팀 멤버",
    ],
    PRICING_PRO_PRICE: "상담 후 안내",
    PRICING_PRO_INFO1: [
      "무제한 팀 멤버",
      "맞춤형 디자인 제공",
      "맞춤형 플랫폼 연동",
      "Video API 제공",
      "전담 고객 지원",
    ],
    PRICING_PRO_INFO2: [
      "- 500만개의 무료 이미지/영상클립 제공",
      "- 상업적 이용 가능한 음원 제공",
      "- 프로젝트 무제한 제작 가능",
      "- 제작 영상 무제한 다운로드 가능",
      "- 플랫폼 별 영상 비율 맞춤 제공",
      "- 디자인 에디터 제공",
      "- 자체 보유한 이미지/영상 사용가능",
      "- MP4 파일로 다운로드",
      "- 상업적 이용가능",
      "- 프리미엄 이미지/영상클립 제공 (작업 중)",
      "- 무제한 팀 멤버 이용가능",
      "- 전담 고객 지원",
      "- 재판매 라이센스 부여",
      "- 브랜드 워터마크 기능 제공(작업 중)",
      "- 맞춤형 디자인 제공",
      "- 비즈니스 맞춤 플랫폼 연동 제공",
      "- Video API 제공",
    ],
    PRICING_BUTTON: "결제하기",
    PRICING_BUTTON_DISABLED: "현재 이용 중",
    // YEARLY PRICING INFO
    PRICING_BASIC_SALE: "20% 세일",
    PRICING_BASIC_PRICE_DISCOUNTED: "36,000원 / 1개월",
    PRICING_BASIC_PRICE_ANNUALLY: "432,000원 / 매년",
    PRICING_BASIC_TIP: "432,000원 / 매년 청구됩니다",
    PRICING_STANDARD_SALE: "30% 세일",
    PRICING_STANDARD_PRICE_DISCOUNTED: "135,000원 / 1개월",
    PRICING_STANDARD_PRICE_ANNUALLY: "1,620,000원 / 매년",
    PRICING_STANDARD_TIP: "1,620,000원 / 매년 청구됩니다",
    // PAYMENT PAGE
    PAYMENT_DETAIL: "상세정보",
    PAYMENT_TYPE: "결제 유형",
    PAYMENT_PAYPERONE: "건당 결제",
    PAYMENT_MONTHLY: "월간 요금제",
    PAYMENT_ANNUALLY: "연간 요금제",
    PAYMENT_PRICE: "결제 금액",
    PAYMENT_METHOD: "결제 방법",
    PAYMENT_METHOD_CARD: "카드 결제",
    PAYMENT_METHOD_CARD_REBILL: "카드 결제",
    PAYMENT_METHOD_PHONE: "휴대폰 결제",
    PAYMENT_METHOD_KAKAO_PAY: "카카오 페이",
    PAYMENT_POLICY:
      "[필수] 주문하실 상품 및 결제, 주문정보를 확인하였으며 이에 동의합니다.",
    PAYMENT_COUPON: "쿠폰",
    PAYMENT_PLACEHOLDER: "쿠폰번호를 입력해주세요",
    PAYMENT_COUPON_BUTTON: "쿠폰 입력",
    PAYMENT_COUPON_LIST_PLACEHOLDER_DEFAULT: "사용 가능한 쿠폰이 없습니다",
    PAYMENT_COUPON_LIST_PLACEHOLDER: ({ number }) =>
      `사용 가능한 쿠폰이 ${number}개 있습니다`,
    PAYMENT_APPLY_COUPON: "적용 할인",
    PAYMENT_FINAL_PRICE: "최종 금액",
    // PROJECTS
    PROJECTS_TITLE: ({ name }) => `${name}님,\n나의 프로젝트`,
    PROJECTS_CATEGORY1: "전체보기",
    PROJECTS_CATEGORY2: "제작 중",
    PROJECTS_CATEGORY3: "완료됨",
    PROJECTS_INPROGRESS_SUBTITLE: "제작 중인 프로젝트를 확인할 수 있습니다",
    PROJECTS_INPROGRESS_INFO: "프로젝트 정보",
    PROJECTS_INPROGRESS_STATUS: "현재 상태",
    PROJECTS_INPROGRESS_NONE: "제작 중인 프로젝트가 없습니다.",
    PROJECTS_INPROGRESS_NONE_BUTTON: "디자인 스토어로 이동",
    PROJECTS_INPROGRESS_MORE: "더보기",
    PROJECTS_INPROGRESS_CONTINUE: "계속 제작하기",
    PROJECTS_COMPLETE_SUBTITLE:
      "적용 중, 적용 완료, 확정된 프로젝트를 확인할 수 있습니다",
    PROJECTS_COMPLETE_NOTICE1:
      "프로젝트 제작 완료 영상은 최초 생성일 이후 7일동안 수정이 가능하고 기간 이후에는 자동 확정됩니다",
    PROJECTS_COMPLETE_NOTICE2:
      "확정 영상은 확정일 이후 30일동안 다운로드 가능하고 기간 이후에는 다운로드가 불가능합니다",
    PROJECTS_COMPLETE_NOTICE3: "확정 영상은 추가적인 수정이 불가능합니다",
    PROJECTS_COMPLETE_NOTICE4:
      "프로젝트 제작 완료 후 확정 영상만 다운로드 및 상업적 이용이 가능합니다",
    PROJECTS_COMPLETE_VIEW: "완료영상 확인하기",
    PROJECTS_COMPLETE_STATUS_ITEM1: "적용 중",
    PROJECTS_COMPLETE_STATUS_ITEM2: "완료",
    PROJECTS_COMPLETE_STATUS_ITEM3: "확정",
    SELECT_COMPLETE_TITLE: "완료영상 확인하기",
    SELECT_EXPIRATION_NOTICE:
      "이 프로젝트는 30일 이후 기간이 만료되어 확정할 수 없습니다.",
    SELECT_COMPLETE_CONFIRM_NOTICE1: "프로젝트를 확정하시겠습니까?",
    SELECT_COMPLETE_CONFIRM_NOTICE2: "확정된 프로젝트는 수정이 불가능 합니다.",
    SELECT_COMPLETE_CONFIRM_CANCEL: "취소",
    SELECT_COMPLETE_CONFIRM_OK: "예",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE1: "프로젝트가 확정되었습니다.",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE2: "확정된 프로젝트는",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE3:
      "나의 프로젝트에서 확인할 수 있습니다.",
    SELECT_COMPLETE_CONFIRM_COMPLETE_MAIN: "메인으로 가기",
    SELECT_COMPLETE_CONFIRM_COMPLETE_PROJECTS: "나의 프로젝트 가기",
    PROJECTS_COMPLETE_INFO: "프로젝트 정보",
    PROJECTS_COMPLETE_STATUS: "현재 상태",
    PROJECTS_COMPLETE_NONE: "완료한 프로젝트가 없습니다.",
    PROJECTS_COMPLETE_NONE_BUTTON: "디자인 스토어로 이동",
    PROJECTS_COMPLETE_MORE: "더보기",
    // 마이페이지
    ACCOUNT_WELCOME: ({ name }) => `환영합니다,\n${name}`,
    ACCOUNT_NAV: "마이 페이지",
    ACCOUNT_NAV_EDIT_PROFILE: "회원정보 수정",
    ACCOUNT_NAV_EDIT_PASSWORD: "비밀번호 변경",
    ACCOUNT_NAV_COUPONS: "쿠폰",
    ACCOUNT_NAV_LIKES: "찜하기",
    ACCOUNT_NAV_PURCHASED: "결제내역",
    ACCOUNT_NAV_LOGOUT: "로그아웃",
    ACCOUNT_NAV_HELP: "고객센터",
    ACCOUNT_NAV_NOTICES: "공지사항",
    ACCOUNT_NAV_FAQ: "자주묻는질문",
    EDITMYINFO_PRICING: "요금제",
    EDITMYINFO_PRICING_NONE: "결제한 요금제가 없습니다",
    EDITMYINFO_PRICING_NONE_BUTTON: "결제하러 가기",
    EDITMYINFO_PRICING_YES_CHANGE: "변경하기",
    EDITMYINFO_PRICING_YES_METHOD: "결제 방법",
    ACCOUNT_PRICING_NEXT_PAYMENT_DAY: ({ date }) =>
      `다음 결제일은 ${moment(date).format("LL")} 입니다`,
    ACCOUNT_PRICING_EXPIRE_DAY: ({ date }) =>
      `해당 요금제의 만료일은 ${moment(date).format(
        "LL"
      )} 이며, \n더는 결제되지 않습니다.`,
    EDITMYINFO_PRICING_ACCOUNT: "이메일",
    EDITMYINFO_PRICING_PHONE: "휴대폰 번호",
    EDITMYINFO_PRICING_PASSWORD: "현재 비밀번호",
    EDITMYINFO_PRICING_PASSWORD_CHANGE: "변경 비밀번호",
    EDITMYINFO_PRICING_CONFIRM: "변경 비밀번호 확인",
    EDITMYINFO_PRICING_NAME: "이름",
    EDITMYINFO_PRICING_LANGUAGE: "언어 설정",
    EDITMYINFO_PRICING_LANGUAGE_LIST: ["한국어", "영어", "태국어"],
    LANGUAGE_LIST: [
      { key: "ko", value: "한국어" },
      { key: "en", value: "영어(English)" },
      { key: "th", value: "태국어(ภาษาไทย)" },
    ],
    EDITMYINFO_PRICING_EDIT_BUTTON: "수정하기",
    AFTER_EDITMYINFO: "회원정보 수정을 완료했습니다",
    AFTER_EDITMYINFO_BUTTON: "메인으로 가기",
    COUPON_BUTTON: "쿠폰 입력",
    COUPON_NAME: "쿠폰 이름",
    COUPON_PERIOD: "유효 기간",
    COUPON_NONE: "보유한 쿠폰이 없습니다",
    ENTER_COUPON_FAIL: [
      "존재하지 않는 쿠폰번호 입니다!",
      "다시 입력해주세요",
      "확인",
    ],
    LIKE: "찜하기",
    SELECT_DELETE: "선택 삭제",
    ALL_DELETE: "전체 삭제",
    LIKE_INFO: "디자인 정보",
    LIKE_PRICE: "결제금액",
    LIKE_NONE: "찜한 디자인이 없습니다",
    LIKE_VIEW: "디자인 스토어로 이동",
    PAYMENT_HISTORY: "결제 내역",
    PAYMENT_HISTORY_CATEGORY: [
      "결제 목록",
      "정보",
      "결제 방법",
      "결제 날짜",
      "결제 금액",
      "구매 영수증",
    ],
    PAYMENT_HISTORY_NONE: "결제 내역이 존재하지 않습니다",
    PAYMENT_HISTORY_VIEW: "영수증 보기",
    NOTICE: "공지사항",
    FAQ: "F A Q",
    FAQ_LIST: ["결제", "계정", "이용안내"],
    // 편집실
    EDITOR_PROGRESS: ({ progress }) => `작업중 ${progress}%`,
    EDITOR_PROGRESS_DONE: "완료하기",
    EDITOR_BGM: "배경음",
    EDITOR_COLOR: "디자인 색상",
    EDITOR_SELECT_TEXT: [
      "텍스트를 입력해주세요",
      "디자인에 적절해요",
      "디자인에 어울리지 않을 수 있어요",
      "디자인에 어울리기 힘들어요",
      "취소",
      "확인",
    ],
    EDITOR_PREVIEW: "미리보기",
    EDITOR_SAVED: "저장 중...",
    EDITOR_SELECT_IMAGE: ["무료 이미지", "파일 불러오기", "원하는 이미지 찾기"],
    SEARCH_IMAGE_ORIENTATION: "방향",
    SEARCH_IMAGE_ORIENTATION_ALL: "모두",
    SEARCH_IMAGE_ORIENTATION_LANDSCAPE: "가로",
    SEARCH_IMAGE_ORIENTATION_PORTRAIT: "세로",

    EDITOR_IMAGE_EDIT: [
      "이미지 편집하기",
      "선택한 이미지를 필요한 부분만큼 자르거나 좌우/상하반전 또는 회전 시킬 수 있습니다.",
      "좌우반전",
      "상하반전",
      "회전",
      "적용",
    ],
    EDITOR_SELECT_VIDEO: ["무료 영상", "파일 불러오기", "원하는 영상 찾기"],
    EDITOR_VIDEO_EDIT: [
      "영상 편집하기",
      "선택한 영상의 원하는 길이를 자를 수 있습니다",
      "다음",
      "자른 영상의 원하는 영역을 사용할 수 있습니다",
      "적용",
    ],
    EDITOR_BGM_LIST: ["현재 BGM", "변경하기"],
    EDITOR_BGM_UPPER: [
      "배경음악 변경으로 원하는 분위기를 만들어보세요",
      "미리듣기",
      "되돌리기",
      "적용하기",
    ],
    EDITOR_BGM_REQUIRE: "적용될 배경음 길이",
    EDITOR_BGM_EFFECT: "페이드 효과",
    MOODS: "기분",
    EDITOR_BGM_MOODS: [
      "전체",
      "밝은",
      "화난",
      "진지한",
      "어두운",
      "행복한",
      "펑키한",
      "편안한",
      "이상한",
      "낭만적인",
      "몽환적인",
    ],
    GENRES: "장르",
    EDITOR_BGM_GENRES: [
      "전체",
      "팝",
      "힙합",
      "재즈 및 블루스",
      "R&B",
      "클래식",
      "댄스 및 일렉트로닉",
      "컨트리 및 포크",
      "아동",
      "펑크",
      "잔잔한 음악",
    ],
    EDITOR_BGM_PREVIEW: [
      "미리듣기",
      "미리듣기 실행 중 입니다.",
      "잠시만 기다려주세요...",
    ],
    EDITOR_COLOR_LIST: [
      "주 색상 변경하기",
      "보조 색상 변경하기",
      "글자 색상 변경하기",
    ],
    EDITOR_COLOR_CHANGE: ["되돌리기", "적용하기"],
    EDITOR_PREVIEW_RUN: [
      "미리보기",
      "미리보기 실행 중 입니다.",
      "잠시만 기다려주세요...",
    ],
    EDITOR_COMPLETE_NOTICE: "작업한 프로젝트를\n이대로 완료하시겠습니까?",
    EDITOR_COMPLETE_RESULT: [
      "제작한 프로젝트의\n영상 적용을 시작하겠습니다",
      "완료된 영상은 나의 프로젝트에서 확인할 수 있습니다",
      "메인으로 가기",
      "나의 프로젝트 가기",
    ],
    BLANK_PAGE: [
      "열심히 개발 중입니다!",
      "안드로이드 APP을 이용해주세요",
      "안드로이드 APP 다운로드하기",
    ],
    // 로그인 및 회원가입
    LOGIN_PAGE: [
      "이메일을 입력해주세요",
      "비밀번호를 입력해주세요",
      "로그인",
      "회원가입",
      "이메일 찾기",
      "비밀번호 찾기",
      "구글로 로그인/가입하기",
      "페이스북으로 로그인/가입하기",
    ],
    LOGIN_NOTICE: [
      "이메일을 입력해주세요",
      "비밀번호를 입력해주세요",
      "이메일과 비밀번호가 일치하지 않습니다",
    ],
    INPUT_EMAIL: "이메일",
    INPUT_PASSWORD: "비밀번호",
    INPUT_CONFIRM_PASSWORD: "비밀번호 확인",
    INPUT_CURRENT_PASSWORD: "현재 비밀번호",
    INPUT_NEW_PASSWORD: "변경 비밀번호",
    INPUT_CONFIRM_NEW_PASSWORD: "변경 비밀번호 확인",
    INPUT_USER_NAME: "이름",
    INPUT_PHONE: "휴대폰 번호",
    PLACEHOLDER_INPUT_PHONE: "휴대폰 번호를 입력해주세요",
    BUTTON_SEND_VERIFY_CODE: "인증번호 받기",
    SIGNUP: [
      "이메일",
      "이메일을 입력해주세요",
      "비밀번호",
      "비밀번호를 입력해주세요",
      "비밀번호 확인",
      "비밀번호를 다시 입력해주세요",
      "이름",
      "이름을 입력해주세요",
      "휴대폰 번호",
      "휴대폰 번호를 입력해주세요",
      "인증번호 받기",
      "언어 설정",
      "한국어",
      "영어",
      "태국어",
      "[필수] 브이플레이트 서비스 이용약관 동의",
      "[필수] 브이플레이트 서비스 개인정보이용약관 동의",
      "회원가입",
    ],
    SIGNUP_NOTICE: [
      "이메일을 입력해주세요.",
      "올바른 형태의 이메일을 입력해주세요.",
      "영어 소문자/대문자, 숫자를 포함한 8글자 이상을 입력해주세요.",
      "입력한 비밀번호와 다릅니다. 다시 입력해주세요.",
      "이름을 입력해주세요.",
      "인증번호가 전송되었습니다. 잠시만 기다려주세요.",
      "인증번호가 30초 내에 오지 않았다면 다시 인증번호 받기를 눌러주세요.",
      "1일 최대 5회까지만 인증번호 전송이 가능합니다.",
      "서비스 이용약관에 동의해주세요.",
      "개인정보이용야관에 동의해주세요.",
    ],
    SIGNUP_COMPLETE: "회원가입을 완료했습니다",
    FIND_EMAIL: "이메일 찾기",
    FIND_EMAIL_AFTER: [
      "인증번호가 전송되었습니다. 잠시만 기다려주세요.",
      "인증번호가 30초 내에 오지 않았다면 다시 인증번호 받기를 눌러주세요.",
      "1일 최대 5회까지만 인증번호 전송이 가능합니다.",
      "인증번호를 입력해주세요",
      "잘못된 인증번호입니다",
      "확인",
    ],
    SUCCESS_FIND_EMAIL: ["이메일 찾기", "비밀번호 찾기", "로그인 하기"],
    FAIL_FIND_EMAIL: [
      "이메일이 존재하지 않습니다",
      "이메일 다시 찾기",
      "회원가입",
    ],
    RECOVERY: "비밀번호 찾기",
    FIND_PASSWORD: [
      "비밀번호 찾기",
      "이메일",
      "이메일을 입력해주세요",
      "본인 인증",
      "휴대폰 번호를 입력해주세요",
      "인증번호 받기",
      "확인",
    ],
    AFTER_FIND_PASSWORD: [
      "이메일이 존재하지 않습니다",
      "인증번호가 전송되었습니다. 잠시만 기다려주세요.",
      "인증번호가 30초 내에 오지 않았다면 다시 인증번호 받기를 눌러주세요.",
      "1일 최대 5회까지만 인증번호 전송이 가능합니다.",
      "인증번호를 입력해주세요",
      "잘못된 인증번호입니다",
      "확인",
    ],
    SUCCESS_FIND_PASSWORD: [
      "비밀번호 찾기",
      "비밀번호 재설정",
      "비밀번호를 입력해주세요.",
      "비밀번호 재설정 확인",
      "재설정할 비밀번호를 다시 입력해주세요.",
      "확인",
    ],
    NOTICE_FIND_PASSWORD: [
      "영어 소문자/대문자, 숫자를 포함한 8글자 이상을 입력해주세요.",
      "입력한 비밀번호와 다릅니다. 다시 입력해주세요.",
    ],
    // SUCCESS_FIND_PASSWORD: [
    //   "비밀번호 변경을 완료했습니다",
    //   "메인으로 가기",
    //   "로그인 하기",
    // ],
    // 사업제휴 문의
    CONTACT_TEXT:
      "파트너사와 브이플레이트가 함께 성장할 수 있는\n새로운 사업제안들을 기다리고 있습니다.",
    CONTACT_PAGE: [
      "사업제휴 문의",
      "이름",
      "이메일",
      "내용을 입력해주세요",
      "문의하기",
    ],
    CONTACT_PAGE_NOTICE: [
      "이름을 입력해주세요",
      "이메일을 입력해주세요",
      "올바른 형태의 이메일을 입력해주세요",
      "문의 내용을 입력해주세요",
    ],
    CONTACT_SUCCESS_TITLE: "사업제휴 문의를 완료했습니다.",
    CONTACT_SUCCESS_TEXT:
      "문의주셔서 감사합니다.\n해당 부서에서 확인 후 빠른 답변 드리겠습니다",
    SUCCESS_CONTACT: ["메인으로 가기"],
  },
  en: {
    MESSAGE_PROJECT_NOT_FOUND: "The project does not exist.",
    BUTTON_WATERMARK: "Create Video Draft",
    MESSAGE_ABOUT_WATERMARK: "Apply a brand watermark to the video",
    MESSAGE_ABOUT_PREVIEW: "You can preview the changes you have made.",
    MESSAGE_ABOUT_CHANGE_COLOR: "You can change the color.",
    MESSAGE_ABOUT_CHANGE_BGM: "You can change the background music.",
    BRAND_NAME: "Brand Name",
    INPUT_BRAND_NAME: "Please enter the brand name.",
    BRAND_LOGO: "Logo File",
    RADIO_BRAND_LOGO_TRUE: "I have a logo file.",
    RADIO_BRAND_LOGO_FALSE: "I don't have a logo file.",
    BUTTON_UPLOAD_BRAND_LOGO: "Upload File",
    MESSAGE_ONLY_PNG: "Only png files with no background are possible",
    BRAND_LOGO_CAUTIONS: [
      "The brand watermark will be attached to the video.",
      "Since the watermark is semi-transparent, it may not be visible due to the color of the video.",
      "The logo of the watermark will be placed in the middle of the video.",
      "If you use a file other than a png file without a background, the logo may not be visible.",
      "You can create a watermark even without a logo.",
      "If you don't have a logo, the watermark will be made with only the brand name.",
    ],
    MESSAGE_BRAND_NAME_REQUIRED: "You didn't enter the brand name.",
    MESSAGE_BRAND_LOGO_REQUIRED: "Please upload the brand logo.",
    MESSAGE_LOADING_WATERMARK: "Brand watermark is applied.",

    MESSAGE_WATERMARK_EXIST: "There is a watermark you made.",
    MESSAGE_WATERMARK_EXIST_DESCRIPTION:
      "Please click 'Modify' button to change it,\nor click the 'Use it' button to apply it as it is.",
    BUTTON_MODIFY_WATERMARK: "Modify",
    BUTTON_USE_WATERMARK: "Use it",
    BUTTON_RETRY: "Try Again",
    PLACEHOLDER_INPUT_VERIFY_CODE: "Enter the verification code",
    CONFIRM_CANCEL_MEMBERSHIP:
      "Are you sure you want to cancel your membership?",
    CONFIRM_REMOVE_PROJECT: "Are you sure you want to delete the project?",
    CONFIRM_REMOVE_PROJECT_SUBTITLE:
      "After it has been deleted, it cannot be recovered.",
    MESSAGE_DELETED: "Successfully deleted.",
    MESSAGE_EDIT_USER_INFO_SUCCESS: "Complete",
    MESSAGE_EDIT_USER_INFO_FAILED: "Fail to edit your account information",
    MESSAGE_USER_NAME_REQUIRED: "Enter the name.",
    MESSAGE_CONTENT_REQUIRED: "Enter the details of the affiliate offer.",
    MESSAGE_PHONE_REQUIRED: "Enter the phone number.",
    MESSAGE_VERIFY_CODE_SENDING: "We are sending the verification code.",
    MESSAGE_VERIFY_CODE_SENDED: "The verification code is sent.",
    MESSAGE_VERIFY_CODE_FAILED_TO_SEND:
      "Fail to send the verification code. Please check your phone number again.",
    MESSAGE_TO_MANY_REQUEST_PREVIEW:
      "You tried to get preview image too many times. Please try this a few seconds later.",
    MESSAGE_PASSWORDS_DO_NOT_MATCH: "The password you entered do not match.",
    MESSAGE_NEED_TO_VERIFY_PHONE: "Please verify yourself through your mobile.",
    MESSAGE_ALREADY_REGISTERED_PHONE_NUMBER:
      "The phone number you entered is already registered.",
    MESSAGE_VERIFY_PHONE_FAILED: "Fail to verification.",
    MESSAGE_SIGNUP_FAILED: "Fail to Sign Up.",
    MESSAGE_FIND_EMAIL_FAILED:
      "There is no account that matches the information your entered.",
    PLACEHOLDER_INPUT_CONTENT: "Enter the details of the affiliate offer.",
    BUTTON_GO_TO_MAIN: "Go to main page",
    BUTTON_CONTACT: "Contact",
    BUTTON_MODIFY: "OK",
    PROJECTS_COMPLETE_INFO: "Project Information",
    PROJECTS_COMPLETE_STATUS: "Status",
    PROJECTS_COMPLETE_NONE: "There is no project completed.",
    PROJECTS_COMPLETE_NONE_BUTTON: "Go to design store",
    PROJECTS_COMPLETE_MORE: "View more",
    ACCOUNT_NAV_EDIT_PASSWORD: "Change the password",
    INPUT_CURRENT_PASSWORD: "Current password",
    INPUT_NEW_PASSWORD: "New password",
    INPUT_CONFIRM_NEW_PASSWORD: "Password confirm",
    RECOVERY: "Forgot your pasword?",
    MESSAGE_NOT_SUPPORTED: "This function is not currently suported.",
    MESSAGE_CONFIRMED: "Complete to confirm",
    MESSAGE_GO_TO_EDITOR: "Go to Editor",
    MESSAGE_CONFIRM_FAILED: "Fail to confirm. Please contact us.",
    MESSAGE_COUPON_ADDED: "Success to register your coupon.",
    MESSAGE_COUPON_FAILED: "Fail to register your coupon.",
    MESSAGE_APPLY_BGM_SUCCESS: "Success to change the BGM.",
    MESSAGE_APPLY_BGM_FAILED:
      "Fail to change the BGM. Please try it a few moment later.",
    MESSAGE_APPLY_VIDEO_SUCCESS: "Success to apply your video clip.",
    MESSAGE_APPLY_VIDEO_FAILED: "Fail to apply your video clip.",
    MESSAGE_PURCHASE_AGREE_REQUIRED: "Please agree to the required terms.",
    MESSAGE_PURCHASE_SUCCESS: "Success to purchase the design.",
    MESSAGE_PURCHASE_FAILED: "Fail to purchase the design.",
    MESSAGE_PURCHASE_COUPON_ONLY:
      "Currently, Available payment is through the coupon.",
    MESSAGE_EMAIL_REQUIRED: "Enter the email",
    MESSAGE_PASSWORD_REQUIRED: "Enter the password",
    MESSAGE_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS:
      "Password must be at least 6 characters",
    MESSAGE_EMAIL_NOT_VALIDATED: "Please enter the valid format of email",
    PLACEHOLDER_INPUT_COUPON: "Enter the coupon code",
    PLACEHOLDER_INPUT_TEXT: "Enter the text",
    PLACEHOLDER_INPUT_EMAIL: "Enter the email",
    PLACEHOLDER_INPUT_PASSWORD: "Enter the password",
    PLACEHOLDER_INPUT_USER_NAME: "Enter the name",
    PLACEHOLDER_NO_COUPON_SELECTED: "You didn't select any coupon.",
    CANNOT_CONFIRM:
      "As the project has been confirmed, you can't modify it anymore.",
    LIKES_DELETED: "Delete",
    LIKES_ADDED: "Add",
    OPTION_NOT_SELECTED: "Don't select",
    THUMBNAIL: "Thumbnail",
    DISCOUNT: "Discount",
    COUPON_TYPE: "Type",
    BUTTON_DOWNLOAD_VIDEO: "Download",
    BUTTON_DOWNLOAD_VIDEO_WATERMARK: "Download",
    PROJECTS_RECENT: "Recent",
    PURCHASED_DATE: "Date",
    PURCHASED_INFO: "Details",
    MOVE_TO_STORE: "Go to Design Store",
    STORE_TITLE: "Design Store",
    NO_SEARCH_RESULT: "No results were found",
    PRICE: "Price",
    PRICE_CALCULATED: ({ price }) =>
      `$${comma(
        parseFloat(parseFloat((price + "").split(",").join("")).toFixed(2))
      )}`,
    PRICE_FREE: "Free",
    PROFILE_NAME: ({ name }) => `${name}`,
    ACCOUNT_NO_MEMBERSHIP: "There is no subscription plan",
    PURCHASE: "Buy now",
    PURCHASE_DETAILS: "Details",
    PURCHASE_METHOD: "Payment method",
    BUTTON_OK: "OK",
    BUTTON_GO_TO_PRICING: "Buy now",
    BUTTON_CHANGE_PLAN: "Change the plan",
    BUTTON_GO_BACK: "Previous page",
    BUTTON_GO_TO_PROJECTS: "Go to Project list",
    BUTTON_CHANGE_BGM: "Change",
    BUTTON_APPLY: "OK",
    BUTTON_RESET: "Reset",
    BUTTON_COMPLETE: "OK",
    BUTTON_CANCEL: "Cancel",
    BUTTON_SAVE: "Save",
    BUTTON_PURCHASE: "Buy now",
    BUTTON_MODIFY_PROJECT: "Modify",
    BUTTON_CONFIRM_PROJECT: "Confirm",
    BUTTON_SIGNIN: "Log in",
    BUTTON_SIGNUP: "Sign up",
    BUTTON_FIND_EMAIL: "Forgot email?",
    BUTTON_RECOVERY: "Forgot password?",
    BUTTON_SIGNIN_WITH_FACEBOOK: "Continue with Facebook",
    BUTTON_SIGNIN_WITH_GOOGLE: "Continue with Google",
    BUTTON_CANCEL_MEMBERSHIP: "Cancel",
    EDITOR_CURRENT_BGM: "Current BGM",
    EDITOR_BGM_UNTITLED: "None",
    EDITOR_BGM_DEFAULT_DESCRIPTION: "This is the default BGM",
    EDITOR_BGM_APPLYING: "Applying now.",
    EDITOR_TEXT_HELPS: [
      "Suitable for design",
      "It may not suit for design",
      "It's difficult to match the design",
    ],
    LOADING: "Loading...",
    EDITOR_IMAGE_FREE_IMAGE: "Free photo stock",
    EDITOR_IMAGE_UPLOAD: "Upload file",
    EDITOR_IMAGE_APPLYING: "Applying your work.",
    PLACEHOLDER_SEARCH_IMAGE: "Search photos",
    PLACEHOLDER_SEARCH_VIDEO: "Search videos",
    EDITOR_EDIT_IMAGE: "Edit photo",
    EDITOR_EDIT_IMAGE_DESCRIPTION:
      "The selected photo can be cropped as much as the area to be used, flipped horizontally or rotated.",
    EDITOR_EDIT_IMAGE_FLIP_HORIZONTAL: "Lateral Inversion",
    EDITOR_EDIT_IMAGE_FLIP_VERTICAL: "Up-down Inversion",
    EDITOR_EDIT_IMAGE_ROTATE: "Rotate",

    EDITOR_VIDEO_FREE_VIDEO: "Free video stock",
    EDITOR_VIDEO_UPLOAD: "Upload file",
    EDITOR_EDIT_VIDEO: "Edit video",
    EDITOR_EDIT_VIDEO_CROP: "Crop",
    EDITOR_EDIT_VIDEO_TRIM: "Trim",
    EDITOR_VIDEO_APPLYING: "Applying your work",

    CANNOT_CHANGE_BGM: "This is unable to change color.",
    PROJECTS_COMPLETED_NONE: "There is no project completed.",
    GREETINGS: ({ name }) => `Hello, ${name}.`,
    MESSAGE_SIGNIN_FAILED: "Failed to sign in.",
    RATIO: "Ratio",
    RATIO_LIST: ["All", "1:1", "9:16", "16:9", "4:5"],
    CATEGORY: "Category",
    CATEGORY_LIST: [
      "All",
      "Cafe & Restaurant",
      "Beauty & Fashion",
      "Promo",
      "Art",
      "Travel",
      "Personal",
      "Activity",
      "Event",
      "e-commerce",
      "Storytelling",
      "Typo",
      "Product Sales",
      "Poster",
      "etc.",
    ],
    STYLE: "Style",
    STYLE_LIST: [
      "All",
      "Warm",
      "Cool",
      "Sentimental",
      "Calm",
      "Colorful",
      "Modern",
      "Experimental",
      "Stereoscopic",
    ],
    PROJECT_STATUS: [
      "In progress",
      "Ready to render",
      "Rendering",
      "completed",
      "Confirmed",
    ],

    // MAIN UI
    NAVIGATION_HOME: "Home",
    NAVIGATION_STORE: "Design Store",
    NAVIGATION_PRICING: "Pricing",
    NAVIGATION_EDITOR: "Editor",
    NAVIGATION_PROJECTS: "My Project",
    NAVIGATION_LOGIN: "Log In",
    CARD1_CATCHPHRASE: "The Simplest Video Ads\nVPLATE",
    CARD1_BUTTON: "View Design",
    CARD2_HOWTO1: "Just Select",
    CARD2_HOWTO2: "And Enter",
    CARD2_HOWTO3: "It's Done",
    CARD2_DESIGN: "# of Design",
    CARD2_PROJECT: "# of Project Made",
    CARD2_BUTTON: "View Design",
    CARD3_MESSAGE: "All kinds of design \nfor your is here",
    CARD3_CATEGORY1: "Cafe & Restaurant",
    CARD3_CATEGORY2: "Beauty & Fashion",
    CARD3_CATEGORY3: "Promo",
    CARD3_CATEGORY4: "Art",
    CARD3_CATEGORY5: "Travel",
    CARD3_CATEGORY6: "Personal",
    CARD3_CATEGORY7: "Activity",
    CARD3_CATEGORY8: "Event",

    CARD4_TITLE: ({ month, week }) => {
      const monthList = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };
      const weekList = {
        1: "1st",
        2: "2nd",
        3: "3th",
        4: "4th",
        5: "5th",
      };
      return `${monthList[month]} ${weekList[week]} NEW`;
    },
    CARD4_BUTTON: "View More",
    CARD5_MESSAGE: ({ number }) =>
      `${number} videos are already\nmade with VPLATE`,
    CARD5_BUTTON: "View More",
    CARD6_MESSAGE: "Create vidoes\nwith mobile app",
    FOOTER_CONTACT: "Contact",
    FOOTER_COMPANY: "Company",
    FOOTER_POLICY: "Policy",
    FOOTER_PRIVACY: "Privacy",
    FOOTER_PAYMENT: "Terms of Payment",
    FOOTER_CORPORATION: "The Vplanet Inc.",
    FOOTER_DETAILS: "© 2020 VPLATE All rights reserved.",
    // DESIGN STORE UI
    TITLE: "Design Store",
    FILTER_RATIO: "Ratio",
    ALL: "All",
    RATIO2: "Square",
    RATIO3: "Vertical",
    RATIO4: "Horizontal",
    FILTER_CATEGORY: "Category",
    CATEGORY1: "All",
    CATEGORY2: "Cafe & Restaurant",
    CATEGORY3: "Beauty & Fashion",
    CATEGORY4: "Promo",
    CATEGORY5: "Art",
    CATEGORY6: "Travel",
    CATEGORY7: "Personal",
    CATEGORY8: "Activity",
    CATEGORY9: "Event",
    CATEGORY10: "e-commerce",
    CATEGORY11: "Storytelling",
    CATEGORY12: "Typo",
    CATEGORY13: "Product Sales",
    CATEGORY14: "Poster",
    CATEGORY15: "etc.",
    FILTER_STYLE: "Style",
    STYLE1: "All",
    STYLE2: "Warm",
    STYLE3: "Cool",
    STYLE4: "Sentimental",
    STYLE5: "Calm",
    STYLE6: "Colorful",
    STYLE7: "Modern",
    STYLE8: "Experimental",
    STYLE9: "Stereoscopic",
    RESET: "Reset",
    CUSTOM: "Custom Available",
    ONLYIMAGE: "Require ONLY photo",
    ONLYVIDEO: "Require ONLY video",
    BOTH: "Require BOTH",
    MOSTPOPULAR: "Most Popular",
    RECENT: "Most Recent",
    // SELECT DESIGN
    DETAIL_CATEGORY: "Category",
    DETAIL_STYLE: "Style",
    DETAIL_AUTHOR: "Author",
    DETAIL_INFO: "Details",
    DETAIL_NOTICE1: "All copyrights of BGM is reserved by VPLATE.",
    DETAIL_NOTICE2:
      "All videos created with VPLATE is available for commercial use.",
    DETAIL_NOTICE3: "Re-selling of all created videos is prohibited.",
    DETAIL_PRICE: "Price",
    DETAIL_BUTTON: "Buy Now",
    DETAIL_BUTTON_SUBSCRIPTION: "Create with this",
    DETAIL_SIMILAR: "Similar Design",
    // PRICING PAGE
    MESSAGE_DESKTOP_ONLY: "Design editing is possible on PC or Android App.",
    MESSAGE_SESSION_EXPIRED:
      "Your login session has expired. Please sign in again.",
    CONNECT_SOCIAL_MEDIA: "Connect Social Media",
    CONNECT_SOCIAL_MEDIA_DESCRIPTION:
      "There is no account associated with the social media account you are logged in to.\nYou can register as a member or link with an existing account.",
    OR: "Or",
    PRICING_SUBSCRIPTION_BASIC_DESCRIPTION:
      "For business or marketer who need the occasional videos",
    PRICING_SUBSCRIPTION_STANDARD_DESCRIPTION:
      "For growing business and agencies who need\ndaily video marketing and more video contents for business",
    PRICING_SUBSCRIPTION_PRO_DESCRIPTION:
      "For companies which want to build their customized video solutions",
    PRICING_SUBSCRIPTION_BASIC_TOP: "Most Popular NOW!",
    PRICING_SUBSCRIPTION_BASIC_TARGET: "Personal",
    PRICING_SUBSCRIPTION_STANDARD_TARGET: "Team",
    PRICING_SUBSCRIPTION_MEMBERS_1: "1 person",
    PRICING_SUBSCRIPTION_MEMBERS_5: "5 people",
    PRICING_SUBSCRIPTION_MEMBERS_10: "10 people",
    PRICING_SUBSCRIPTION_MEMBERS_20: "20 people",
    PRICING_SUBSCRIPTION_BENEFIT_1_HEADER: "Video Features",
    PRICING_SUBSCRIPTION_BENEFIT_1_ITEMS: [
      "5M+ Free stock",
      "Available with your own images",
      "Royalty Free BGM",
      "Unlimited single video production",
      "Unlimited single video download",
      "Design Editor",
      "Every video scale for each social media platform",
      "Premium stock",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_1_TOOLTIPS: [
      "You can use Free stocks through Pixabay, Unsplash, Pexels",
      "You can use your own images",
      "We provide royalty free BGM which is made by VPLATE",
      "You can use all of templates and\nmake single video unlimitedly",
      "You can download every single video\nuntil subscription period",
      "We provide our smart editor\nto use the video template",
      "We provide each scale of square, horizontal, vertical\nthat can be used on any social media platform",
      "You can use premium stock images\nthrough Getty images",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_2_HEADER: "Support Features",
    PRICING_SUBSCRIPTION_BENEFIT_2_ITEMS: [
      "Commercial use available",
      "Reseller license",
      "Brand watermark",
      "Custom landing page",
      "Custom video design",
      "Video API access",
      "Collaboration",
      "Customer support",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_2_TOOLTIPS: [
      "You can use videos commercially",
      "You can sell the videos\nto third parties",
      "You can export draft videos through\nbrand watermark function",
      "We provide customized landing page \nfit to your busines",
      "We provide new customized video design\nfit to your brand",
      "We provide video API\nwhich can be used in your business",
      "You can make videos with your team",
      "Our managers will provide\nsolutions through chat channel",
    ],
    PRICING_SUBSCRIPTION_BASIC_MAX_MEMBERS: "1 person",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS: "Up to 5~20 team members",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS_CAPTION:
      "(Prices vary depending on the number of team members)",
    PRICING_SUBSCRIPTION_PRO_MAX_MEMBERS: "Unlimited",
    PRICING_SUBSCRIPTION_BASIC_SUPPORT_SERVICE: "Customer support",
    PRICING_SUBSCRIPTION_STANDARD_SUPPORT_SERVICE: "Priority support",
    PRICING_SUBSCRIPTION_PRO_SUPPORT_SERVICE: "Dedicated platform support",
    PRICING_FAQ: "Need more information about pricing?",
    SEARCH_IMAGE_ORIENTATION_ALL: "All",
    PRICING_TITLE: "Pricing",
    PRICING_PAYPERONE_MESSAGE: "If you need 1 design",
    PRICING_PAYPERONE: "Pay per one piece",
    PRICING_PAYPERONE_INFO: [
      "- Able to make 1 video",
      "- Offer various ratio fit for each platform",
      "- Able to download the video in 7days",
      "- Provide Smart Editor",
      "- Available for commercial use",
      "- Able to use your own images",
      "- Provide 5M free images",
      "- Able to download as MP4 file",
      "- Provide our own BGM licensed",
      "- Chat support",
    ],
    PRICING_PAYPERONE_CATCHPHRASE1: "These are available",
    PRICING_PAYPERONE_CATCHPHRASE2: "by just $7",
    // MONTHLY PRICING INFO
    PRICING_SUBSCRIPTION_MESSAGE: "If you need to use all of design",
    PRICING_SUBSCRIPTION_MONTHLY: "Monthly",
    PRICING_SUBSCRIPTION_ANNUALLY: "Annually",
    PRICING_SUBSCRIPTION_BASIC: "Basic",
    PRICING_SUBSCRIPTION_STANDARD: "Standard",
    PRICING_SUBSCRIPTION_PRO: "Pro",
    PRICING_BASIC_PRICE: "$45 / Month",
    PRICING_PERIOD_MONTHLY: "Monthly",
    PRICING_PERIOD_ANNUALLY: "Annually",
    PER_MONTH: "/ Month",
    PER_YEAR: "/ Year",
    PRICING_BASIC_INFO1: [
      "Able to make videos unlimitedly",
      "Able to download videos unlimitedly",
      "Available for commercial use",
      "Max 1 team member",
      "Chat support",
    ],
    PRICING_BASIC_INFO2: [
      "- Provide 5M free images",
      "- Provide our own BGM licensed",
      "- Able to create projects unlimitedly",
      "- Able to download videos unlimitedly",
      "- Offer various ratio fit for each platform",
      "- Provide smart editor",
      "- Able to use your own images",
      "- Able to download as MP4 file",
      "- Available for commercial use",
      "　",
      "The resale of the video you made is prohibited in this plan",
    ],
    PRICING_STANDARD_PRICE: "$190 / Month",
    PRICING_STANDARD_INFO1: [
      "Provide Premium image clips",
      "Provide re-seller license",
      "Provide brand watermark function",
      "Priority support",
    ],
    PRICING_STANDARD_INFO2: [
      "- Provide 5M free images",
      "- Provide our own BGM licensed",
      "- Create your projects unlimitedly",
      "- Able to download videos unlimitedly",
      "- Offer various ratio fit for each platform",
      "- Provide smart editor",
      "- Able to use your own images",
      "- Able to download as MP4 file",
      "- Available for commercial use",
      "- Provide premium image clips (Unready)",
      "- Max 5 team members",
      "- Priority support",
      "- Provide re-seller license",
      "- Provide the brand watermark function (Unready)",
    ],
    PRICING_STANDARD_OPTIONS: [
      "Max 5 team members",
      "Max 10 team members",
      "Max 20 team members",
    ],
    PRICING_PRO_PRICE: "Contact us",
    PRICING_PRO_INFO1: [
      "Unlimited team members",
      "Creative service",
      "Custom integration",
      "Video API access",
      "Dedicated platform support",
    ],
    PRICING_PRO_INFO2: [
      "- Provide 5M free images",
      "- Provide our own BGM licensed",
      "- Create your projects unlimitedly",
      "- Able to download videos unlimitedly",
      "- Offer various ratio fit for each platform",
      "- Provide smart editor",
      "- Able to use your own images",
      "- Able to download as MP4 file",
      "- Available for commercial use",
      "- Provide premium images (Unready)",
      "- Unlimited team members",
      "- Dedicated platform support",
      "- Provide re-seller license",
      "- Provide the brand watermark function (Unready)",
      "- Creative service",
      "- Custom integration",
      "- Video API access",
    ],
    PRICING_BUTTON: "Buy Now",
    PRICING_BUTTON_DISABLED: "This is your plan",
    // YEARLY PRICING INFO
    PRICING_BASIC_SALE: "20% off",
    PRICING_BASIC_PRICE_DISCOUNTED: "$35 / Month",
    PRICING_BASIC_PRICE_ANNUALLY: "$432 / Annually",
    PRICING_BASIC_TIP: "$432 / Billed annually",
    PRICING_STANDARD_SALE: "30% off",
    PRICING_STANDARD_PRICE_DISCOUNTED: "$135 / 1M",
    PRICING_STANDARD_PRICE_ANNUALLY: "$1,620 / Annually",
    PRICING_STANDARD_TIP: "$1,620 / Billed annually",
    // PAYMENT PAGE
    PAYMENT_DETAIL: "Details",
    PAYMENT_TYPE: "Type",
    PAYMENT_PAYPERONE: "Pay per one",
    PAYMENT_MONTHLY: "Monthly pricing",
    PAYMENT_ANNUALLY: "Annually pricing",
    PAYMENT_PRICE: "Price",
    PAYMENT_METHOD: "Payment method",
    // 한글 아니면 일단 준비중이라는 표현 필요
    PAYMENT_METHOD_CARD: "Card",
    PAYMENT_METHOD_CARD_REBILL: "Card",
    PAYMENT_METHOD_PHONE: "Phone",
    PAYMENT_METHOD_KAKAO_PAY: "Kakaopay",
    PAYMENT_POLICY:
      "I have confirmed the product, payment, and order details to be ordered and agree to it.",
    PAYMENT_COUPON: "Coupon",
    PAYMENT_PLACEHOLDER: "Please enter the coupon code.",
    PAYMENT_COUPON_BUTTON: "OK",
    PAYMENT_COUPON_LIST_PLACEHOLDER_DEFAULT: "There is no coupon available",
    PAYMENT_COUPON_LIST_PLACEHOLDER: ({ number }) =>
      `${number} coupon available`,
    PAYMENT_APPLY_COUPON: "Discount",
    PAYMENT_FINAL_PRICE: "Final Price",
    // PROJECTS
    PROJECTS_TITLE: ({ name }) => `${name},\nMy Project`,
    PROJECTS_CATEGORY1: "All",
    PROJECTS_CATEGORY2: "In Progress",
    PROJECTS_CATEGORY3: "Complete",
    PROJECTS_INPROGRESS_SUBTITLE: "You can check projects in progress",
    PROJECTS_INPROGRESS_INFO: "Project Details",
    PROJECTS_INPROGRESS_STATUS: "Status",
    PROJECTS_INPROGRESS_NONE: "There is no project in progress",
    PROJECTS_INPROGRESS_NONE_BUTTON: "Go to design store",
    PROJECTS_INPROGRESS_MORE: "View more",
    PROJECTS_INPROGRESS_CONTINUE: "Continue editing",
    PROJECTS_COMPLETE_SUBTITLE:
      "You can check applying, applied, confirmed projects",
    PROJECTS_COMPLETE_NOTICE1:
      "Completed project video can be modified for 7days after the creation date and automatically confirmed after the period.",
    PROJECTS_COMPLETE_NOTICE2:
      "You can only download the confirmed video for 30 days after the confirmation date and unable to download afterwards.",
    PROJECTS_COMPLETE_NOTICE3: "It is impossible to modify confirmed video	",
    PROJECTS_COMPLETE_NOTICE4:
      "After the project is completed, only confirmed videos are available for download and commerical use	",
    PROJECTS_COMPLETE_VIEW: "View result",
    PROJECTS_COMPLETE_STATUS_ITEM1: "Applying",
    PROJECTS_COMPLETE_STATUS_ITEM2: "Complete",
    PROJECTS_COMPLETE_STATUS_ITEM3: "Confirm",
    SELECT_COMPLETE_TITLE: "View result",
    SELECT_EXPIRATION_NOTICE:
      "A completed project cannot be finalized after 30 days.	",
    SELECT_COMPLETE_CONFIRM_NOTICE1: "Do you want to confirm this project?",
    SELECT_COMPLETE_CONFIRM_NOTICE2: "You can not modify confirmed project",
    SELECT_COMPLETE_CONFIRM_CANCEL: "Cancel",
    SELECT_COMPLETE_CONFIRM_OK: "Confirm",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE1: "The project is confirmed",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE2:
      "You can check the confirmed project",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE3: "in My projects",
    SELECT_COMPLETE_CONFIRM_COMPLETE_MAIN: "Go to Main Page",
    SELECT_COMPLETE_CONFIRM_COMPLETE_PROJECTS: "Go to My projects",
    // 마이페이지
    ACCOUNT_WELCOME: ({ name }) => `Welcome,\n${name}`,
    ACCOUNT_NAV: "My Page",
    ACCOUNT_NAV_EDIT_PROFILE: "Change Account Details",
    ACCOUNT_NAV_COUPONS: "Coupon",
    ACCOUNT_NAV_LIKES: "Like",
    ACCOUNT_NAV_PURCHASED: "Payment history",
    ACCOUNT_NAV_LOGOUT: "Logout",
    ACCOUNT_NAV_HELP: "Customer service",
    ACCOUNT_NAV_NOTICES: "Notice",
    ACCOUNT_NAV_FAQ: "F A Q",
    EDITMYINFO_PRICING: "Subscription",
    EDITMYINFO_PRICING_NONE: "There is no registered subscription",
    EDITMYINFO_PRICING_NONE_BUTTON: "Buy Now",
    EDITMYINFO_PRICING_YES_CHANGE: "Change",
    EDITMYINFO_PRICING_YES_METHOD: "Payment method",
    ACCOUNT_PRICING_NEXT_PAYMENT_DAY: ({ date }) => {
      return `Next payment is ${moment(date).format("LL")}`;
    },
    ACCOUNT_PRICING_EXPIRE_DAY: ({ date }) =>
      `Your plan's expiration date is ${moment(date).format(
        "LL"
      )}\nand will no longer be paid.`,
    EDITMYINFO_PRICING_ACCOUNT: "email",
    EDITMYINFO_PRICING_PHONE: "Phone number",
    EDITMYINFO_PRICING_PASSWORD: "Password",
    EDITMYINFO_PRICING_PASSWORD_CHANGE: "Change Password",
    EDITMYINFO_PRICING_CONFIRM: "Confirm Password",
    EDITMYINFO_PRICING_NAME: "Name",
    EDITMYINFO_PRICING_LANGUAGE: "Language",
    EDITMYINFO_PRICING_LANGUAGE_LIST: ["Korean", "English", "Thai"],
    LANGUAGE_LIST: [
      { key: "ko", value: "Korean(한국어)" },
      { key: "en", value: "English" },
      { key: "th", value: "Thai(ภาษาไทย)" },
    ],
    EDITMYINFO_PRICING_EDIT_BUTTON: "Save changes",
    AFTER_EDITMYINFO: "Complete the Save",
    AFTER_EDITMYINFO_BUTTON: "Go to Main page",
    COUPON_BUTTON: "OK",
    COUPON_NAME: "Coupon name",
    COUPON_PERIOD: "Valid period",
    COUPON_NONE: "There is no coupon",
    ENTER_COUPON_FAIL: [
      "This coupon code doesn't exist!",
      "Please enter the coupon code again.",
      "OK",
    ],
    LIKE: "Like",
    SELECT_DELETE: "Delete Selection",
    ALL_DELETE: "Delete All",
    LIKE_INFO: "Design Info",
    LIKE_PRICE: "Price",
    LIKE_NONE: "There is no liked design",
    LIKE_VIEW: "View the design",
    PAYMENT_HISTORY: "Payment history",
    PAYMENT_HISTORY_CATEGORY: [
      "Payment type",
      "Details",
      "Payment method",
      "Payment Date",
      "Amount of payment",
      "Receipt",
    ],
    PAYMENT_HISTORY_NONE: "There is no design paid",
    PAYMENT_HISTORY_VIEW: "View receipt",
    NOTICE: "Notice",
    FAQ: "F A Q",
    FAQ_LIST: ["Payment", "Account", "Service"],
    // 편집실
    EDITOR_PROGRESS: ({ progress }) => `In progress ${progress}%`,
    EDITOR_PROGRESS_DONE: "Finish",
    EDITOR_BGM: "BGM",
    EDITOR_COLOR: "Color",
    EDITOR_SELECT_TEXT: [
      "Please enter the text",
      "Suitable for design",
      "It may not suit for design",
      "It's difficult to match the design",
      "Cancel",
      "OK",
    ],
    EDITOR_PREVIEW: "Preview",
    EDITOR_SAVED: "Save...",
    EDITOR_SELECT_IMAGE: ["Free photo Stock", "Upload file", "Find images"],
    SEARCH_IMAGE_ORIENTATION: "Direction",
    SEARCH_IMAGE_ORIENTATION_LANDSCAPE: "Horizontal",
    SEARCH_IMAGE_ORIENTATION_PORTRAIT: "Vertical",

    EDITOR_IMAGE_EDIT: [
      "Edit photo",
      "The selected photo can be cropped as much as the area to be used, flipped horizontally or rotated.",
      "Lateral Inversion",
      "Up-down Inversion",
      "Rotate",
      "Apply",
    ],
    EDITOR_SELECT_VIDEO: ["Free video stock", "Upload file", "Find videos"],
    EDITOR_VIDEO_EDIT: [
      "Edit video",
      "You can trim any parts of the selected video.",
      "Next",
      "You can use the cropped parts of the video.",
      "Apply",
    ],
    EDITOR_BGM_LIST: ["Current BGM", "Change"],
    EDITOR_BGM_UPPER: [
      "Change BGM and create any mood you want",
      "Preview",
      "Reset",
      "Apply",
    ],
    EDITOR_BGM_REQUIRE: "Applied BGM Length",
    EDITOR_BGM_EFFECT: "Fade effect",
    MOODS: "Mood",
    EDITOR_BGM_MOODS: [
      "All",
      "Bright",
      "Angry",
      "Serious",
      "Dark",
      "Happy",
      "Funky",
      "Comfortable",
      "Weird",
      "Romantic",
      "Dreamy",
    ],
    GENRES: "Genre",
    EDITOR_BGM_GENRES: [
      "All",
      "Pop",
      "Hip-hop",
      "Jazz & Blues",
      "R&B",
      "Classics",
      "Dance & Electronics",
      "Country & Folk",
      "Kids",
      "Funk",
      "Calm music",
    ],
    EDITOR_BGM_PREVIEW: [
      "Preview",
      "Preview is running.",
      "Please wait for a moment...",
    ],
    EDITOR_COLOR_LIST: [
      "Change primary color",
      "Change secondary color",
      "Change text color",
    ],
    EDITOR_COLOR_CHANGE: ["Reset", "Apply"],
    EDITOR_PREVIEW_RUN: [
      "Preview",
      "Preview is running.",
      "Please wait for a moment...",
    ],
    EDITOR_COMPLETE_NOTICE: "Do you want to finish\nthe working project?",
    EDITOR_COMPLETE_RESULT: [
      "Your project starts\nto render the video",
      "You can check the complete video in My Project",
      "Go to Main page",
      "Go to My Project",
    ],
    BLANK_PAGE: [
      "Not yet ready",
      "Please use the Android app",
      "Download the Android app",
    ],
    // 로그인 및 회원가입
    LOGIN_PAGE: [
      "Email",
      "Password",
      "Log in",
      "Sign up",
      "Forgot email?",
      "Forgot password?",
      "Continue with Google",
      "Continue with Facebook",
    ],
    LOGIN_NOTICE: [
      "Please enter the email address",
      "Please enter the password",
      "Email and password do not match",
    ],
    INPUT_EMAIL: "Email",
    INPUT_PASSWORD: "Password",
    INPUT_CONFIRM_PASSWORD: "Confirm password",
    INPUT_USER_NAME: "Name",
    INPUT_PHONE: "Mobile phone",
    PLACEHOLDER_INPUT_PHONE: "Please enter your phone number",
    BUTTON_SEND_VERIFY_CODE: "Get a verification code",
    SIGNUP: [
      "Email",
      "Please enter the email address",
      "Password",
      "Please enter the password",
      "Confirm password",
      "Please enter the password again",
      "Name",
      "Please enter the name",
      "Mobile phone",
      "Please enter your phone number",
      "Get a verification code",
      "Language setting",
      "Korean",
      "English",
      "Thai",
      "Agree to the Terms of Use",
      "Agree to the Privacy Policy",
      "Sign up",
    ],
    SIGNUP_NOTICE: [
      "Please enter the email address",
      "Please enter a valid email format",
      "Password should be longer than 8 characters including uppercase and lowercase letters in English and number",
      "It is different from the password you entered. Please enter again.",
      "Please enter the name.",
      "The verification code is sent. Please wait.",
      "If you do not get a verification code in 30 seconds, please click get verification code again.",
      "You can get a verification code maximum 5 times a day.",
      "You should agree to this condition to Sign up.",
      "You should agree to this condition to Sign up.",
    ],
    SIGNUP_COMPLETE: "Sign up complete!",
    FIND_EMAIL: "Find Email",
    FIND_EMAIL_AFTER: [
      "The verification code is sent. Please wait.",
      "If you do not get a verification code in 30 seconds, please click get verification code again.",
      "You can get a verification code maximum 5 times a day.",
      "Verification code",
      "Wrong verification code",
      "OK",
    ],
    SUCCESS_FIND_EMAIL: ["Forgot email?", "Forgot password?", "Log in"],
    FAIL_FIND_EMAIL: [
      "The email does not exist",
      "Find the email again",
      "Sign up",
    ],
    FIND_PASSWORD: [
      "Forgot password?",
      "Email",
      "Please enter the email address",
      "Verification",
      "Please enter your phone number",
      "Get a verification code",
      "OK",
    ],
    AFTER_FIND_PASSWORD: [
      "The email does not exist",
      "The verification code is sent. Please wait.",
      "If you do not get a verification code in 30 seconds, please click get verification code again.",
      "You can get a verification code maximum 5 times a day.",
      "Please enter the verification code",
      "OK",
    ],
    SUCCESS_FIND_PASSWORD: [
      "Forgot password?",
      "Reset the password",
      "Please enter your new password",
      "Confirm password",
      "Please enter your new password again",
      "OK",
    ],
    NOTICE_FIND_PASSWORD: [
      "Password should be longer than 8 characters including uppercase and lowercase letters in English and number",
      "The password is wrong. Please enter again.	",
    ],
    // SUCCESS_FIND_PASSWORD: [
    //   "Complete to change the password",
    //   "Go to Main page",
    //   "Log in",
    // ],
    // 사업제휴 문의
    CONTACT_TEXT:
      "We are waiting for new business proposals\nfor the mutual growth of VPLATE and the partner",
    CONTACT_PAGE: [
      "Global partnership",
      "Name",
      "Email",
      "Proposal contents",
      "Send",
    ],
    CONTACT_PAGE_NOTICE: [
      "Please enter your name.",
      "Please enter your email address.",
      "Please enter a valid email format.",
      "Please enter the your proposal contents.",
    ],
    CONTACT_SUCCESS_TITLE: "Thank you for proposing your amazing business.",
    CONTACT_SUCCESS_TEXT:
      "After checking with relevant team, we will get back to you shortly.",
    SUCCESS_CONTACT: ["Go to Main page"],
  },
  th: {
    MESSAGE_PROJECT_NOT_FOUND: "ไม่มีโครงการนี้",
    BUTTON_WATERMARK: "สร้างวิดีโอร่าง",
    MESSAGE_ABOUT_WATERMARK: "ใช้ลายน้ำแบรนด์กับวิดีโอ",
    MESSAGE_ABOUT_PREVIEW: "สามารถตรวจสอบการเปลี่ยนแปลงได้ค่ะ",
    MESSAGE_ABOUT_CHANGE_COLOR: "เปลี่ยนสีได้",
    MESSAGE_ABOUT_CHANGE_BGM: "สามารถเปลี่ยนเพลงพื้นหลังได้",
    BRAND_NAME: "ชื่อแบรนด์",
    INPUT_BRAND_NAME: "กรุณาใส่ชื่อแบรนด์.",
    BRAND_LOGO: "ไฟล์โลโก้",
    RADIO_BRAND_LOGO_TRUE: "ฉันมีไฟล์โลโก้.",
    RADIO_BRAND_LOGO_FALSE: "ฉันไม่มีไฟล์โลโก้.",
    BUTTON_UPLOAD_BRAND_LOGO: "อัพโหลดไฟล์",
    MESSAGE_ONLY_PNG: "เฉพาะไฟล์ png ที่ไม่มีพื้นหลังเท่านั้น",
    BRAND_LOGO_CAUTIONS: [
      "ลายน้ำของแบรนด์จะถูกแนบกับวิดีโอ.",
      "เนื่องจากลายน้ำกึ่งโปร่งใสอาจไม่สามารถมองเห็นได้เนื่องจากสีของวิดีโอ.",
      "โลโก้ของลายน้ำจะอยู่ตรงกลางของวิดีโอ.",
      "หากคุณใช้ไฟล์อื่นที่ไม่ใช่ไฟล์ png ที่ไม่มีพื้นหลังโลโก้อาจมองไม่เห็น.",
      "คุณสามารถสร้างลายน้ำแม้ไม่มีโลโก้.",
      "หากคุณไม่มีโลโก้ลายน้ำจะถูกสร้างขึ้นด้วยชื่อแบรนด์เท่านั้น.",
    ],
    MESSAGE_BRAND_NAME_REQUIRED: "คุณไม่ได้ป้อนชื่อแบรนด์.",
    MESSAGE_BRAND_LOGO_REQUIRED: "โปรดอัพโหลดโลโก้แบรนด์ค่ะ",
    MESSAGE_LOADING_WATERMARK: "มีการใช้ลายน้ำยี่ห้อ.",

    MESSAGE_WATERMARK_EXIST: "มีลายน้ำที่คุณทำ.",
    MESSAGE_WATERMARK_EXIST_DESCRIPTION:
      "กรุณาคลิกปุ่ม 'แก้ไข' เพื่อเปลี่ยน,\nหรือคลิกปุ่ม 'ใช้งาน' เพื่อใช้งานตามที่เป็นอยู่.",
    BUTTON_MODIFY_WATERMARK: "ปรับเปลี่ยน",
    BUTTON_USE_WATERMARK: "ใช้มัน",
    BUTTON_RETRY: "ลองอีกครั้ง",
    PLACEHOLDER_INPUT_VERIFY_CODE: "ป้อนรหัสยืนยัน",
    CONFIRM_CANCEL_MEMBERSHIP: "จะยกเลิกการเป็นสมาชิกไหมคะ",
    CONFIRM_REMOVE_PROJECT: "คุณแน่ใจหรือว่า ต้องการจะลบโครงการทิ้ง?",
    CONFIRM_REMOVE_PROJECT_SUBTITLE: "ไม่สามารถกู้คืนหลังจากถูกลบค่ะ",
    MESSAGE_DELETED: "ถูกลบแล้วค่ะ",
    MESSAGE_EDIT_USER_INFO_SUCCESS: "สมบูรณ์",
    MESSAGE_EDIT_USER_INFO_FAILED: "ไม่สามารถแก้ไขข้อมูลบัญชีของคุณ",
    MESSAGE_USER_NAME_REQUIRED: "ป้อนชื่อ",
    MESSAGE_CONTENT_REQUIRED: "ป้อนรายละเอียดของข้อเสนอพันธมิตร",
    MESSAGE_PHONE_REQUIRED: "ป้อนหมายเลขโทรศัพท์",
    MESSAGE_VERIFY_CODE_SENDING: "เรากำลังส่งรหัสยืนยัน",
    MESSAGE_VERIFY_CODE_SENDED: "รหัสยืนยันถูกส่งแล้ว",
    MESSAGE_VERIFY_CODE_FAILED_TO_SEND:
      "ไม่สามารถส่งรหัสยืนยัน โปรดตรวจสอบหมายเลขโทรศัพท์ของคุณอีกครั้ง",
    MESSAGE_TO_MANY_REQUEST_PREVIEW:
      "คุณพยายามรับภาพตัวอย่างหลายครั้งเกินไป โปรดลองอีกสักครู่ในภายหลัง",
    MESSAGE_PASSWORDS_DO_NOT_MATCH: "รหัสผ่านที่คุณป้อนไม่ตรงกัน",
    MESSAGE_NEED_TO_VERIFY_PHONE: "โปรดยืนยันตัวเองผ่านมือถือของคุณ",
    MESSAGE_ALREADY_REGISTERED_PHONE_NUMBER:
      "หมายเลขโทรศัพท์ที่คุณป้อนได้รับการลงทะเบียนแล้ว",
    MESSAGE_VERIFY_PHONE_FAILED: "ไม่สามารถตรวจสอบได้",
    MESSAGE_SIGNUP_FAILED: "ไม่สามารถลงทะเบียน",
    MESSAGE_FIND_EMAIL_FAILED: "ไม่มีบัญชีที่ตรงกับข้อมูลที่คุณป้อน",
    PLACEHOLDER_INPUT_CONTENT: "ป้อนรายละเอียดของข้อเสนอพันธมิตร",
    BUTTON_GO_TO_MAIN: "ไปที่หน้าหลัก",
    BUTTON_CONTACT: "ติดต่อ",
    BUTTON_MODIFY: "ตกลง",
    PROJECTS_COMPLETE_INFO: "ข้อมูลโครงการ",
    PROJECTS_COMPLETE_STATUS: "สถานะ",
    PROJECTS_COMPLETE_NONE: "ไม่มีโครงการที่เสร็จสมบูรณ์",
    PROJECTS_COMPLETE_NONE_BUTTON: "ไปที่ร้านออกแบบ",
    PROJECTS_COMPLETE_MORE: "ดูเพิ่มเติม",
    ACCOUNT_NAV_EDIT_PASSWORD: "เปลี่ยนรหัสผ่าน",
    INPUT_CURRENT_PASSWORD: "รหัสผ่านปัจจุบัน",
    INPUT_NEW_PASSWORD: "รหัสผ่านใหม่",
    INPUT_CONFIRM_NEW_PASSWORD: "ยืนยันรหัสผ่าน",
    RECOVERY: "ลืมรหัสผ่านของคุณ?",
    MESSAGE_NOT_SUPPORTED: "ฟังก์ชั่นนี้เป็นไปไม่ได้ตอนนี้",
    MESSAGE_CONFIRMED: "เสร็จสิ้นเพื่อยืนยัน",
    MESSAGE_GO_TO_EDITOR: "ไปที่บรรณาธิการ",
    MESSAGE_CONFIRM_FAILED: "ไม่สามารถยืนยันได้ กรุณาติดต่อเรา",
    MESSAGE_COUPON_ADDED: "ลงทะเบียนคูปองสำเร็จแล้ว",
    MESSAGE_COUPON_FAILED: "ไม่สามารถลงทะเบียนคูปองของคุณ",
    MESSAGE_APPLY_BGM_SUCCESS: "ประสบความสำเร็จในการเปลี่ยน BGM",
    MESSAGE_APPLY_BGM_FAILED: "ไม่สามารถเปลี่ยน BGM โปรดลองอีกครั้งในภายหลัง",
    MESSAGE_APPLY_VIDEO_SUCCESS: "สำเร็จในการใช้วิดีโอคลิปของคุณ",
    MESSAGE_APPLY_VIDEO_FAILED: "ไม่สามารถใช้วิดีโอคลิปของคุณ",
    MESSAGE_PURCHASE_AGREE_REQUIRED: "โปรดยอมรับข้อกำหนดที่จำเป็น",
    MESSAGE_PURCHASE_SUCCESS: "ความสำเร็จในการซื้อการออกแบบ",
    MESSAGE_PURCHASE_FAILED: "ไม่สามารถซื้อการออกแบบ",
    MESSAGE_PURCHASE_COUPON_ONLY: "ขณะนี้การชำระเงินที่ใช้งานได้คือผ่านคูปอง",
    MESSAGE_EMAIL_REQUIRED: "ใส่อีเมล",
    MESSAGE_PASSWORD_REQUIRED: "ใส่รหัสผ่าน",
    MESSAGE_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS:
      "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
    MESSAGE_EMAIL_NOT_VALIDATED: "โปรดป้อนรูปแบบอีเมลที่ถูกต้อง",
    PLACEHOLDER_INPUT_COUPON: "ป้อนรหัสคูปอง",
    PLACEHOLDER_INPUT_TEXT: "ป้อนข้อความ",
    PLACEHOLDER_INPUT_EMAIL: "ใส่อีเมล",
    PLACEHOLDER_INPUT_PASSWORD: "ใส่รหัสผ่าน",
    PLACEHOLDER_INPUT_USER_NAME: "กรุณาใส่ชื่อของคุณ",
    PLACEHOLDER_NO_COUPON_SELECTED: "คุณไม่ได้เลือกคูปองใดๆ",
    CANNOT_CONFIRM:
      "เมื่อโครงการได้รับการยืนยันแล้วคุณจะไม่สามารถแก้ไขได้อีกต่อไป",
    LIKES_DELETED: "ลบ",
    LIKES_ADDED: "เพิ่ม",
    OPTION_NOT_SELECTED: "อย่าเลือก",
    THUMBNAIL: "รูปขนาดย่อ",
    DISCOUNT: "ส่วนลด",
    COUPON_TYPE: "ชนิด",
    BUTTON_DOWNLOAD_VIDEO: "ดาวน์โหลด",
    BUTTON_DOWNLOAD_VIDEO_WATERMARK: "ดาวน์โหลด",
    PROJECTS_RECENT: "เมื่อเร็ว ๆ นี้",
    PURCHASED_DATE: "วันที่",
    PURCHASED_INFO: "รายละเอียด",
    MOVE_TO_STORE: "ไปที่ร้านออกแบบ",
    STORE_TITLE: "ร้านออกแบบ",
    NO_SEARCH_RESULT: "ไม่พบผลลัพธ์",
    PRICE: "ราคา",
    PRICE_CALCULATED: ({ price }) =>
      `$${comma(
        parseFloat(parseFloat((price + "").split(",").join("")).toFixed(2))
      )}`,
    PRICE_FREE: "ฟรี",
    PROFILE_NAME: ({ name }) => `${name}`,
    ACCOUNT_NO_MEMBERSHIP: "ไม่มีแผนการสมัครสมาชิก",
    PURCHASE: "ซื้อเลย",
    PURCHASE_DETAILS: "รายละเอียด",
    PURCHASE_METHOD: "วิธีการชำระเงิน",
    BUTTON_OK: "ตกลง",
    BUTTON_GO_TO_PRICING: "ซื้อเลย",
    BUTTON_CHANGE_PLAN: "เปลี่ยนแผน",
    BUTTON_GO_BACK: "หน้าที่แล้ว",
    BUTTON_GO_TO_PROJECTS: "ไปที่รายการโครงการ",
    BUTTON_CHANGE_BGM: "เปลี่ยนแปลง",
    BUTTON_APPLY: "ตกลง",
    BUTTON_RESET: "รีเซ็ต",
    BUTTON_COMPLETE: "ตกลง",
    BUTTON_CANCEL: "ยกเลิก",
    BUTTON_SAVE: "ประหยัด",
    BUTTON_PURCHASE: "ซื้อเลย",
    BUTTON_MODIFY_PROJECT: "ปรับเปลี่ยน",
    BUTTON_CONFIRM_PROJECT: "ยืนยัน",
    BUTTON_SIGNIN: "เข้าสู่ระบบ",
    BUTTON_SIGNUP: "ลงทะเบียน",
    BUTTON_FIND_EMAIL: "ลืมอีเมล?",
    BUTTON_RECOVERY: "ลืมรหัสผ่าน?",
    BUTTON_SIGNIN_WITH_FACEBOOK: "ดำเนินการต่อด้วย Facebook",
    BUTTON_SIGNIN_WITH_GOOGLE: "ดำเนินการต่อกับ Google",
    BUTTON_CANCEL_MEMBERSHIP: "ยกเลิกการสมัครสมาชิก",
    EDITOR_CURRENT_BGM: "BGM ปัจจุบัน",
    EDITOR_BGM_UNTITLED: "ไม่มีเลย",
    EDITOR_BGM_DEFAULT_DESCRIPTION: "นี่คือ BGM เริ่มต้น",
    EDITOR_BGM_APPLYING: "กำลังสมัครตอนนี้",
    EDITOR_TEXT_HELPS: [
      "เหมาะสำหรับออกแบบ",
      "อาจไม่เหมาะกับการออกแบบ",
      "มันยากที่จะจับคู่การออกแบบ",
    ],
    LOADING: "กำลังโหลด...",
    EDITOR_IMAGE_FREE_IMAGE: "ภาพสต็อกฟรี",
    EDITOR_IMAGE_UPLOAD: "อัพโหลดไฟล์",
    EDITOR_IMAGE_APPLYING: "กำลังสมัครงานของคุณ.",
    PLACEHOLDER_SEARCH_IMAGE: "ค้นหารูปภาพ",
    PLACEHOLDER_SEARCH_VIDEO: "ค้นหาวิดีโอ",
    EDITOR_EDIT_IMAGE: "แก้ไขรูปภาพ",
    EDITOR_EDIT_IMAGE_DESCRIPTION:
      "ภาพถ่ายที่เลือกสามารถถูกครอบตัดได้มากเท่ากับพื้นที่และพลิกในแนวนอนหรือหมุน",
    EDITOR_EDIT_IMAGE_FLIP_HORIZONTAL: "การผกผันด้านข้าง",
    EDITOR_EDIT_IMAGE_FLIP_VERTICAL: "ผกผันขึ้น - ลง",
    EDITOR_EDIT_IMAGE_ROTATE: "หมุน",

    EDITOR_VIDEO_FREE_VIDEO: "วิดีโอสต็อกฟรี",
    EDITOR_VIDEO_UPLOAD: "อัพโหลดไฟล์",
    EDITOR_EDIT_VIDEO: "แก้ไขวีดีโอ",
    EDITOR_EDIT_VIDEO_CROP: "พืชผล",
    EDITOR_EDIT_VIDEO_TRIM: "การตัดแต่ง",
    EDITOR_VIDEO_APPLYING: "กำลังสมัครงานของคุณ",

    CANNOT_CHANGE_BGM: "ไม่สามารถเปลี่ยนสีได้",
    PROJECTS_COMPLETED_NONE: "ไม่มีโครงการที่เสร็จสมบูรณ์",
    GREETINGS: ({ name }) => `สวัสดี, ${name}.`,
    MESSAGE_SIGNIN_FAILED: "การลงชื่อเข้าใช้ล้มเหลว",
    RATIO: "อัตราส่วน",
    RATIO_LIST: [
      "ทั้งหมด",
      "สี่เหลี่ยม",
      "แนวตั้ง",
      "ตามแนวนอน",
      "สำหรับ Instagram",
    ],
    CATEGORY: "ประเภท",
    CATEGORY_LIST: [
      "ทั้งหมด",
      "คาเฟ่และร้านอาหาร",
      "ความสวยความงามและแฟชั่น",
      "บริษัท",
      "ศิลปะ",
      "การท่องเที่ยว",
      "ส่วนบุคคล",
      "กิจกรรม",
      "การส่งเสริม",
      "อีคอมเมิร์ซ",
      "การเล่านิยาย",
      "วิชาการพิมพ์",
      "สินค้า",
      "postโปสเตอร์er",
      "ฯลฯ",
    ],
    STYLE: "สไตล์",
    STYLE_LIST: [
      "ทั้งหมด",
      "อบอุ่น",
      "หนาวเหน็บ",
      "ซาบซึ้ง",
      "เงียบสงบ",
      "มีสีสัน",
      "ทันสมัย",
      "การทดลอง",
      "สามมิติ",
    ],
    PROJECT_STATUS: [
      "กำลังดำเนินการ",
      "พร้อมที่จะเรนเดอร์",
      "กำลังทำ",
      "สมบูรณ์",
      "ได้รับการยืนยัน",
    ],

    // MAIN UI
    NAVIGATION_HOME: "หน้าหลัก",
    NAVIGATION_STORE: "ร้านค้าออกแบบ",
    NAVIGATION_PRICING: "การสมัครสมาชิก",
    NAVIGATION_EDITOR: "ตัวแก้ไข",
    NAVIGATION_PROJECTS: "โครงการของฉัน",
    NAVIGATION_LOGIN: "เข้าสู่ระบบ",
    CARD1_CATCHPHRASE: "งานวิดีโอโฆษณาที่เรียบง่ายที่สุด\nVPLATE",
    CARD1_BUTTON: "ดูการออกแบบ",
    CARD2_HOWTO1: "เพียงแค่เลือก",
    CARD2_HOWTO2: "และเข้าสู่",
    CARD2_HOWTO3: "มันจบแล้ว",
    CARD2_DESIGN: "จำนวนการออกแบบ",
    CARD2_PROJECT: "จำนวนโครงการ",
    CARD2_BUTTON: "ดูการออกแบบ",
    CARD3_MESSAGE: "การออกแบบโฆษณาทุกชนิดสำหรับคุณ",
    CARD3_CATEGORY1: "คาเฟ่และร้านอาหาร",
    CARD3_CATEGORY2: "ความสวยความงามและแฟชั่น",
    CARD3_CATEGORY3: "บริษัท",
    CARD3_CATEGORY4: "ศิลปะ",
    CARD3_CATEGORY5: "การท่องเที่ยว",
    CARD3_CATEGORY6: "ส่วนบุคคล",
    CARD3_CATEGORY7: "กิจกรรม",
    CARD3_CATEGORY8: "การส่งเสริม",
    CARD4_TITLE: ({ month, week }) => {
      const monthList = {
        1: "มกราคม",
        2: "กุมภาพันธ์",
        3: "มีนาคม",
        4: "เมษายน",
        5: "อาจ",
        6: "มิถุนายน",
        7: "กรกฎาคม",
        8: "สิงหาคม",
        9: "กันยายน",
        10: "ตุลาคม",
        11: "พฤศจิกายน",
        12: "ธันวาคม",
      };
      const weekList = {
        1: "1ของ",
        2: "2ของ",
        3: "3ของ",
        4: "4ของ",
        5: "5ของ",
      };
      return `สัปดาห์ที่ ${weekList[week]}${monthList[month]}`;
    },
    CARD4_BUTTON: "ดูเพิ่มเติม",
    CARD5_MESSAGE: ({ number }) => `มี ${number} วิดีโอที่ทำด้วย VPLATE`,
    CARD5_BUTTON: "ดูการออกแบบเพิ่มเติม",
    CARD6_MESSAGE: "สร้างวิดีโอด้วย\nแอพโทรศัพท์มือถือ",
    FOOTER_CONTACT: "ติดต่อ",
    FOOTER_COMPANY: "แนะนำ บริษัท",
    FOOTER_POLICY: "นโยบาย",
    FOOTER_PRIVACY: "นโยบายความเป็นส่วนตัว",
    FOOTER_PAYMENT: "เงื่อนไขการชำระเงิน",
    FOOTER_CORPORATION: "The Vplanet Inc.",
    FOOTER_DETAILS: "© 2020 VPLATE สงวนลิขสิทธิ์",
    // DESIGN STORE UI
    TITLE: "ร้านค้าออกแบบ",
    FILTER_RATIO: "อัตราส่วน",
    ALL: "ทั้งหมด",
    RATIO2: "สี่เหลี่ยม",
    RATIO3: "แนวตั้ง",
    RATIO4: "ตามแนวนอน",
    FILTER_CATEGORY: "ประเภท",
    CATEGORY1: "ทั้งหมด",
    CATEGORY2: "คาเฟ่และร้านอาหาร",
    CATEGORY3: "ความสวยความงามและแฟชั่น	",
    CATEGORY4: "บริษัท",
    CATEGORY5: "ศิลปะ",
    CATEGORY6: "การท่องเที่ยว",
    CATEGORY7: "ส่วนบุคคล",
    CATEGORY8: "กิจกรรม",
    CATEGORY9: "การส่งเสริม",
    CATEGORY10: "อีคอมเมิร์ซ",
    CATEGORY11: "การเล่านิยาย",
    CATEGORY12: "วิชาการพิมพ์",
    CATEGORY13: "สินค้า",
    CATEGORY14: "โปสเตอร์",
    CATEGORY15: "ฯลฯ",
    FILTER_STYLE: "สไตล์",
    STYLE1: "ทั้งหมด",
    STYLE2: "อบอุ่น",
    STYLE3: "หนาวเหน็บ",
    STYLE4: "ซาบซึ้ง",
    STYLE5: "เงียบสงบ",
    STYLE6: "มีสีสัน",
    STYLE7: "ทันสมัย",
    STYLE8: "การทดลอง",
    STYLE9: "สามมิติ",
    RESET: "รีเซ็ต",
    CUSTOM: "กำหนดเองได้",
    ONLYIMAGE: "ต้องใช้ภาพถ่ายเท่านั้น",
    ONLYVIDEO: "ต้องใช้วิดีโอเท่านั้น",
    BOTH: "ใช้ทั้งคู่",
    MOSTPOPULAR: "เป็นที่นิยมมากที่สุด",
    RECENT: "ล่าสุดมากที่สุด",
    // SELECT DESIGN
    DETAIL_CATEGORY: "ประเภท",
    DETAIL_STYLE: "สไตล์",
    DETAIL_AUTHOR: "ผู้เขียน",
    DETAIL_INFO: "รายละเอียด",
    DETAIL_NOTICE1: "สิทธิ์การคัดลอกเพลงที่สงวนไว้โดย VPLATE.",
    DETAIL_NOTICE2:
      "วิดีโอทั้งหมดที่สร้างด้วย VPLATE สามารถนำไปใช้งานเชิงพาณิชย์ได้.",
    DETAIL_NOTICE3: "อย่าขายวิดีโอที่สร้างขึ้นทั้งหมดอีกครั้ง.",
    DETAIL_PRICE: "ราคารวม",
    DETAIL_BUTTON: "ซื้อเลย",
    DETAIL_BUTTON_SUBSCRIPTION: "สร้างด้วยการออกแบบนี้",
    DETAIL_SIMILAR: "การออกแบบที่คล้ายกัน",
    // PRICING PAGE
    MESSAGE_DESKTOP_ONLY: "การแก้ไขการออกแบบสามารถทำได้โดย PC หรือแอนดรอยด์",
    MESSAGE_SESSION_EXPIRED:
      "วาระการล็อกอินได้หมดอายุแล้ว กรุณาล็อกอินใหม่อีกครั้งค่ะ",
    CONNECT_SOCIAL_MEDIA: "เชื่อมต่อบัญชีสื่อสังคมออนไลน์",
    CONNECT_SOCIAL_MEDIA_DESCRIPTION:
      "ไม่มีบัญชีที่เกี่ยวข้องกับบัญชีสื่อสังคมออนไลน์ที่ลงชื่อเข้าใช้ค่ะ\nสามารถสมัครสมาชิกหรือเชื่อมต่อกับบัญชีที่มีอยู่แล้วค่ะ.",
    OR: "หรือ",
    PRICING_SUBSCRIPTION_BASIC_DESCRIPTION:
      "สำหรับนักธุรกิจหรือนักการตลาดที่ต้องการวิดีโอเป็นครั้งคราว",
    PRICING_SUBSCRIPTION_STANDARD_DESCRIPTION:
      "สำหรับธุรกิจที่กำลังเติบโตและหน่วยงานที่ต้องการการตลาดวิดีโอรายวันและเนื้อหาวิดีโอเพิ่มเติมสำหรับธุรกิจ",
    PRICING_SUBSCRIPTION_PRO_DESCRIPTION:
      "สำหรับ บริษัท ที่ต้องการสร้างโซลูชันวิดีโอแบบกำหนดเอง",
    PRICING_SUBSCRIPTION_BASIC_TOP: "เป็นที่นิยมที่สุดในตอนนี้!",
    PRICING_SUBSCRIPTION_BASIC_TARGET: "ส่วนบุคคล",
    PRICING_SUBSCRIPTION_STANDARD_TARGET: "ทีม",
    PRICING_SUBSCRIPTION_MEMBERS_1: "1 คน",
    PRICING_SUBSCRIPTION_MEMBERS_5: "5 คน",
    PRICING_SUBSCRIPTION_MEMBERS_10: "10 คน",
    PRICING_SUBSCRIPTION_MEMBERS_20: "20 คน",
    PRICING_SUBSCRIPTION_BENEFIT_1_HEADER: "คุณสมบัติวิดีโอ",
    PRICING_SUBSCRIPTION_BENEFIT_1_ITEMS: [
      "5M + หุ้นฟรี",
      "สามารถใช้ได้กับภาพของคุณเอง",
      "BGM ปลอดค่าลิขสิทธิ์",
      "สร้างวิดีโอเดี่ยวไม่ จำกัด",
      "ดาวน์โหลดวิดีโอเดียวไม่ จำกัด",
      "บรรณาธิการการออกแบบ",
      "ทุกขนาดวิดีโอสำหรับแต่ละแพลตฟอร์มโซเชียลมีเดีย",
      "หุ้นพรีเมี่ยม",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_1_TOOLTIPS: [
      "คุณสามารถใช้หุ้นฟรีผ่าน Pixabay, Unsplash, Pexels",
      "คุณสามารถใช้ภาพของคุณเอง",
      "เราให้บริการ BGM ปลอดค่าลิขสิทธิ์ซึ่งจัดทำโดย VPLATE",
      "คุณสามารถใช้เทมเพลตและ\nสร้างวิดีโอเดียวได้ไม่ จำกัด",
      "คุณสามารถดาวน์โหลดทุกวิดีโอ\nจนถึงช่วงสมัครสมาชิก",
      "เรามีโปรแกรมแก้ไขอัจฉริยะของเรา\nเพื่อใช้เทมเพลตวิดีโอ",
      "เราจัดเตรียมมาตราส่วนแนวนอนแนวนอนสี่เหลี่ยมแต่ละอัน\nที่สามารถใช้บนโซเชียลมีเดียทุกแพลตฟอร์ม",
      "คุณสามารถใช้ภาพสต็อกระดับพรีเมียม\nผ่านภา GettyImages",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_2_HEADER: "คุณสมบัติการสนับสนุน",
    PRICING_SUBSCRIPTION_BENEFIT_2_ITEMS: [
      "มีจำหน่ายทั่วไป",
      "ใบอนุญาตผู้ค้าปลีก",
      "ลายน้ำแบรนด์",
      "หน้า Landing Page ที่กำหนดเอง",
      "การออกแบบวิดีโอที่กำหนดเอง",
      "การเข้าถึง API วิดีโอ",
      "การทำงานร่วมกัน",
      "สนับสนุนลูกค้า",
    ],
    PRICING_SUBSCRIPTION_BENEFIT_2_TOOLTIPS: [
      "คุณสามารถใช้วิดีโอในเชิงพาณิชย์ได้",
      "คุณสามารถขายวิดีโอได\nให้กับบุคคลที่สาม",
      "คุณสามารถส่งออกวิดีโอร่างผ่าน\nฟังก์ชั่นลายน้ำแบรนด์",
      "เรามีหน้า Landing Page ที่กำหนดเอง\nเหมาะกับธุรกิจของคุณ",
      "เราให้บริการออกแบบวิดีโอแบบกำหนดเองใหม\nหมาะกับแบรนด์ของคุณ",
      "เรามี API วิดีโอ\nซึ่งสามารถใช้ในธุรกิจของคุณได้",
      "คุณสามารถสร้างวิดีโอร่วมกับทีมของคุณ",
      "ผู้จัดการของเราจะให\nโซลูชั่นผ่านช่องแชท",
    ],
    PRICING_SUBSCRIPTION_BASIC_MAX_MEMBERS: "1 คน",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS: "สมาชิกในทีมมากถึง 5 ~ 20 คน",
    PRICING_SUBSCRIPTION_STANDARD_MAX_MEMBERS_CAPTION:
      "(ราคาแตกต่างกันไปขึ้นอยู่กับ\nจำนวนสมาชิกในทีม)",
    PRICING_SUBSCRIPTION_PRO_MAX_MEMBERS: "ไม่ จำกัด",
    PRICING_SUBSCRIPTION_BASIC_SUPPORT_SERVICE: "การสนับสนุนการแชท",
    PRICING_SUBSCRIPTION_STANDARD_SUPPORT_SERVICE: "การสนับสนุนลำดับความสำคัญ",
    PRICING_SUBSCRIPTION_PRO_SUPPORT_SERVICE: "รองรับแพลตฟอร์มเฉพาะ",
    PRICING_FAQ: "ต้องการข้อมูลเพิ่มเติมเกี่ยวกับราคาหรือไม่?",
    PER_YEAR: "/ ประจำปี",
    SEARCH_IMAGE_ORIENTATION_ALL: "ทั้งหมด",
    PRICING_TITLE: "การตั้งราคา",
    PRICING_PAYPERONE_MESSAGE: "หากคุณต้องการ 1 การออกแบบ",
    PRICING_PAYPERONE: "จ่ายต่อชิ้น",
    PRICING_PAYPERONE_INFO: [
      "- สามารถผลิตได้ 1 วิดีโอ",
      "- เสนออัตราส่วนวิดีโอที่กำหนดเองได้สำหรับแต่ละแพลตฟอร์ม",
      "- สามารถดาวน์โหลดวิดีโอได้ 7 วัน",
      "- จัดเตรียมเครื่องมือแก้ไขการออกแบบ",
      "- สามารถใช้ในเชิงพาณิชย์ได้",
      "- สามารถใช้ภาพถ่าย / วิดีโอที่คุณมี",
      "- มากกว่า 5 ล้านคลิปวิดีโอ / ภาพฟรี",
      "- สามารถดาวน์โหลดเป็นไฟล์ MP4 ได้",
      "- มีแหล่งกำเนิดเสียงสำหรับใช้ในเชิงพาณิชย์",
      "- สนับสนุนการแชท",
    ],
    PRICING_PAYPERONE_CATCHPHRASE1: "สิ่งเหล่านี้มีใ",
    PRICING_PAYPERONE_CATCHPHRASE2: "ห้เพียง $ 7",
    // MONTHLY PRICING INFO
    PRICING_SUBSCRIPTION_MESSAGE: "หากคุณจำเป็นต้องใช้การออกแบบทั้งหมด",
    PRICING_SUBSCRIPTION_MONTHLY: "รายเดือน",
    PRICING_SUBSCRIPTION_ANNUALLY: "เป็นประจำทุกปี",
    PRICING_SUBSCRIPTION_BASIC: "ขั้นพื้นฐาน",
    PRICING_SUBSCRIPTION_STANDARD: "มาตรฐาน",
    PRICING_SUBSCRIPTION_PRO: "มืออาชีพ",
    PRICING_BASIC_PRICE: "$45 / เดือน",
    PRICING_PERIOD_MONTHLY: "เดือน",
    PRICING_PERIOD_ANNUALLY: "เรียกเก็บเงินเป็นรายปี",
    PER_MONTH: "/ เดือน",
    PET_YEAR: "/ เรียกเก็บเงินเป็นรายปี",
    PRICING_BASIC_INFO1: [
      "การผลิตวิดีโอแบบไม่จำกัด",
      "ดาวน์โหลดวิดีโอไม่จำกัด",
      "มีจำหน่ายเพื่อการใช้งานเชิงพาณิชย์",
      "สมาชิกทีมสูงสุด 1 คน",
      "สนับสนุนการแชท",
    ],
    PRICING_BASIC_INFO2: [
      "- ให้ภาพฟรี 5 ล้านภาพ",
      "- จัดเตรียมเพลงสำหรับการใช้ในเชิงพาณิชย์",
      "- สามารถสร้างโครงการได้ไม่ จำกัด",
      "- สามารถดาวน์โหลดวิดีโอได้ไม่ จำกัด",
      "- เสนออัตราส่วนที่เหมาะสมสำหรับแต่ละแพลตฟอร์ม",
      "- จัดเตรียมเครื่องมือแก้ไขการออกแบบ",
      "- สามารถใช้ภาพถ่าย / วิดีโอที่คุณมี",
      "- สามารถดาวน์โหลดเป็นไฟล์ MP4 ได้",
      "- สามารถใช้ในเชิงพาณิชย์ได้",
      "　",
      "การขายวิดีโอที่คุณทำไว้เป็นสิ่งต้องห้ามในแผนนี้",
    ],
    PRICING_STANDARD_PRICE: "$190 / เดือน",
    PRICING_STANDARD_INFO1: [
      "ให้คลิปภาพพรีเมี่ยม",
      "การให้ใบอนุญาตผู้ค้าปลีก",
      "ให้ฟังก์ชั่นลายน้ำแบรนด์",
      "การสนับสนุนลำดับความสำคัญ",
    ],
    PRICING_STANDARD_INFO2: [
      "- ให้ภาพฟรี 5 ล้านภาพ",
      "- จัดเตรียมเพลงสำหรับการใช้ในเชิงพาณิชย์",
      "- สามารถสร้างโครงการได้ไม่ จำกัด",
      "- สามารถดาวน์โหลดวิดีโอได้ไม่ จำกัด",
      "- เสนออัตราส่วนที่เหมาะสมสำหรับแต่ละแพลตฟอร์ม",
      "- จัดเตรียมเครื่องมือแก้ไขการออกแบบ",
      "- สามารถใช้ภาพถ่าย / วิดีโอที่คุณมี",
      "- สามารถดาวน์โหลดเป็นไฟล์ MP4 ได้",
      "- สามารถใช้ในเชิงพาณิชย์ได้",
      "- ให้คลิปภาพพรีเมี่ยม (ยังไม่เสร็จ)",
      "- สมาชิกทีมสูงสุด 5 คน",
      "- การสนับสนุนลำดับความสำคัญ",
      "- การให้ใบอนุญาตผู้ค้าปลีก",
      "- ให้ฟังก์ชั่นลายน้ำแบรนด์ (ยังไม่เสร็จ)",
    ],
    PRICING_STANDARD_OPTIONS: [
      "สมาชิกทีมสูงสุด 5 คน",
      "สมาชิกทีมสูงสุด 10 คน",
      "สมาชิกทีมสูงสุด 20 คน",
    ],
    PRICING_PRO_PRICE: "ติดต่อเรา",
    PRICING_PRO_INFO1: [
      "สมาชิกในทีมไม่ จำกัด",
      "บริการสร้างสรรค์",
      "บูรณาการที่กำหนดเอง",
      "การเข้าถึง วีดีโอ API",
      "การสนับสนุนลำดับความสำคัญสูงสุด",
    ],
    PRICING_PRO_INFO2: [
      "- ให้ภาพฟรี 5 ล้านภาพ",
      "- จัดเตรียมเพลงสำหรับการใช้ในเชิงพาณิชย์",
      "- สามารถสร้างโครงการได้ไม่ จำกัด",
      "- สามารถดาวน์โหลดวิดีโอได้ไม่ จำกัด",
      "- เสนออัตราส่วนที่เหมาะสมสำหรับแต่ละแพลตฟอร์ม",
      "- จัดเตรียมเครื่องมือแก้ไขการออกแบบ",
      "- สามารถใช้ภาพถ่าย / วิดีโอที่คุณมี",
      "- สามารถดาวน์โหลดเป็นไฟล์ MP4 ได้",
      "- สามารถใช้ในเชิงพาณิชย์ได้",
      "- ให้คลิปภาพพรีเมี่ยม (ยังไม่เสร็จ)",
      "- สมาชิกในทีมไม่ จำกัด",
      "- การสนับสนุนลำดับความสำคัญสูงสุด",
      "- การให้ใบอนุญาตผู้ค้าปลีก",
      "- ให้ฟังก์ชั่นลายน้ำแบรนด์ (ยังไม่เสร็จ)",
      "- บริการสร้างสรรค์",
      "- บูรณาการที่กำหนดเอง",
      "- การเข้าถึง วีดีโอ API",
    ],
    PRICING_BUTTON: "ซื้อเลย",
    PRICING_BUTTON_DISABLED: "ใช้อยู่ในปัจจุบัน",
    // YEARLY PRICING INFO
    PRICING_BASIC_SALE: "ลด 20%",
    PRICING_BASIC_PRICE_DISCOUNTED: "$35 / เดือน",
    PRICING_BASIC_PRICE_ANNUALLY: "$432 / เรียกเก็บเงินเป็นรายปี",
    PRICING_BASIC_TIP: "$432 / เรียกเก็บเงินเป็นรายปี",
    PRICING_STANDARD_SALE: "ลด 30%",
    PRICING_STANDARD_PRICE_DISCOUNTED: "$135 / เดือน",
    PRICING_STANDARD_PRICE_ANNUALLY: "$1,620 / เรียกเก็บเงินเป็นรายปี",
    PRICING_STANDARD_TIP: "$1,620 / เรียกเก็บเงินเป็นรายปี",
    // PAYMENT PAGE
    PAYMENT_DETAIL: "รายละเอียด",
    PAYMENT_TYPE: "ชนิด",
    PAYMENT_PAYPERONE: "จ่ายต่อชิ้น",
    PAYMENT_MONTHLY: "รายเดือน",
    PAYMENT_ANNUALLY: "เป็นประจำทุกปี",
    PAYMENT_PRICE: "ราคา",
    PAYMENT_METHOD: "วิธีการชำระเงิน",
    // 한글 아니면 일단 준비중이라는 표현 필요
    PAYMENT_METHOD_CARD: "Card",
    PAYMENT_METHOD_CARD_REBILL: "Card",
    PAYMENT_METHOD_PHONE: "Phone",
    PAYMENT_METHOD_KAKAO_PAY: "Kakaopay",
    PAYMENT_POLICY:
      "ฉันยืนยันผลิตภัณฑ์การชำระเงินและรายละเอียดการสั่งซื้อและยอมรับมัน",
    PAYMENT_COUPON: "คูปอง",
    PAYMENT_PLACEHOLDER: "กรุณาใส่รหัสคูปอง",
    PAYMENT_COUPON_BUTTON: "ตกลง",
    PAYMENT_COUPON_LIST_PLACEHOLDER_DEFAULT: "ไม่มีคูปอง",
    PAYMENT_COUPON_LIST_PLACEHOLDER: ({ number }) => `มี ${number} คูปอง`,
    PAYMENT_APPLY_COUPON: "ส่วนลด",
    PAYMENT_FINAL_PRICE: "ราคาสุดท้าย",
    // PROJECTS
    PROJECTS_TITLE: ({ name }) => `${name},\nโครงการของฉัน`,
    PROJECTS_CATEGORY1: "ทั้งหมด",
    PROJECTS_CATEGORY2: "กำลังดำเนินการ",
    PROJECTS_CATEGORY3: "สมบูรณ์",
    PROJECTS_INPROGRESS_SUBTITLE:
      "คุณสามารถดูโครงการที่กำลังดำเนินการอยู่ในปัจจุบัน",
    PROJECTS_INPROGRESS_INFO: "รายละเอียดโครงการ",
    PROJECTS_INPROGRESS_STATUS: "สถานะ",
    PROJECTS_INPROGRESS_NONE: "ไม่มีโครงการที่ดำเนินอยู่",
    PROJECTS_INPROGRESS_NONE_BUTTON: "ไปที่ออกแบบร้านค้า",
    PROJECTS_INPROGRESS_MORE: "ดูเพิ่มเติม",
    PROJECTS_INPROGRESS_CONTINUE: "ทำการแก้ไขต่อไป",
    PROJECTS_COMPLETE_SUBTITLE:
      "คุณสามารถตรวจสอบโครงการที่กำลังนำไปใช้ นำไปใช้แล้ว และได้รับการยืนยันแล้ว",
    PROJECTS_COMPLETE_NOTICE1:
      "วิดีโอโครงการที่เสร็จสมบูรณ์สามารถแก้ไขได้ภายใน 7 วันหลังจากวันที่สร้างและยืนยันโดยอัตโนมัติหลังจากช่วงเวลานั้น",
    PROJECTS_COMPLETE_NOTICE2:
      "คุณสามารถดาวน์โหลดวิดีโอที่ได้รับการยืนยันภายใน 30 วันหลังจากวันที่ยืนยัน และไม่สามารถดาวน์โหลดได้ในภายหลัง",
    PROJECTS_COMPLETE_NOTICE3: "ไม่สามารถแก้ไขวิดีโอที่ยืนยันแล้วได้",
    PROJECTS_COMPLETE_NOTICE4:
      "หลังจากเสร็จสิ้นโครงการ คุณจะสามารถดาวน์โหลดและใช้ในเชิงพานิชย์ได้เฉพาะวิดีโอที่ยืนยันเท่านั้น",
    PROJECTS_COMPLETE_VIEW: "ดูผลลัพธ์",
    PROJECTS_COMPLETE_STATUS_ITEM1: "กำลังสมัคร",
    PROJECTS_COMPLETE_STATUS_ITEM2: "สมบูรณ์",
    PROJECTS_COMPLETE_STATUS_ITEM3: "ยืนยัน",
    SELECT_COMPLETE_TITLE: "ดูผลลัพธ์",
    SELECT_EXPIRATION_NOTICE:
      "โครงการที่เสร็จสิ้นแล้วจะไม่สามารถสรุปได้หลังจากผ่านไป 30 วัน",
    SELECT_COMPLETE_CONFIRM_NOTICE1: "คุณต้องการยืนยันโครงการนี้หรือไม่?",
    SELECT_COMPLETE_CONFIRM_NOTICE2: "คุณไม่สามารถแก้ไขโครงการที่ยืนยัน",
    SELECT_COMPLETE_CONFIRM_CANCEL: "ยกเลิก",
    SELECT_COMPLETE_CONFIRM_OK: "ยืนยัน",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE1: "ยืนยันโครงการเรียบร้อยแล้ว",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE2:
      "คุณสามารถตรวจสอบโครงการที่ยืนยันแล้ว",
    SELECT_COMPLETE_CONFIRM_COMPLETE_NOTICE3: "ในโครงการของฉัน",
    SELECT_COMPLETE_CONFIRM_COMPLETE_MAIN: "ไปที่หน้าหลัก",
    SELECT_COMPLETE_CONFIRM_COMPLETE_PROJECTS: "ไปที่โครงการของฉัน",
    COMPLETE_INFO: "รายละเอียดโครงการ",
    COMPLETE_STATUS: "สถานะ",
    COMPLETE_NONE: "ไม่มีโครงการที่สมบูรณ์",
    COMPLETE_NONE_BUTTON: "ไปที่ออกแบบร้านค้า",
    COMPLETE_MORE: "ดูเพิ่มเติม",
    // 마이페이지
    ACCOUNT_WELCOME: ({ name }) => `ยินดีต้อนรับ.\n${name}`,
    ACCOUNT_NAV: "หน้าของฉัน",
    ACCOUNT_NAV_EDIT_PROFILE: "เปลี่ยนรายละเอียดบัญชี",
    ACCOUNT_NAV_COUPONS: "คูปอง",
    ACCOUNT_NAV_LIKES: "ชอบ",
    ACCOUNT_NAV_PURCHASED: "ประวัติการชำระเงิน",
    ACCOUNT_NAV_LOGOUT: "ออกจากระบบ",
    ACCOUNT_NAV_HELP: "บริการลูกค้า",
    ACCOUNT_NAV_NOTICES: "แจ้งให้ทราบ",
    ACCOUNT_NAV_FAQ: "F A Q",
    // CATEGORY_MYPAGE1: "",
    // CATEGORY_MYPAGE2: "",
    // CATEGORY_MYPAGE3: "",
    // CATEGORY_MYPAGE4: "",
    // CATEGORY_MYPAGE5: "",
    // ACCOUNT_NAV_LOGOUT: "",
    // CATEGORY_MYPAGE7: "",
    // CATEGORY_MYPAGE8: "",
    // CATEGORY_MYPAGE9: "",
    EDITMYINFO_PRICING: "การสมัครสมาชิก",
    EDITMYINFO_PRICING_NONE: "ไม่มีการสมัครสมาชิกที่ลงทะเบียน",
    EDITMYINFO_PRICING_NONE_BUTTON: "ซื้อเลย",
    EDITMYINFO_PRICING_YES_CHANGE: "เปลี่ยนแปลง",
    EDITMYINFO_PRICING_YES_METHOD: "วิธีการชำระเงิน",
    ACCOUNT_PRICING_NEXT_PAYMENT_DAY: ({ date }) => {
      return `การชำระครั้งต่อไปคือวันที่ ${moment(date).format("LL")}`;
    },
    ACCOUNT_PRICING_EXPIRE_DAY: ({ date }) =>
      `Your plan's expiration date is ${moment(date).format(
        "LL"
      )} and will no longer be paid.`,
    EDITMYINFO_PRICING_ACCOUNT: "อีเมล",
    EDITMYINFO_PRICING_PHONE: "หมายเลขโทรศัพท์",
    EDITMYINFO_PRICING_PASSWORD: "รหัสผ่าน",
    EDITMYINFO_PRICING_PASSWORD_CHANGE: "เปลี่ยนรหัสผ่าน",
    EDITMYINFO_PRICING_CONFIRM: "ยืนยันรหัสผ่าน",
    EDITMYINFO_PRICING_NAME: "ชื่อ",
    EDITMYINFO_PRICING_LANGUAGE: "ภาษา",
    EDITMYINFO_PRICING_LANGUAGE_LIST: ["ภาษาเกาหลี", "ภาษาอังกฤษ", "ภาษาไทย"],
    LANGUAGE_LIST: [
      { key: "ko", value: "ภาษาเกาหลี(한국어)" },
      { key: "en", value: "ภาษาอังกฤษ(English)" },
      { key: "th", value: "ภาษาไทย" },
    ],
    EDITMYINFO_PRICING_EDIT_BUTTON: "บันทึกการเปลี่ยนแปลง",
    AFTER_EDITMYINFO: "บันทึกการเปลี่ยนแปลงเสร็จแล้ว",
    AFTER_EDITMYINFO_BUTTON: "ไปที่หน้าหลัก",
    COUPON_BUTTON: "ตกลง",
    COUPON_NAME: "ชื่อคูปอง",
    COUPON_PERIOD: "ระยะเวลาที่ถูกต้อง",
    COUPON_NONE: "ไม่มีคูปอง",
    ENTER_COUPON_FAIL: [
      "ไม่มีรหัสคูปองนี้!",
      "กรุณาใส่รหัสคูปองอีกครั้ง",
      "ตกลง",
    ],
    LIKE: "ชอบ",
    SELECT_DELETE: "ลบส่วนที่เลือก",
    ALL_DELETE: "ลบทั้งหมด",
    LIKE_INFO: "ข้อมูลการออกแบบ",
    LIKE_PRICE: "ราคา",
    LIKE_NONE: "ไม่มีการออกแบบที่ชอบ",
    LIKE_VIEW: "ดูการออกแบบ",
    PAYMENT_HISTORY: "ประวัติการชำระเงิน",
    PAYMENT_HISTORY_CATEGORY: [
      "ประเภทการชำระเงิน",
      "รายละเอียด",
      "วิธีการชำระเงิน",
      "วันจ่าย",
      "จำนวนเงินที่ซื้อ",
      "ใบเสร็จรับเงิน",
    ],
    PAYMENT_HISTORY_NONE: "ไม่มีการออกแบบที่จ่าย",
    PAYMENT_HISTORY_VIEW: "ดูใบเสร็จ",
    NOTICE: "แจ้งให้ทราบ",
    FAQ: "F A Q",
    FAQ_LIST: ["การชำระเงิน", "บัญชีผู้ใช้", "บริการ"],
    // 편집실
    EDITOR_PROGRESS: ({ progress }) => `กำลังดำเนินการ ${progress}%`,
    EDITOR_PROGRESS_DONE: "เสร็จสิ้น",
    EDITOR_BGM: "BGM",
    EDITOR_COLOR: "สี",
    EDITOR_SELECT_TEXT: [
      "กรุณากรอกข้อควา",
      "เหมาะสำหรับออกแบบ",
      "อาจไม่เหมาะกับการออกแบบ",
      "มันยากที่จะจับคู่การออกแบบ",
      "ยกเลิก",
      "ตกลง",
    ],
    EDITOR_PREVIEW: "ดูตัวอย่าง",
    EDITOR_SAVED: "ประหยัด...",
    EDITOR_SELECT_IMAGE: ["ภาพสต็อกฟรี", "อัพโหลดไฟล์", "ค้นหาภาพ"],
    SEARCH_IMAGE_ORIENTATION: "ทิศทาง",
    SEARCH_IMAGE_ORIENTATION_LANDSCAPE: "ตามแนวนอน",
    SEARCH_IMAGE_ORIENTATION_PORTRAIT: "แนวตั้ง",

    EDITOR_IMAGE_EDIT: [
      "แก้ไขรูปภาพ",
      "ภาพถ่ายที่เลือกสามารถถูกครอบตัดได้มากเท่ากับพื้นที่และพลิกในแนวนอนหรือหมุน",
      "การผกผันด้านข้าง",
      "ผกผันขึ้น - ลง",
      "หมุน",
      "เสร็จสิ้น",
    ],
    EDITOR_SELECT_VIDEO: ["สต็อกฟรีวิดีโอ", "อัพโหลดไฟล์", "ค้นหาวิดิโอ"],
    EDITOR_VIDEO_EDIT: [
      "แก้ไขวีดีโอ",
      "คุณสามารถตัดช่วงของวิดีโอที่เลือก",
      "ต่อไป",
      "คุณสามารถใช้ส่วนที่ครอบตัดของวิดีโอ",
      "เสร็จสิ้น",
    ],
    EDITOR_BGM_LIST: ["BGM ปัจจุบัน", "เปลี่ยนแปลง"],
    EDITOR_BGM_UPPER: [
      "เปลี่ยน BGM และสร้างอารมณ์ใด ๆ ที่คุณต้องการ",
      "ดูตัวอย่าง",
      "ตั้งค่าใหม่",
      "เสร็จสิ้น",
    ],
    EDITOR_BGM_REQUIRE: "ความยาว BGM ประยุกต์",
    EDITOR_BGM_EFFECT: "ผลจางหาย",
    MOODS: "อารมณ์",
    EDITOR_BGM_MOODS: [
      "ทั้งหมด",
      "สดใส",
      "โกรธ",
      "จริงจัง",
      "มืดมน",
      "มีความสุข",
      "ฟังก์กี้",
      "สบาย",
      "แปลก",
      "โรแมนติก",
      "เพ้อฝัน",
    ],
    GENRES: "แนวเพลง",
    EDITOR_BGM_GENRES: [
      "ทั้งหมด",
      "Pop",
      "Hip-hop",
      "Jazz & Blues",
      "R&B",
      "คลาสสิก",
      "แดนซ์และอิเล็กทรอนิกส์	",
      "คันทรี่และโฟล์ก",
      "เด็ก",
      "ฟังก์",
      "เพลงผ่อนคลาย",
    ],
    EDITOR_BGM_PREVIEW: [
      "ดูตัวอย่าง",
      "ภาพตัวอย่างกำลังทำงาน",
      "กรุณารอสักครู่...",
    ],
    EDITOR_COLOR_LIST: ["เปลี่ยนสีหลัก", "เปลี่ยนสีรอง", "เปลี่ยนสีข้อความ"],
    EDITOR_COLOR_CHANGE: ["ตั้งค่าใหม่", "เสร็จสิ้น"],
    EDITOR_PREVIEW_RUN: [
      "ดูตัวอย่าง",
      "ภาพตัวอย่างกำลังทำงาน",
      "กรุณารอสักครู่...",
    ],
    EDITOR_COMPLETE_NOTICE: "คุณต้องการที่จะ\nเสร็จสิ้นโครงการนี้หรือไม่?",
    EDITOR_COMPLETE_RESULT: [
      "โครงการของคุณเริ\nมสร้างวิดีโอ",
      "คุณสามารถดูวิดีโอที่เสร็จสมบูรณ์ในโครงการของฉัน",
      "ไปที่หน้าหลัก",
      "ไปที่โครงการของฉัน",
    ],
    BLANK_PAGE: ["ยังไม่พร้อม", "โปรดใช้แอพ Android", "ดาวน์โหลดแอพ Android"],
    // 로그인 및 회원가입
    LOGIN_PAGE: [
      "อีเมล",
      "รหัสผ่าน",
      "เข้าสู่ระบบ",
      "ลงทะเบียน",
      "ลืมอีเมล?",
      "ลืมรหัสผ่าน?",
      "ดำเนินการต่อกับ Google",
      "ดำเนินการต่อด้วย Facebook",
    ],
    LOGIN_NOTICE: ["กรอกอีเมล", "กรอกรหัสผ่าน", "อีเมลและรหัสผ่านไม่ตรงกัน"],
    INPUT_EMAIL: "อีเมล",
    INPUT_PASSWORD: "รหัสผ่าน",
    INPUT_CONFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
    INPUT_USER_NAME: "ชื่อ",
    INPUT_PHONE: "โทรศัพท์มือถือ",
    PLACEHOLDER_INPUT_PHONE: "กรุณาใส่หมายเลขโทรศัพท์ของคุณ",
    BUTTON_SEND_VERIFY_CODE: "รับรหัสยืนยัน",
    SIGNUP: [
      "อีเมล",
      "กรอกอีเมล",
      "รหัสผ่าน",
      "กรอกรหัสผ่าน",
      "ยืนยันรหัสผ่าน",
      "กรุณาใส่รหัสผ่านอีกครั้ง",
      "ชื่อ",
      "กรุณาใส่ชื่อ",
      "โทรศัพท์มือถือ",
      "กรุณาใส่หมายเลขโทรศัพท์ของคุณ",
      "รับรหัสยืนยัน",
      "การตั้งค่าภาษา",
      "ภาษาเกาหลี",
      "ภาษาอังกฤษ",
      "ภาษาไทย",
      "ยอมรับข้อตกลงการใช้งาน",
      "ยอมรับนโยบายความเป็นส่วนตัว",
      "ลงทะเบียน",
    ],
    SIGNUP_NOTICE: [
      "กรอกอีเมล",
      "โปรดป้อนรูปแบบอีเมลที่ถูกต้อง",
      "รหัสผ่านควรมีความยาวมากกว่า 8 ตัวอักษรรวมถึงตัวพิมพ์ใหญ่และตัวพิมพ์เล็กในภาษาอังกฤษและตัวเลข",
      "มันแตกต่างจากรหัสผ่านที่คุณป้อน กรุณากรอกใหม่",
      "กรอกชื่อ",
      "รหัสยืนยันถูกส่งแล้ว โปรดรอ.",
      "หากคุณไม่ได้รับรหัสยืนยันภายใน 30 วินาทีโปรดคลิกเพื่อรับรหัสยืนยัน",
      "คุณสามารถรับรหัสการตรวจสอบได้ถึง 5 ครั้งต่อวัน",
      "คุณควรยอมรับเงื่อนไขนี้เพื่อลงทะเบียน",
      "คุณควรยอมรับเงื่อนไขนี้เพื่อลงทะเบียน",
    ],
    SIGNUP_COMPLETE: "ลงทะเบียนเสร็จสมบูรณ์!",
    FIND_EMAIL: "ลืมอีเมล?",
    FIND_EMAIL_AFTER: [
      "รหัสยืนยันถูกส่งแล้ว โปรดรอ.",
      "หากคุณไม่ได้รับรหัสยืนยันภายใน 30 วินาทีโปรดคลิกเพื่อรับรหัสยืนยัน",
      "คุณสามารถรับรหัสการตรวจสอบได้ถึง 5 ครั้งต่อวัน",
      "รหัสยืนยัน",
      "รหัสยืนยันไม่ถูกต้อง",
      "ตกลง",
    ],
    SUCCESS_FIND_EMAIL: ["ลืมอีเมล?", "ลืมรหัสผ่าน?", "เข้าสู่ระบบ"],
    FAIL_FIND_EMAIL: ["ไม่พบอีเมลนี้", "ค้นหาอีเมลของฉันอีกครั้ง", "ลงทะเบียน"],
    FIND_PASSWORD: [
      "ลืมรหัสผ่าน?",
      "อีเมล",
      "กรอกอีเมล",
      "การยืนยันความถูกต้อง",
      "กรอกหมายเลขโทรศัพท์ของคุณ",
      "รับรหัสยืนยันความถูกต้อง",
      "ตกลง",
    ],
    AFTER_FIND_PASSWORD: [
      "ไม่พบอีเมลนี้",
      "รหัสยืนยันถูกส่งแล้ว โปรดรอ.",
      "หากคุณไม่ได้รับรหัสยืนยันภายใน 30 วินาทีโปรดคลิกเพื่อรับรหัสยืนยัน",
      "คุณสามารถรับรหัสการตรวจสอบได้ถึง 5 ครั้งต่อวัน",
      "รหัสยืนยัน",
      "รหัสยืนยันไม่ถูกต้อง",
      "ตกลง",
    ],
    SUCCESS_FIND_PASSWORD: [
      "ลืมรหัสผ่าน?",
      "รีเซ็ตรหัสผ่าน",
      "กรุณาใส่รหัสผ่านใหม่ของคุณ",
      "ยืนยันรหัสผ่าน",
      "กรุณาใส่รหัสผ่านใหม่อีกครั้ง",
      "ตกลง",
    ],
    NOTICE_FIND_PASSWORD: [
      "รหัสผ่านควรมีความยาวมากกว่า 8 ตัวอักษรรวมถึงตัวพิมพ์ใหญ่และตัวพิมพ์เล็กในภาษาอังกฤษและตัวเลข",
      "มันแตกต่างจากรหัสผ่านที่คุณป้อน กรุณากรอกใหม่",
    ],
    // SUCCESS_FIND_PASSWORD: [
    //   "เสร็จสิ้นการเปลี่ยนรหัสผ่าน",
    //   "ไปที่หน้าหลัก",
    //   "เข้าสู่ระบบ",
    // ],
    // 사업제휴 문의
    CONTACT_TEXT:
      "เรากำลังรอข้อเสนอทางธุรกิจใหม่\nสำหรับการเติบโตร่วมกันของ VPLATE และพันธมิตร",
    CONTACT_PAGE: [
      "พันธมิตรระดับโลก",
      "ชื่อ",
      "อีเมล์",
      "เนื้อหาข้อเสนอ",
      "ส่ง",
    ],
    CONTACT_PAGE_NOTICE: [
      "กรุณากรอกชื่อของคุณ.",
      "กรุณากรอกอีเมลของคุณ.",
      "โปรดป้อนรูปแบบอีเมลที่ถูกต้อง",
      "โปรดป้อนเนื้อหาข้อเสนอของคุณ",
    ],
    CONTACT_SUCCESS_TITLE: "ขอบคุณสำหรับการเสนอธุรกิจที่น่าทึ่งของคุณ",
    CONTACT_SUCCESS_TEXT:
      "หลังจากตรวจสอบกับทีมที่เกี่ยวข้องเราจะติดต่อกลับหาคุณในไม่ช้า",
    SUCCESS_CONTACT: ["ไปที่หน้าหลัก"],
  },
};

export default l10n;
