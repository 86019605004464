import Title from "./Title";
import Title2 from "./Title2";
import Body from "./Body";
import Body2 from "./Body2";
import Headline from "./Headline";
import Headline2 from "./Headline2";
import Caption from "./Caption";
import Caption2 from "./Caption2";

export default {
  Headline,
  Headline2,
  Title,
  Title2,
  Text: Body,
  Text2: Body2,
  Caption,
  Caption2,
};
