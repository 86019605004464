import React, { useState, useEffect, useCallback } from "react";
import { Button, Spin } from "antd";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import theme from "config/theme";
import cropImage from "utils/cropImage";
import flipImage from "utils/flipImage";
import { getRotatedImage as rotateImage } from "utils/rotateImage";

import iconReverseHorizontal from "assets/img/icon-reverse-horizontal.svg";
import iconReverseVertical from "assets/img/icon-reverse-vertical.svg";
import iconRotate from "assets/img/icon-rotate.svg";
import { LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 80px;
`;

const CropWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 50vh;
  position: relative;
  background-color: ${theme.brown};
`;

const FunctionWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  border-bottom: 1px solid ${theme.borderGray};

  & > div {
    width: 100%;
    height: auto;
    max-width: 360px;
    margin: 0 auto;
    padding: 16px;
    position: relative;
    display: flex;
  }
`;

const IconFunction = styled.div`
  flex: 1;
  width: auto;
  height: auto;
  position: relative;
  display: inline-block;
  padding: 16px;
  text-align: center;
  color: ${theme.white};
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 22px;
    position: relative;
    display: block;
    background-image: url(${(props) => props.icon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 8px;
  }
`;

const CropImage = inject("l10n")(
  observer(
    ({
      image: imageSrc,
      aspect,
      onCrop: handleCrop,
      l10n,
      restrictPosition,
    }) => {
      // image: 'your-image-url or as base64',
      // crop: { x: 0, y: 0 },
      // zoom: 1,
      // aspect: 4 / 3,

      const [image, setImage] = useState(imageSrc);
      const [crop, setCrop] = useState({ x: 0, y: 0 });
      const [zoom, setZoom] = useState(1);
      const [rotation, setRotation] = useState(360);
      const [isLoaded, setIsLoaded] = useState(false);
      const [isCropping, setIsCropping] = useState(false);

      const [flipH, setFlipH] = useState(false);
      const [flipV, setFlipV] = useState(false);

      const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

      const handleCropChange = setCrop;
      const handleZoomChange = setZoom;
      const handleCropComplete = useCallback(
        (croppedArea, _croppedAreaPixels) => {
          setCroppedAreaPixels(_croppedAreaPixels);
        },
        []
      );
      const handleMediaLoaded = () => {
        setIsLoaded(true);
      };

      const handleComplete = useCallback(async () => {
        setIsCropping(true);
        try {
          const croppedImage = await cropImage(
            image,
            croppedAreaPixels,
            0
            // rotation
          );
          handleCrop(croppedImage);
        } catch (e) {
          console.error(e);
        }
        setIsCropping(false);
      }, [croppedAreaPixels, image, handleCrop]);

      const handleFlipAndRotate = useCallback(async () => {
        setIsLoaded(false);
        const flipResult = await flipImage(imageSrc, flipH, flipV);
        const result = await rotateImage(flipResult, rotation);
        setImage(result);
        setIsLoaded(true);
      }, [imageSrc, flipH, flipV, rotation]);

      useEffect(() => {
        handleFlipAndRotate();
      }, [handleFlipAndRotate, flipH, flipV, rotation]);

      useEffect(() => {
        setImage(imageSrc);
      }, [imageSrc]);

      return (
        <Wrapper>
          {!isLoaded && <div>{l10n.str("LOADING")}</div>}
          <CropWrapper>
            <Cropper
              {...{ image, crop, zoom, aspect, restrictPosition }}
              onCropChange={handleCropChange}
              onZoomChange={handleZoomChange}
              // onRotationChange={setRotation}
              onCropComplete={handleCropComplete}
              onMediaLoaded={handleMediaLoaded}
              minZoom={restrictPosition ? 1 : 0}
            />
          </CropWrapper>
          <FunctionWrapper>
            <div>
              <IconFunction
                icon={iconReverseHorizontal}
                onClick={() => {
                  setFlipH((current) => !current);
                }}
              >
                {l10n.str("EDITOR_EDIT_IMAGE_FLIP_HORIZONTAL")}
              </IconFunction>
              <IconFunction
                icon={iconReverseVertical}
                onClick={() => {
                  setFlipV((current) => !current);
                }}
              >
                {l10n.str("EDITOR_EDIT_IMAGE_FLIP_VERTICAL")}
              </IconFunction>
              <IconFunction
                icon={iconRotate}
                onClick={() => {
                  setRotation((current) => {
                    if (current - 90 <= 0) {
                      return 360;
                    }
                    return current - 90;
                  });
                }}
              >
                {l10n.str("EDITOR_EDIT_IMAGE_ROTATE")}
              </IconFunction>
            </div>
          </FunctionWrapper>
          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              textAlign: "right",
            }}
          >
            <Button
              type="primary"
              size="default"
              style={{ margin: 0 }}
              onClick={() => {
                handleComplete();
              }}
              disabled={isCropping}
            >
              {!isCropping && l10n.str("BUTTON_OK")}
              {isCropping && (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              )}
            </Button>
          </div>
        </Wrapper>
      );
    }
  )
);

CropImage.propTypes = {
  image: PropTypes.string,
  onCrop: PropTypes.func,
  aspect: PropTypes.number,
  restrictPosition: PropTypes.bool,
};

CropImage.defaultProps = {
  image: "",
  onCrop: () => {},
  aspect: 1,
  restrictPosition: true,
};

export default CropImage;
