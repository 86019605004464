import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import theme from "config/theme";

import iconDone from "assets/img/icon-screen-done.svg";

const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: ${theme.black};
  user-select: none;
`;

const Body = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${theme.black};
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    border: 1px solid ${theme.black};
  }
  &.selected,
  &:active {
    opacity: 1;
    border: 2px solid ${theme.primary};
  }

  &.done::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${iconDone});
    background-position: 50% 50%;
    background-size: 28px 28px;
    background-repeat: no-repeat;
  }
`;

const Scene = ({ image, onClick, isSelected, isDone }) => {
  return (
    <Wrapper onClick={onClick}>
      <Body
        className={[isSelected && "selected", isDone && "done"].join(" ")}
        style={{ backgroundImage: `url('${image}')` }}
      >
        &nbsp;
      </Body>
    </Wrapper>
  );
};

Scene.propTypes = {
  image: PropTypes.string,
  isDone: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Scene.defaultProps = {
  image: "",
  isDone: false,
  isSelected: false,
  onClick: () => {},
};

export default Scene;
