import { create } from "axios";
import BootPay from "bootpay-js";
import queryString from "query-string";
import * as firebase from "firebase";
import { L10nStore } from "stores/l10n";

// const urlencode = require("urlencode");

function api(token) {
  let accessToken = null;
  if (token) {
    if (typeof token === "object" && token?.accessToken) {
      accessToken = token.accessToken;
    }
    if (typeof token === "string") {
      accessToken = token;
    }
  }

  const withToken = accessToken
    ? {
        // 'Access-Control-Request-Headers': 'X-AUTH-TOKEN',
        // 'Access-Control-Allow-Headers': 'X-AUTH-TOKEN, X-Requested-With',
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
      }
    : {};

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": new L10nStore().languageCode,
    ...withToken,
  };

  const axios = create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    config: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL,
      // headers: { 'Access-Control-Allow-Origin': '*' },
      headers,
    },
  });
  axios.defaults.headers = headers;

  const landing = {};
  const users = {};
  const templates = {};
  const payment = {};
  const membership = {};
  const projects = {};
  const likes = {};
  const settings = {};
  const coupons = {};
  const music = {};
  const auth = {};
  const externals = {};

  auth.verifyPhoneCode = async (
    data = { phoneNumber: String, verifyCode: String }
  ) => {
    try {
      const { phoneNumber, verifyCode } = data;
      const result = await axios({
        method: "post",
        url: "/user/verifyPhoneToken",
        data: {
          phoneNumber,
          token: verifyCode,
        },
      });
      if (result?.data?.code === "SUCCESS") {
        const { idToken: customToken } = result?.data?.result || {};

        if (customToken) {
          const signInResult = await firebase
            .auth()
            .signInWithCustomToken(customToken);
          if (signInResult) {
            const idToken = await signInResult.user.getIdToken();
            return { idToken };
          }
          return false;
        }
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  auth.requestPhoneVerifyCode = async (
    data = {
      phoneNumber: Number,
      type: "0: 회원가입, 1: 이메일 찾기, 2: 비밀번호 복구",
    }
  ) => {
    try {
      const { phoneNumber, type: phoneVerificationType } = data;
      const result = await axios({
        method: "post",
        url: "/user/requestPhoneVerification",
        data: {
          phoneNumber,
          phoneVerificationType,
        },
      });
      return result.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  auth.phone = async (data = { phoneNumber: Number }) => {
    try {
      const { phoneNumber } = data;
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);
      return confirmationResult;
      // window.confirmationResult = confirmationResult;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  auth.verifyPhone = async (
    data = { confirmationResult: Object, verifyCode: String }
  ) => {
    try {
      const { confirmationResult, verifyCode } = data;
      const credential = await confirmationResult.confirm(verifyCode);
      if (credential) {
        const idToken = await credential.user.getIdToken();
        return { idToken };
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  auth.refreshToken = async (data = { refreshToken: "" }) => {
    try {
      const { refreshToken } = data;
      const tokenResult = await axios({
        method: "post",
        url: "/user/token",
        data: { refreshToken },
      });
      if (tokenResult?.data?.code === "SUCCESS") {
        return tokenResult.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  landing.info = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "/design/landing",
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  // users.signin = {};

  // users.signin.email = async (data = { userEmail: "", password: "" }) => {
  //   try {
  //     const { userEmail, password } = data;
  //     const result = await axios({
  //       method: "post",
  //       url: "/email/login",
  //       data: {
  //         userEmail,
  //         password,
  //       },
  //     });
  //     return result.data;
  //   } catch (error) {
  //     console.log(error);
  //     return {};
  //   }
  // };

  // users.signin.token = async (data = { userUid: "", languageCode: "ko" }) => {
  //   try {
  //     const { userUid } = data;
  //     let { languageCode } = data;
  //     if (!languageCode) {
  //       languageCode = "ko";
  //     }

  //     const result = await axios({
  //       method: "post",
  //       url: "/user/login",
  //       data: {
  //         userUid,
  //         languageCode,
  //       },
  //     });
  //     return result.data;
  //   } catch (error) {
  //     console.log(error);
  //     return {};
  //   }
  // };

  users.signin = async (
    data = {
      userEmail: "email",
      password: "password",
      channel: "email | facebook | google",
    }
  ) => {
    try {
      const { userEmail, password, channel } = data;
      let result = {};
      if (!channel || channel === "email") {
        result = await axios({
          method: "post",
          url: `/user/login`,
          data: {
            userEmail,
            password,
            channel,
          },
        });
      }
      if (channel === "facebook") {
        const provider = new firebase.auth.FacebookAuthProvider();
        const firebaseResult = await firebase.auth().signInWithPopup(provider);
        console.log(firebaseResult);
        if (firebaseResult?.user) {
          const idToken = await firebaseResult?.user?.getIdToken();
          console.log(idToken);
          result = await axios({
            method: "post",
            url: `/user/login`,
            data: {
              idToken,
              channel,
            },
          });
          result.data.idToken = idToken;
        }
      }
      if (channel === "google") {
        const provider = new firebase.auth.GoogleAuthProvider();
        const firebaseResult = await firebase.auth().signInWithPopup(provider);
        console.log(firebaseResult);
        if (firebaseResult?.user) {
          const idToken = await firebaseResult?.user?.getIdToken();
          console.log(idToken);
          result = await axios({
            method: "post",
            url: `/user/login`,
            data: {
              idToken,
              channel,
            },
          });
          result.data.idToken = idToken;
        }
      }

      console.log(result?.data);

      if (result?.data?.code === "SUCCESS" && result?.data?.result) {
        return result?.data?.result;
      }
      if (result?.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  };

  users.signout = async () => {
    try {
      if (accessToken) {
        const result = await axios({
          method: "delete",
          url: "/user/logout",
        });
        if (result?.data?.code === "SUCCESS") {
          return result.data;
        }
        return false;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  users.connectSocialMedia = async (
    data = { accessToken: "", idToken: "", channel: "" }
  ) => {
    try {
      if (data.idToken && data.accessToken && data.channel) {
        const result = await axios({
          method: "patch",
          url: `/user/social`,
          data,
        });

        if (result?.data?.code === "SUCCESS") {
          return result.data;
        }
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  users.signup = async (
    data = {
      idToken: "",
      userEmail: "",
      password: "",
      userName: "",
      userPhoneNumber: "",
      channel: "",
    }
  ) => {
    try {
      const {
        idToken,
        userEmail,
        password,
        userName,
        userPhoneNumber,
        channel,
      } = data;
      const result = await axios({
        method: "post",
        url: `/user/signup`,
        data: {
          idToken,
          userEmail,
          password,
          userName,
          userPhoneNumber,
          channel,
        },
      });
      if (result?.data?.code === "SUCCESS") {
        return result.data;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  };
  // users.signup.email = async (data = { userEmail: "", password: "" }) => {
  //   try {
  //     const { userEmail, password } = data;
  //     const result = await axios({
  //       method: "post",
  //       url: `/email/signup`,
  //       data: {
  //         userEmail,
  //         password,
  //       },
  //     });
  //     return result.data;
  //   } catch (error) {
  //     console.log(error);
  //     return {};
  //   }
  // };

  users.recovery = {};
  users.recovery.email = async (data = { idToken: String }) => {
    try {
      const { idToken } = data;
      const result = await axios({
        method: "post",
        url: "/user/findEmail",
        data: {
          idToken,
        },
      });
      if (result?.data?.code === "SUCCESS") {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return {};
    }
    return {};
  };

  // /email/checkPw

  // /email/changePw

  // GET /user/info

  users.info = async () => {
    try {
      if (accessToken) {
        const result = await axios({
          method: "get",
          url: "/user/info",
        });
        if (result?.data?.code === "SUCCESS") {
          return result?.data?.result;
        }
        return result.data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  users.edit = async (data) => {
    try {
      if (accessToken) {
        const result = await axios({
          method: "patch",
          url: "/user/info",
          data,
        });
        if (result?.data?.code === "SUCCESS") {
          if (result?.data?.result) {
            return result?.data?.result;
          }
          if (result?.data?.saveUserInfo) {
            return result?.data?.saveUserInfo;
          }
        }
        return result?.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  users.changePassword = async (
    data = { password: String, idToken: String, userEmail: String }
  ) => {
    try {
      if (accessToken || data.idToken) {
        const result = await axios({
          method: "post",
          url: "/user/findPw",
          data,
        });
        if (
          result?.data?.code === "PASSWORD_CHANGE_SUCCESS" ||
          result?.data?.code === "SUCCESS"
        ) {
          return result?.data;
        }
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // PATCH /user/info

  // GET /token/info

  // GET /token/refresh

  // users.refreshToken = async () => {
  //   try {
  //     if (accessToken) {
  //       const result = await axios({
  //         method: "post",
  //         url: "/token/refresh",
  //       });

  //       return result.data;
  //     }
  //     return {};
  //   } catch (error) {
  //     console.log(error);
  //     return {};
  //   }
  // };

  templates.list = async (
    data = {
      page: 1,
      limit: 10,
      filter: 0,
      category: [0],
      style: [0],
      ratio: [0],
    }
  ) => {
    // filter=0&categorize=5&style=1&templateRatio=3
    try {
      const {
        page,
        limit,
        filter,
        category: categorize,
        style,
        ratio: templateRatio,
      } = data;
      const result = await axios({
        method: "get",
        url: `/design?${queryString.stringify({
          page,
          limit,
          filter,
          categorize,
          style,
          templateRatio,
        })}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  templates.findOne = async (data = { id: String }) => {
    try {
      const { id } = data;
      // /template/${id}/all/

      const result = await axios({
        method: "get",
        url: `/design/${id}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  payment.requestInvoice = async (
    data = { templateId: String, couponId: String, type: Number }
  ) => {
    try {
      const { templateId, couponId, type } = data;
      const requestData = { type: type || 0 };
      if (templateId) {
        requestData.templateId = templateId;
        requestData.designId = templateId;
      }
      if (couponId) {
        requestData.couponId = couponId;
      }

      const result = await axios({
        method: "post",
        url: `/invoice`,
        data: requestData,
      });
      let res = {};
      if (result?.data?.code === "SUCCESS") {
        res = result.data.result;
      } else {
        res = result.data;
      }
      if (res.design) {
        res.template = res.design;
      }
      return res;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  payment.findOne = async (data = { invoiceId: "" }) => {
    try {
      const { invoiceId } = data;
      const result = await axios({
        method: "get",
        url: `/invoice/${invoiceId}`,
      });
      let res = {};
      if (result?.data?.code === "SUCCESS") {
        res = result.data.result;
      } else {
        res = result.data;
      }
      if (res.design) {
        res.template = res.design;
      }
      return res;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  payment.calculatePrice = async (
    data = { planType: Number, couponCode: String }
  ) => {
    try {
      const { planType: paymentsPlanType, couponCode: couponId } = data;

      const result = await axios({
        method: "post",
        url: `/invoice/price/calc`,
        data: { paymentsPlanType, couponId },
      });

      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  payment.purchase = async (data = { invoiceId: "", method: "", pg: "" }) => {
    try {
      const { invoiceId, method, pg } = data;
      const user = await users.info();
      // 유저 정보 없음(로그인 안 됨)
      if (!user) {
        return false;
      }
      const invoice = await payment.findOne({ invoiceId });
      // Invoice 요청자와 로그인한 유저 정보가 다름
      if (invoice.userId !== user._id) {
        return false;
      }
      const requestData = {
        order_id: invoiceId,
        // price: 1000,
        price: parseInt(invoice?.price, 10),
        application_id: process.env.REACT_APP_BOOTPAY_APP_KEY,
        pg: pg || "danal",
        method,
        show_agree_window: 0,
        items: [],
        user_info: {
          username: user?.userName,
          email: user?.userEmail,
          addr: "",
          phone: method !== "phone" ? user?.userPhoneNumber : "",
        },
      };

      if (method === "card_rebill") {
        requestData.price = 0;
        requestData.name = "Vplate - " + invoice?.detail;
        requestData.items.push({
          item_name: invoice?.detail,
          qty: 1,
          unique: "" + invoice?.planType,
          price: parseInt(invoice?.price, 10),
          cat1: "Subscribe",
        });
      } else {
        const template = await templates.findOne({
          id: invoice?.template?._id,
        });
        // 템플릿 정보 없음

        if (!template) {
          return false;
        }

        // Invoice 정보 없음, 템플릿 정보 없음, 이미 구매함
        if (!invoice || !invoice.template || invoice.subtemplate) {
          return false;
        }
        requestData.name = "Vplate - " + template?.temTitle;
        requestData.items.push({
          item_name: template?.temTitle,
          qty: 1,
          unique: template?._id,
          price: parseInt(template?.temPrice, 10),
          cat1: "Design",
        });
      }

      console.log("requestData");
      console.log(requestData);

      return BootPay.request(requestData).confirm((res) => {
        // 결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
        // 주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
        console.log(res);
        const enable = true; // 재고 수량 관리 로직 혹은 다른 처리
        if (enable) {
          BootPay.transactionConfirm(res); // 조건이 맞으면 승인 처리를 한다.
        } else {
          BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
        }
      });
      // .error((res) => {
      //   // 결제 진행시 에러가 발생하면 수행됩니다.
      //   console.log(res);
      // })
      // .cancel((res) => {
      //   // 결제가 취소되면 수행됩니다.
      //   console.log(res);
      // })
      // .ready((res) => {
      //   // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
      //   console.log(res);
      // })
      // .confirm((res) => {
      //   // 결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
      //   // 주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
      //   console.log(res);
      //   const enable = true; // 재고 수량 관리 로직 혹은 다른 처리
      //   if (enable) {
      //     BootPay.transactionConfirm(res); // 조건이 맞으면 승인 처리를 한다.
      //   } else {
      //     BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
      //   }
      // })
      // .close((res) => {
      //   // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
      //   console.log(res);
      // })
      // .done((res) => {
      //   // 결제가 정상적으로 완료되면 수행됩니다
      //   // 비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
      //   console.log(res);
      // });
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  payment.approve = async (
    data = { invoiceId: String, pgReceiptId: String, billingKey: String }
  ) => {
    try {
      const { invoiceId, pgReceiptId, billingKey } = data;

      const result = await axios({
        method: "post",
        url: `/invoice/${invoiceId}/approve`,
        data: { pgReceiptId, billingKey },
      });
      let res = {};
      if (result?.data?.code === "SUCCESS") {
        res = result.data.result;
      } else {
        res = result.data;
      }
      if (res.design) {
        res.template = res.design;
      }
      return res;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  payment.list = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `/invoice`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  membership.plans = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `/invoice/payment/plan`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result.data?.result;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  };

  membership.subscribe = payment.approve;

  membership.cancel = async () => {
    try {
      if (!accessToken) {
        return false;
      }
      const result = await axios({
        method: "post",
        url: `/invoice/billing/cancel`,
      });

      if (result?.data?.code === "SUCCESS") {
        return true;
      }
      return result?.data;
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  projects.list = async (data = { page: 1, limit: 3, status: "0,1" }) => {
    try {
      let { page, limit } = data;
      let { status } = data;
      if (Array.isArray(status)) {
        status = status.join(",");
      }
      if (!status) {
        status = "0,1";
      }
      if (!page) {
        page = 1;
      }
      if (!limit) {
        limit = 3;
      }
      const result = await axios({
        method: "get",
        url: `/subtemplate?${queryString.stringify({ page, limit, status })}`,
      });
      let valueToReturn = {};
      if (result?.data?.code === "SUCCESS") {
        valueToReturn = result.data;
      }
      if (Array.isArray(valueToReturn?.result)) {
        valueToReturn.result = valueToReturn.result.map((project) => {
          const newProject = { ...project };

          if (newProject.design) {
            newProject.template = newProject.design;
          }

          return newProject;
        });
      }
      return valueToReturn;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  projects.create = async (data = { templateId: String }) => {
    try {
      const { templateId } = data;

      const result = await axios({
        method: "post",
        url: `/subtemplate`,
        data: { templateId, designId: templateId },
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  projects.findOne = async (
    data = { subtemplateId: String, userId: String }
  ) => {
    try {
      const { subtemplateId, userId } = data;
      // /template/${id}/all/

      const result = await axios({
        method: "post",
        url: `/subtemplate/${subtemplateId}`,
        data: { userId },
      });

      let res = {};
      if (result?.data?.code === "SUCCESS") {
        res = result.data.result;
      } else {
        res = result.data;
      }
      if (res.design) {
        res.template = res.design;
      }
      return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  projects.watermark = async (
    data = {
      subtemplateId: String,
      brandName: String,
      brandLogo: "binary",
    }
  ) => {
    try {
      const { subtemplateId, brandName, brandLogo } = data;

      const formData = new FormData();
      if (brandName) {
        formData.append("brandName", brandName);
      }
      if (brandLogo) {
        formData.append("brandLogo", brandLogo, "logo.png");
      }

      const result = await axios({
        method: "post",
        url: `/subtemplate/${subtemplateId}/watermark`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
    }
    return {};
  };

  projects.patch = async (
    data = {
      subtemplateId: String,
      sources: [],
      bgmUrl: "",
      bgmId: "",
      colors: [],
      lastSceneType: 3,
      contactNumber: null,
      userId: String,
    }
  ) => {
    // subtemplate/${subtemplateId}/sources
    try {
      const {
        subtemplateId,
        sources: userSource,
        bgmId: bgm,
        bgmUrl,
        colors: changedColor,
        lastSceneType,
        contactNumber,
        userId,
      } = data;

      const requestData = { userId };
      if (userSource) {
        requestData.userSource = userSource;
      }
      if (bgm) {
        requestData.bgm = bgm;
      }
      if (bgmUrl) {
        requestData.bgmUrl = bgmUrl;
      }
      if (changedColor) {
        requestData.changedColor = changedColor;
      }
      if (!Number.isNaN(lastSceneType)) {
        requestData.lastSceneType = parseInt(lastSceneType, 10);
      }
      if (contactNumber || contactNumber === null) {
        requestData.contactNumber = contactNumber;
      }
      // requestData.bgm = "5eba4baff1149469376077d0";
      // requestData.bgmUrl =
      //   "https://vplate-music.s3.ap-northeast-2.amazonaws.com/3_Fastion_show083.mp3";
      const result = await axios({
        method: "patch",
        url: `/subtemplate/${subtemplateId}`,
        data: requestData,
      });
      if (result?.data?.code === "SUCCESS") {
        return result.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  projects.changeStatus = async (
    data = {
      subtemplateId: String,
      status: Number,
      userId: String,
    }
  ) => {
    try {
      const { subtemplateId, status, userId } = data;

      const result = await axios({
        method: "patch",
        url: `/subtemplate/${subtemplateId}/status`,
        data: { status, userId },
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  projects.remove = async (
    data = {
      subtemplateId: String,
      userId: String,
    }
  ) => {
    try {
      const { subtemplateId, userId } = data;
      if (userId && subtemplateId) {
        const result = await axios({
          method: "delete",
          url: `/subtemplate/${subtemplateId}?userId=${userId}`,
          data: {
            userId,
          },
        });
        if (result?.data?.code === "SUCCESS") {
          return true;
        }
        return result.data;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  likes.list = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `/like`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  likes.add = async (data = { templateId: "" }) => {
    try {
      const { templateId } = data;
      const result = await axios({
        method: "put",
        url: `/like/${templateId}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  likes.remove = async (data = { templateId: "" }) => {
    try {
      const { templateId } = data;
      const result = await axios({
        method: "delete",
        url: `/like/${templateId}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  settings.faqList = async (data = { type: undefined }) => {
    try {
      const { type: faqType } = data;
      const result = await axios({
        method: "get",
        url: faqType
          ? `/setting/faq/list?${queryString.stringify({ faqType })}`
          : "/setting/faq",
      });
      if (result?.data?.code === "SUCCESS") {
        return result.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  settings.noticeList = async () => {
    try {
      const result = await axios({
        method: "get",
        url: `/setting/notice`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  settings.docs = async (data = { id: 0 }) => {
    // http://setting/terms?conditions=0
    try {
      const result = await axios({
        method: "get",
        url: `/setting/terms?conditions=${data.id}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  settings.contact = async (data = { name: "", email: "", content: "" }) => {
    try {
      const { name: userName, email: userEmail, content: userContents } = data;
      const result = await axios({
        method: "post",
        url: "/user/businessMail",
        data: { userName, userEmail, userContents },
      });
      if (result?.data?.code === "SUCCESS") {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  coupons.list = async (data = { planType: -1 }) => {
    try {
      const { planType: type } = data;
      const result = await axios({
        method: "get",
        url: `/coupon?${queryString.stringify({ type })}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  coupons.add = async (data = { code: "" }) => {
    try {
      const { code } = data;
      const result = await axios({
        method: "put",
        url: `/coupon/${code}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data?.result;
      }
      return result.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  music.list = async (
    data = { limit: Number, page: Number, moods: Array, genres: Array }
  ) => {
    try {
      let { limit, page, moods: musicMood, genres: musicGenre } = data;
      if (!limit) {
        limit = 10;
      }
      if (!page) {
        page = 1;
      }
      if (!musicMood) {
        musicMood = [0];
      }
      if (!musicGenre) {
        musicGenre = [0];
      }

      musicMood = musicMood.join(",");
      musicGenre = musicGenre.join(",");

      const result = await axios({
        method: "get",
        url: `/music?${queryString.stringify({
          limit,
          page,
          musicMood,
          musicGenre,
        })}`,
      });
      if (result?.data?.code === "SUCCESS") {
        return result?.data;
      }
      return result?.data || {};
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  if (!externals?.cafe24) {
    const cafe24 = {};

    cafe24.getCategories = async () => {
      try {
        const result = await axios({
          method: "get",
          url: "/externals/cafe24/categories",
        });
        if (result?.data) {
          return result?.data;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    };

    cafe24.uploadFiles = async (data = { files: [] }) => {
      try {
        let { files } = data;
        if (files && !Array.isArray(files)) {
          files = [files];
        }
        const result = await axios({
          method: "post",
          url: "/externals/cafe24/upload",
          data: {
            requests: files.map((file) => {
              return { image: file };
            }),
          },
        });
        if (result?.data) {
          if (result?.data?.Location) {
            result.data.url = result.data.Location;
          }
          return result?.data;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    };
    cafe24.createProduct = async (
      data = {
        productName: "",
        supplyPrice: 0,
        detailImage: "",
        additionalImages: [],
      }
    ) => {
      try {
        const { productName, supplyPrice } = data;
        let { detailImage, additionalImages } = data;

        if (additionalImages && !Array.isArray(additionalImages)) {
          additionalImages = [additionalImages];
        }

        // if (Array.isArray(additionalImages)) {
        //   additionalImages = additionalImages.map((additionalImage) => {
        //     const url = new URL(additionalImage + "");
        //     return url.pathname;
        //   });
        // }

        if (detailImage) {
          detailImage = new URL(detailImage + "").pathname || "";
        }

        if (!productName) {
          return false;
        }

        const description = (additionalImages || [])
          .map((imageUrl) => {
            if ((imageUrl + "").indexOf("file.vplate.io") >= 0) {
              return `<div style="width: 100%; text-align: center;">
                      <video src="${imageUrl}" controls style="width: 100%; max-width: 720px; display: inline-block;" autoplay loop muted>
                        Your browser does not support the video tag.
                      </video>
                    </div>`;
            }
            return `<img src="${imageUrl}" alt="image" style="width: 100%;"/>`;
          })
          .join("<br />");

        const result = await axios({
          method: "post",
          url: "/externals/cafe24/products",
          data: {
            shop_no: 1,
            request: {
              product_name: productName,
              price: supplyPrice || 0,
              supply_price: supplyPrice || 0,
              image_upload_type: "A",
              detail_image: detailImage,
              description,
            },
          },
        });

        if (result?.data) {
          return result?.data;
        }
        return false;
      } catch (error) {
        console.log(error);
      }
      return false;
    };
    cafe24.link = async (data = { mallId: "", redirectUri: "" }) => {
      try {
        const { mallId, redirectUri } = data;
        if (!accessToken) {
          return false;
        }
        const result = await axios({
          method: "post",
          url: "/externals/cafe24/link",
          data: {
            cafe24: {
              mall_id: mallId,
              redirect_uri: redirectUri || "https://vplate.io/services/cafe24",
            },
          },
        });
        if (result?.data) {
          window.location.href = `https://${mallId}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_CAFE24_CLIENT_ID}&state=${accessToken}&redirect_uri=${process.env.REACT_APP_CAFE24_REDIRECT_URI}&scope=mall.read_category,mall.write_product`;
          return result?.data;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    };

    cafe24.unlink = async () => {
      try {
        const result = await axios({
          method: "post",
          url: "/externals/cafe24/unlink",
        });
        if (result?.data) {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    };

    cafe24.refresh = async () => {
      try {
        const result = await axios({
          method: "post",
          url: "/externals/cafe24/getAccessTokenUseRefreshToken",
        });
        if (result?.data) {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
      return false;
    };
    // cafe24.auth = async () => {};

    externals.cafe24 = cafe24;
  }

  //   async uploadImage(data){
  //     return await http(`https://file.vplate.io/upload/image`, data, 'post')
  // },

  // async uploadVideo(data){
  //     return await http(`https://file.vplate.io/upload/video/withCrop`, data, 'post')
  // },
  // async makingFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:1}, 'patch')
  // },
  // async modifyFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:0}, 'patch')
  // },
  // async confirmFilm(subtemplateId){
  //     return await http(`https://api.vplate.io/subtemplate/${subtemplateId}/status`, {status:4}, 'patch')
  // }

  return {
    landing,
    users,
    templates,
    payment,
    membership,
    projects,
    likes,
    settings,
    coupons,
    music,
    auth,
    externals,
  };
}

export default api;
