import React, { useEffect, useState, useCallback, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { inject, observer } from "mobx-react";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Form, Row, Col, Select, Button } from "antd";
import styled from "styled-components";
import api from "utils/api";

import PropTypes from "prop-types";

import logoPexels from "assets/img/logo-pexels.png";
import logoUnsplash from "assets/img/logo-unsplash.png";
import logoPixabay from "assets/img/logo-pixabay.png";
import theme from "config/theme";

const logos = {
  pexels: logoPexels,
  unsplash: logoUnsplash,
  pixabay: logoPixabay,
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 50vh;
  /* overflow-x: hidden; */
  overflow-y: auto;
  position: relative;
`;

const ImageList = styled(InfiniteScroll)`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  /* max-height: 30vh; */
  /* 
  & > img {
    width: 100%;
    margin-bottom: 4px;
  } */
`;

const Item = styled.div`
  position: relative;
  display: inline-block;
  height: 128px;
  width: auto;
  cursor: pointer;
  margin: 0 8px 16px 8px;
  transition: transform 0.125s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: translateY(-4px);
  }

  & > .copyright {
    /* width: 80%;
    max-width: 64px; */
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    /* padding: 2px; */
    /* background-color: ${theme.white}; */
    opacity: 1;

    & > img {
      width: auto;
      height: 24px;
      vertical-align: middle;
    }
  }
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  outline: none;
`;

Image.defaultProps = { alt: "search result" };

const SearchImage = inject("l10n")(
  observer(({ style, onSelect: handleSelect, defaultQuery, l10n }) => {
    // const [isBusy, setIsBusy] = useState(false);
    const isBusy = useRef(false);
    const [searchResultData, setSearchResultData] = useState([]);
    const [totals, setTotals] = useState({
      unsplash: 0,
      pixabay: 0,
      pexels: 0,
    });
    const [currentQuery, setCurrentQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOrientation, setCurrentOrientation] = useState("all");

    const fetchData = useCallback(async ({ query, orientation, page }) => {
      if (!isBusy.current) {
        // setIsBusy(true);
        isBusy.current = true;
        let unsplashResult = {
          total: 0,
        };
        try {
          unsplashResult = await api
            .images()
            .search({ query, page, from: "unsplash", orientation });
          setSearchResultData((data) => {
            return [...data, ...unsplashResult.data];
          });
        } catch (error) {
          console.log(error);
        }

        let pixabayResult = {
          total: 0,
        };
        try {
          pixabayResult = await api
            .images()
            .search({ query, page, from: "pixabay", orientation });

          setSearchResultData((data) => {
            return [...data, ...pixabayResult.data];
          });
        } catch (error) {
          console.log(error);
        }

        let pexelsResult = {
          total: 0,
        };
        try {
          pexelsResult = await api
            .images()
            .search({ query, page, from: "pexels", orientation });

          setSearchResultData((data) => {
            return [...data, ...pexelsResult.data];
          });
        } catch (error) {
          console.log(error);
        }

        setTotals({
          unsplash: unsplashResult.total,
          pixabay: pixabayResult.total,
          pexels: pexelsResult.total,
        });
        // setIsBusy(false);
        isBusy.current = false;
      }
    }, []);

    const handleSearch = useCallback(
      ({ query, orientation }) => {
        if (!isBusy.current) {
          setSearchResultData([]);
          setCurrentQuery(query);
          setCurrentOrientation(orientation);
          setCurrentPage(1);
          fetchData({ query, orientation, page: 1 });
        }
      },
      [fetchData]
    );

    useEffect(() => {
      if (
        defaultQuery &&
        typeof defaultQuery === "string" &&
        defaultQuery.length >= 2
      ) {
        handleSearch({ query: defaultQuery });
      } else {
        handleSearch({});
      }
    }, [defaultQuery, handleSearch]);
    return (
      <Wrapper style={style}>
        <Form onFinish={handleSearch} style={{ padding: 20 }}>
          <Row align="middle" justify="space-between">
            <Col>&nbsp;</Col>
            <Col>
              <Form.Item name="query">
                <Input
                  style={{ width: 312, maxWidth: "100vw", border: "none" }}
                  className="dark"
                  size="small"
                  placeholder={l10n.str("PLACEHOLDER_SEARCH_IMAGE")}
                  suffix={
                    <Button
                      size="small"
                      type="link"
                      icon={<SearchOutlined />}
                      htmlType="submit"
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="orientation" initialValue="all">
                <Select>
                  <Select.Option value="all">
                    {l10n.str("SEARCH_IMAGE_ORIENTATION")}
                  </Select.Option>
                  <Select.Option value="landscape">
                    {l10n.str("SEARCH_IMAGE_ORIENTATION_LANDSCAPE")}
                  </Select.Option>
                  <Select.Option value="portrait">
                    {l10n.str("SEARCH_IMAGE_ORIENTATION_PORTRAIT")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{ visibility: "collapse", height: 0, marginBottom: 0 }}
          >
            <Button htmlType="submit">Search</Button>
          </Form.Item>
        </Form>
        <ImageList
          scrollableTarget="selectImage"
          dataLength={searchResultData.length}
          next={async () => {
            const nextPage = currentPage + 1;
            await fetchData({
              query: currentQuery,
              page: nextPage,
              orientation: currentOrientation,
            });
            setCurrentPage(nextPage);
          }}
          hasMore={
            searchResultData.length <=
            Object.keys(totals).reduce(
              (sum, key) => sum + (totals[key] || 0),
              0
            )
          }
          loader={
            <div style={{ display: "block", textAlign: "center", padding: 16 }}>
              <LoadingOutlined />
            </div>
          }
        >
          {searchResultData.map((item) => {
            const { thumbnail, from, link } = item;
            return (
              <Item>
                <Image
                  src={thumbnail}
                  onClick={() => {
                    handleSelect(item);
                  }}
                />
                <a
                  className="copyright"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logos[from]} alt={from} />
                </a>
              </Item>
            );
          })}
        </ImageList>
      </Wrapper>
    );
  })
);

SearchImage.propTypes = {
  onSelect: PropTypes.func,
  defaultQuery: PropTypes.string,
};

SearchImage.defaultProps = {
  onSelect: () => {},
  defaultQuery: "",
};

export default SearchImage;
