import { Checkbox as checkbox } from "antd";
import styled from "styled-components";
import theme from "config/theme";

const Checkbox = styled(checkbox)`
  user-select: none;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.primary};
    border-color: ${theme.primary};
  }
  .ant-checkbox-checked::after {
    border-color: ${theme.primary};
  }
  &:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.primary} !important;
  }

  /* .ant-checkbox-inner::after,
  .ant-checkbox-checked::after {
    content: none !important;
  } */
`;

export default Checkbox;
